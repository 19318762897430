import React, { InputHTMLAttributes } from "react";
import { Checkbox } from "@material-ui/core";
import {
  Path,
  UseFormRegister,
  Controller,
  Control,
  FieldValues,
} from "react-hook-form";

import { Container, Label, Text } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  text: string;
  control: Control<FieldValues, any>;
  width: number;
}

const Input: React.FC<InputProps> = ({
  label,
  register,
  required,
  text,
  control,
  width,
  ...rest
}) => {
  return (
    <Container width={width}>
      <Controller
        name={label}
        control={control}
        defaultValue={false}
        rules={{ required: true }}
        render={({ field }) => (
          <Label>
            <Checkbox
              checked={!!field.value}
              style={{ color: "#663399" }}
              {...field}
            />
            <Text>{text}</Text>
          </Label>
        )}
      />
    </Container>
  );
};

export default Input;
