import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const InputText = styled.input`
  width: 18px;
  height: 18px;
`;

export const Text = styled.label`
  padding: 0;
  margin-bottom: 0px;
  margin-top: 3px;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;
