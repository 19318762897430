import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { format } from "date-fns";
import * as Dialog from "@radix-ui/react-dialog";
import { useAuth } from "../../../../../../hooks/auth";
import { useRoutes } from "../../../../../../hooks/routes";

import IListUsersAdminApiProps from "./dtos/IListUsersAdminApiProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import DialogModal from "../../../../../../shared/components/DialogModal";

import { Notify } from "../../../../../../shared/utils";

import {
  Container,
  Title,
  Subtitle,
  ContainerLoading,
  Bold,
  Positive,
  Negative,
  TitlePermissions,
  ContainerPermission,
  ContainerButton,
} from "./styles";

const ViewUserAdmin: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [usersAdminData, setUserAdminData] = useState<IListUsersAdminApiProps>({
    user: {
      id: "",
      email: "",
      is_blocked: false,
      user_type: "",
      created_at: new Date(),
      updated_at: new Date(),
      avatar_url: "",
    },
    people: {
      id: "",
      first_name: "",
      last_name: "",
    },
    admin_user_permissions: {
      id: "",
      create_templates: false,
      read_templates: false,
      update_templates: false,
      delete_templates: false,
      clone_templates: false,
      create_template_categories: false,
      read_template_categories: false,
      update_template_categories: false,
      delete_template_categories: false,
      create_users: false,
      read_users: false,
      block_users: false,
      update_user_password: false,
      update_user_subscription: false,
      delete_users: false,
      cancel_users: false,
      create_clauses: false,
      read_clauses: false,
      update_clauses: false,
      delete_clauses: false,
      create_clause_categories: false,
      read_clause_categories: false,
      update_clause_categories: false,
      delete_clause_categories: false,
      create_icons: false,
      read_icons: false,
      update_icons: false,
      delete_icons: false,
      create_icons_categories: false,
      read_icons_categories: false,
      update_icons_categories: false,
      delete_icons_categories: false,
      create_users_admin: false,
      read_users_admin: false,
      update_users_admin: false,
      update_users_admin_password: false,
      block_users_admin: false,
      read_users_admin_editor: false,
      create_users_admin_editor: false,
      delete_users_admin_editor: false,
      user_graphics: false,
      send_personalized_email: false,
      create_text_replacement: false,
      read_text_replacement: false,
      update_text_replacement: false,
      delete_text_replacement: false,
      create_image: false,
      read_image: false,
      update_image: false,
      delete_image: false,
      create_image_categories: false,
      read_image_categories: false,
      update_image_categories: false,
      delete_image_categories: false,
      create_shapes: false,
      read_shapes: false,
      update_shapes: false,
      delete_shapes: false,
      create_shapes_categories: false,
      read_shapes_categories: false,
      update_shapes_categories: false,
      delete_shapes_categories: false,
      clone_bits_to_user_template: false,
      create_remote_access_user: false,
      read_remote_access_user: false,
      delete_remote_access_user: false,
      download_users_admin: false,
      create_element_block: false,
      read_element_block: false,
      update_element_block: false,
      delete_element_block: false,
      create_element_block_category: false,
      read_element_block_category: false,
      update_element_block_category: false,
      delete_element_block_category: false,
    },
  });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { user_id, which_page } = useParams();

  const { data: dataUseAuth } = useAuth();

  const { getUrl } = useRoutes();

  const userPermissionUpdate =
    dataUseAuth.admin_user_permissions.update_users_admin;
  const userPermissionUpdatePassword =
    dataUseAuth.admin_user_permissions.update_users_admin_password;
  const userPermissionBlock =
    dataUseAuth.admin_user_permissions.block_users_admin;

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/view-user-admin/:which_page/:user_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listUserSpecific = await api.get(`/user-admin/${user_id}`);

      setUserAdminData(listUserSpecific.data);
      setLoading(false);
    })();
  }, [user_id, reload]);

  const handleBlockUsersAdmin = useCallback(
    async (id: string) => {
      await api.put(`/users/block-user/${id}`);
      Notify({
        text: "Usuário bloqueado / desbloqueado com sucesso",
        type: "success",
      });
      setReload(!reload);
    },
    [reload]
  );

  const handleGoBack = useCallback(() => {
    if (which_page === "user-admin") {
      navigate("/users-admin", {
        state: { params: dataLocation?.params },
      });
    } else if (which_page === "user-admin-editor") {
      navigate("/users-admin-editor", {
        state: { params: dataLocation?.params },
      });
    }
  }, [which_page, navigate, dataLocation]);

  const handleDeleteUsersAdminEditor = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/bits-templates-editor-users/${id}`);
        Notify({
          text: "Acesso de editor removido do usuário com sucesso",
          type: "success",
        });
        navigate("/users-admin-editor", {
          state: { params: dataLocation?.params },
        });
      } catch (error: any) {
        console.log(error);
      }
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Visualizar usuário admin" />
      <MasterMain>
        {loading && (
          <ContainerLoading>
            <SyncLoader loading={loading} color="#663399" size={10} />
          </ContainerLoading>
        )}

        {!loading && (
          <Container>
            <Title>Nome:</Title>
            <Subtitle>
              {usersAdminData.people.first_name
                ? usersAdminData.people.first_name
                : "null"}
            </Subtitle>

            <Title>Sobrenome:</Title>
            <Subtitle>
              {usersAdminData.people.last_name
                ? usersAdminData.people.last_name
                : "null"}
            </Subtitle>

            <Title>Email:</Title>
            <Subtitle>
              {usersAdminData.user.email ? usersAdminData.user.email : "null"}
            </Subtitle>

            <Title>Está bloqueado?</Title>
            <Subtitle>
              {usersAdminData.user.is_blocked ? "Sim" : "Não"}
            </Subtitle>

            <Title>Criado em:</Title>
            <Subtitle>
              {`${new Date(usersAdminData.user.created_at)}`
                ? `${format(
                  new Date(usersAdminData.user.created_at),
                  "dd/MM/yyyy HH:mm"
                )}`
                : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 10, marginTop: 20 }} />

            <TitlePermissions style={{ marginBottom: 20 }}>
              Permissões de usuário:
            </TitlePermissions>

            <ContainerPermission>
              <Title>Templates</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_templates ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_templates ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions.update_templates ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions.delete_templates ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Clonar para bits: </Bold>
                {usersAdminData.admin_user_permissions.clone_templates ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Clonar para o usuário: </Bold>
                {usersAdminData.admin_user_permissions
                  .clone_bits_to_user_template ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Categoria de templates</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_template_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .read_template_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_template_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_template_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Usuários UX Doc</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_users ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_users ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Bloquear: </Bold>
                {usersAdminData.admin_user_permissions.block_users ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar senha: </Bold>
                {usersAdminData.admin_user_permissions.update_user_password ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar status da assinatura: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_user_subscription ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions.delete_users ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Cancelar assinatura: </Bold>
                {usersAdminData.admin_user_permissions.cancel_users ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Cláusulas</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_clauses ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_clauses ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions.update_clauses ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions.delete_clauses ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Categoria de cláusulas</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_clause_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .read_clause_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_clause_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_clause_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Ícones</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_icons ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_icons ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions.update_icons ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions.delete_icons ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Categoria de ícones</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_icons_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_icons_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_icons_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_icons_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Imagens</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_image ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_image ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions.update_image ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions.delete_image ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Categoria de imagens</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_image_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_image_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_image_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_image_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Formas</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_shapes ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_shapes ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions.update_shapes ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions.delete_shapes ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Categoria de formas</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_shapes_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .read_shapes_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_shapes_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_shapes_categories ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Usuários admin</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions.create_users_admin ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_users_admin ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions.update_users_admin ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar senha: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_users_admin_password ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Bloquear: </Bold>
                {usersAdminData.admin_user_permissions.block_users_admin ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Usuários admin editor</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_users_admin_editor ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .read_users_admin_editor ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_users_admin_editor ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Substituição de texto</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_text_replacement ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_text_replacement ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_text_replacement ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_text_replacement ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Relatórios</Title>

              <Subtitle>
                <Bold>Usuários: </Bold>
                {usersAdminData.admin_user_permissions.user_graphics ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Email</Title>

              <Subtitle>
                <Bold>Enviar email personalizado: </Bold>
                {usersAdminData.admin_user_permissions
                  .send_personalized_email ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Acesso remoto</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_remote_access_user ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .read_remote_access_user ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_remote_access_user ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Download CSV</Title>

              <Subtitle>
                <Bold>Permitir: </Bold>
                {usersAdminData.admin_user_permissions
                  .download_users_admin ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Bloco de elementos</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_element_block ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_element_block ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_element_block ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_element_block ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <ContainerPermission>
              <Title>Categorias de blocos de elementos</Title>

              <Subtitle>
                <Bold>Criar: </Bold>
                {usersAdminData.admin_user_permissions
                  .create_element_block_category ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Visualizar: </Bold>
                {usersAdminData.admin_user_permissions.read_element_block_category ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Atualizar: </Bold>
                {usersAdminData.admin_user_permissions
                  .update_element_block_category ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>

              <Subtitle>
                <Bold>Deletar: </Bold>
                {usersAdminData.admin_user_permissions
                  .delete_element_block_category ? (
                  <Positive>Sim</Positive>
                ) : (
                  <Negative>Não</Negative>
                )}
              </Subtitle>
            </ContainerPermission>

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <ContainerButton>
              <Button style={{ width: 100 }} onClick={() => handleGoBack()}>
                Voltar
              </Button>

              <Button
                style={{ width: 100, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/edit-user-admin/${user_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionUpdate}
              >
                Editar
              </Button>

              <Button
                style={{ width: 150, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/reset-password-user-admin/${user_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionUpdatePassword}
              >
                Alterar senha
              </Button>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 200, marginLeft: 20 }}
                    disabled={!userPermissionBlock}
                  >
                    {`${usersAdminData.user.is_blocked
                        ? "Desbloquear"
                        : "Bloquear"
                      } usuário`}
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title={`${usersAdminData.user.is_blocked ? "Desbloquear" : "Bloquear"
                    } usuário`}
                  type="block"
                  isBlocked={usersAdminData.user.is_blocked}
                  subtitle={`${usersAdminData.people.first_name} ${usersAdminData.people.last_name}`}
                  callback={() => handleBlockUsersAdmin(usersAdminData.user.id)}
                />
              </Dialog.Root>

              {which_page === "user-admin-editor" && (
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <Button style={{ width: 270, marginLeft: 20 }}>
                      Remover permissão de editor
                    </Button>
                  </Dialog.Trigger>

                  <DialogModal
                    title="Remover permissão de editor"
                    type="delete"
                    subtitle={`${usersAdminData.people.first_name} ${usersAdminData.people.last_name}`}
                    callback={() =>
                      handleDeleteUsersAdminEditor(usersAdminData.user.id)
                    }
                  />
                </Dialog.Root>
              )}
            </ContainerButton>
          </Container>
        )}
      </MasterMain>
    </MasterContainer>
  );
};

export default ViewUserAdmin;
