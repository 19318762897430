import styled from "styled-components";

interface IImage {
  srcImage: string;
  widthImage: number;
  heightImage: number;
}

export const ContainerImage = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Image = styled.div<IImage>`
  width: ${({ widthImage }) => widthImage}px;
  height: ${({ heightImage }) => heightImage}px;
  background: url(${({ srcImage }) => srcImage}) no-repeat;
  background-size: contain;
  background-position: center;
`;
