import React, { InputHTMLAttributes } from "react";
import { Path, Controller, Control, FieldValues } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@material-ui/core/styles";

import { Container } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: Path<IFormValues>;
  control: Control<FieldValues, any>;
  defaultValue: Array<string>;
  required: boolean;
  placeholder: string;
}

const InputSelect: React.FC<InputProps> = ({
  label,
  control,
  defaultValue,
  required,
  placeholder,
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiFormLabel-root": {
        display: "none",
      },

      "& .MuiFormControl-root": {
        padding: 0,
      },

      "& .MuiInputBase-root": {
        marginTop: 3,
        marginBottom: 3,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#9D9D9D",
        borderRadius: 5,
        paddingLeft: 6,
      },

      "& .MuiInputBase-root:before": {
        display: "none",
      },

      "& .MuiInputBase-root:after": {
        display: "none",
      },

      "& .MuiAutocomplete-endAdornment": {
        right: 13,
        top: 10,
      },

      "& .MuiChip-label": {
        fontSize: 14,
      },

      "& .MuiInputBase-input": {
        fontSize: 14,
        color: "#000",
        marginBottom: 5,
        marginTop: 5,
        marginLeft: 5,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Container>
      <Controller
        name={label}
        control={control}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            classes={classes}
            options={[]}
            defaultValue={defaultValue}
            value={value ? value : []}
            onChange={(event, values) => onChange(values)}
            freeSolo
            renderTags={(value: [], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={`${placeholder} - Aperte enter para adicionar a palavra`}
              />
            )}
          />
        )}
      />
    </Container>
  );
};

export default InputSelect;
