import React from "react";
import { useAuth } from "../../../../../hooks/auth";
import { useRoutes } from "../../../../../hooks/routes";

import IconSvg from "../../../../../shared/components/IconSvg";
import ButtonNavigator from "../ButtonNavigator";
import DropdownMenu from "../DropdownMenu";
import ButtonDropdown from "../ButtonDropdown";

import logoUxDoc from "../../../../../shared/assets/logo-white.svg";
import homeIcon from "../../../../../shared/assets/icon-home.svg";
import homeHoverIcon from "../../../../../shared/assets/icon-home-hover.svg";
import userIcon from "../../../../../shared/assets/icon-user.svg";
import userHoverIcon from "../../../../../shared/assets/icon-user-hover.svg";
import exitIcon from "../../../../../shared/assets/icon-exit.svg";
import exitHoverIcon from "../../../../../shared/assets/icon-exit-hover.svg";
import clauseIcon from "../../../../../shared/assets/icon-clause.svg";
import clauseHoverIcon from "../../../../../shared/assets/icon-clause-hover.svg";
import iconsIcon from "../../../../../shared/assets/icon-icons.svg";
import iconsHoverIcon from "../../../../../shared/assets/icon-icons-hover.svg";
import iconTemplate from "../../../../../shared/assets/icon-template.svg";
import iconTemplateHover from "../../../../../shared/assets/icon-template-hover.svg";
import iconGraphic from "../../../../../shared/assets/icon-graphics.svg";
import iconGraphicHover from "../../../../../shared/assets/icon-graphics-hover.svg";
import iconEmail from "../../../../../shared/assets/icon-email.svg";
import iconEmailHover from "../../../../../shared/assets/icon-email-hover.svg";
import iconTextReplacement from "../../../../../shared/assets/icon-text-replacement.svg";
import iconTextReplacementHover from "../../../../../shared/assets/icon-text-replacement-hover.svg";
import iconImage from "../../../../../shared/assets/icon-image.svg";
import iconImageHover from "../../../../../shared/assets/icon-image-hover.svg";
import iconShape from "../../../../../shared/assets/icon-shape.svg";
import iconShapeHover from "../../../../../shared/assets/icon-shape-hover.svg";
import iconRemoteAccess from "../../../../../shared/assets/icon-remote-access.svg";
import iconRemoteAccessHover from "../../../../../shared/assets/icon-remote-access-hover.svg";

import { AsideControlsContent, Box, ContainerItems } from "./styles";

const MasterAsideControls: React.FC = () => {
  const {
    isExpandedClauseRepository,
    isExpandedClause,
    isExpandedClauseCategory,
    isExpandedIcon,
    isExpandedIconRepository,
    isExpandedIconCategory,
    isExpandedImage,
    isExpandedImageRepository,
    isExpandedImageCategory,
    isExpandedShape,
    isExpandedShapeRepository,
    isExpandedShapeCategory,
    isExpandedGraphic,
    isExpandedGraphicUser,
    isExpandedEmail,
    isExpandedSendEmail,
    isExpandedTemplate,
    isExpandedTemplateRepository,
    isExpandedTemplateCategory,
    isExpandedUser,
    isExpandedCommonUser,
    isExpandedUserAdmin,
    isExpandedUserAdminEditor,
    isActiveHome,
    isActiveRemoteAccess,
    isActiveTextReplacement,
  } = useRoutes();

  const { signOut, data: userPermission } = useAuth();

  return (
    <AsideControlsContent>
      <Box>
        <IconSvg image={logoUxDoc} width="160px" height="33px" />
      </Box>

      <ContainerItems>
        <ButtonNavigator
          icon={homeIcon}
          iconClick={homeHoverIcon}
          name="Início"
          url="/"
          isActive={isActiveHome}
        />

        {(userPermission.admin_user_permissions.create_users ||
          userPermission.admin_user_permissions.read_users ||
          userPermission.admin_user_permissions.block_users ||
          userPermission.admin_user_permissions.update_user_password ||
          userPermission.admin_user_permissions.delete_users ||
          userPermission.admin_user_permissions.cancel_users ||
          userPermission.admin_user_permissions.create_users_admin ||
          userPermission.admin_user_permissions.read_users_admin ||
          userPermission.admin_user_permissions.update_users_admin ||
          userPermission.admin_user_permissions.block_users_admin ||
          userPermission.admin_user_permissions.read_users_admin_editor ||
          userPermission.admin_user_permissions.create_users_admin_editor ||
          userPermission.admin_user_permissions.delete_users_admin_editor) && (
          <DropdownMenu
            name="Usuários"
            icon={userIcon}
            iconClick={userHoverIcon}
            expanded={isExpandedUser}
          >
            {(userPermission.admin_user_permissions.create_users ||
              userPermission.admin_user_permissions.read_users ||
              userPermission.admin_user_permissions.block_users ||
              userPermission.admin_user_permissions.update_user_password ||
              userPermission.admin_user_permissions.delete_users ||
              userPermission.admin_user_permissions.cancel_users) && (
              <ButtonDropdown
                name="Comuns"
                url="/users"
                isActive={isExpandedCommonUser}
              />
            )}
            {(userPermission.admin_user_permissions.create_users_admin ||
              userPermission.admin_user_permissions.read_users_admin ||
              userPermission.admin_user_permissions.update_users_admin ||
              userPermission.admin_user_permissions.block_users_admin) && (
              <ButtonDropdown
                name="Admin"
                url="/users-admin"
                isActive={isExpandedUserAdmin}
              />
            )}
            {(userPermission.admin_user_permissions.read_users_admin_editor ||
              userPermission.admin_user_permissions.create_users_admin_editor ||
              userPermission.admin_user_permissions
                .delete_users_admin_editor) && (
              <ButtonDropdown
                name="Admin Editor"
                url="/users-admin-editor"
                isActive={isExpandedUserAdminEditor}
              />
            )}
          </DropdownMenu>
        )}

        {(userPermission.admin_user_permissions.create_templates ||
          userPermission.admin_user_permissions.read_templates ||
          userPermission.admin_user_permissions.update_templates ||
          userPermission.admin_user_permissions.delete_templates ||
          userPermission.admin_user_permissions.create_template_categories ||
          userPermission.admin_user_permissions.read_template_categories ||
          userPermission.admin_user_permissions.update_template_categories ||
          userPermission.admin_user_permissions.delete_template_categories) && (
          <DropdownMenu
            name="Templates"
            icon={iconTemplate}
            iconClick={iconTemplateHover}
            expanded={isExpandedTemplate}
          >
            {(userPermission.admin_user_permissions.create_templates ||
              userPermission.admin_user_permissions.read_templates ||
              userPermission.admin_user_permissions.update_templates ||
              userPermission.admin_user_permissions.delete_templates) && (
              <ButtonDropdown
                name="Repositório de Templates"
                url="/templates"
                isActive={isExpandedTemplateRepository}
              />
            )}
            {(userPermission.admin_user_permissions
              .create_template_categories ||
              userPermission.admin_user_permissions.read_template_categories ||
              userPermission.admin_user_permissions
                .update_template_categories ||
              userPermission.admin_user_permissions
                .delete_template_categories) && (
              <ButtonDropdown
                name="Categorias dos Templates"
                url="/template-category"
                isActive={isExpandedTemplateCategory}
              />
            )}
          </DropdownMenu>
        )}

        {(userPermission.admin_user_permissions.create_clauses ||
          userPermission.admin_user_permissions.read_clauses ||
          userPermission.admin_user_permissions.update_clauses ||
          userPermission.admin_user_permissions.delete_clauses ||
          userPermission.admin_user_permissions.create_clause_categories ||
          userPermission.admin_user_permissions.read_clause_categories ||
          userPermission.admin_user_permissions.update_clause_categories ||
          userPermission.admin_user_permissions.delete_clause_categories) && (
          <DropdownMenu
            name="Cláusulas"
            icon={clauseIcon}
            iconClick={clauseHoverIcon}
            expanded={isExpandedClause}
          >
            {(userPermission.admin_user_permissions.create_clauses ||
              userPermission.admin_user_permissions.read_clauses ||
              userPermission.admin_user_permissions.update_clauses ||
              userPermission.admin_user_permissions.delete_clauses) && (
              <ButtonDropdown
                name="Repositório de Cláusulas"
                url="/clauses"
                isActive={isExpandedClauseRepository}
              />
            )}
            {(userPermission.admin_user_permissions.create_clause_categories ||
              userPermission.admin_user_permissions.read_clause_categories ||
              userPermission.admin_user_permissions.update_clause_categories ||
              userPermission.admin_user_permissions
                .delete_clause_categories) && (
              <ButtonDropdown
                name="Categoria das Cláusulas"
                url="/clause-category"
                isActive={isExpandedClauseCategory}
              />
            )}
          </DropdownMenu>
        )}

        {(userPermission.admin_user_permissions.create_icons ||
          userPermission.admin_user_permissions.read_icons ||
          userPermission.admin_user_permissions.update_icons ||
          userPermission.admin_user_permissions.delete_icons ||
          userPermission.admin_user_permissions.create_icons_categories ||
          userPermission.admin_user_permissions.read_icons_categories ||
          userPermission.admin_user_permissions.update_icons_categories ||
          userPermission.admin_user_permissions.delete_icons_categories) && (
          <DropdownMenu
            name="Ícones"
            icon={iconsIcon}
            iconClick={iconsHoverIcon}
            expanded={isExpandedIcon}
          >
            {(userPermission.admin_user_permissions.create_icons ||
              userPermission.admin_user_permissions.read_icons ||
              userPermission.admin_user_permissions.update_icons ||
              userPermission.admin_user_permissions.delete_icons) && (
              <ButtonDropdown
                name="Repositório de Ícones"
                url="/icons"
                isActive={isExpandedIconRepository}
              />
            )}
            {(userPermission.admin_user_permissions.create_icons_categories ||
              userPermission.admin_user_permissions.read_icons_categories ||
              userPermission.admin_user_permissions.update_icons_categories ||
              userPermission.admin_user_permissions
                .delete_icons_categories) && (
              <ButtonDropdown
                name="Categoria dos Ícones"
                url="/icon-category"
                isActive={isExpandedIconCategory}
              />
            )}
          </DropdownMenu>
        )}

        {(userPermission.admin_user_permissions.create_image ||
          userPermission.admin_user_permissions.read_image ||
          userPermission.admin_user_permissions.update_image ||
          userPermission.admin_user_permissions.delete_image ||
          userPermission.admin_user_permissions.create_image_categories ||
          userPermission.admin_user_permissions.read_image_categories ||
          userPermission.admin_user_permissions.update_image_categories ||
          userPermission.admin_user_permissions.delete_image_categories) && (
          <DropdownMenu
            name="Ilustrações"
            icon={iconImage}
            iconClick={iconImageHover}
            expanded={isExpandedImage}
          >
            {(userPermission.admin_user_permissions.create_image ||
              userPermission.admin_user_permissions.read_image ||
              userPermission.admin_user_permissions.update_image ||
              userPermission.admin_user_permissions.delete_image) && (
              <ButtonDropdown
                name="Repositório de Ilustrações"
                url="/image"
                isActive={isExpandedImageRepository}
              />
            )}
            {(userPermission.admin_user_permissions.create_image_categories ||
              userPermission.admin_user_permissions.read_image_categories ||
              userPermission.admin_user_permissions.update_image_categories ||
              userPermission.admin_user_permissions
                .delete_image_categories) && (
              <ButtonDropdown
                name="Categoria de Ilustrações"
                url="/image-category"
                isActive={isExpandedImageCategory}
              />
            )}
          </DropdownMenu>
        )}

        {(userPermission.admin_user_permissions.create_shapes ||
          userPermission.admin_user_permissions.read_shapes ||
          userPermission.admin_user_permissions.update_shapes ||
          userPermission.admin_user_permissions.delete_shapes ||
          userPermission.admin_user_permissions.create_shapes_categories ||
          userPermission.admin_user_permissions.read_shapes_categories ||
          userPermission.admin_user_permissions.update_shapes_categories ||
          userPermission.admin_user_permissions.delete_shapes_categories) && (
          <DropdownMenu
            name="Formas"
            icon={iconShape}
            iconClick={iconShapeHover}
            expanded={isExpandedShape}
          >
            {(userPermission.admin_user_permissions.create_shapes ||
              userPermission.admin_user_permissions.read_shapes ||
              userPermission.admin_user_permissions.update_shapes ||
              userPermission.admin_user_permissions.delete_shapes) && (
              <ButtonDropdown
                name="Repositório de Formas"
                url="/shapes"
                isActive={isExpandedShapeRepository}
              />
            )}
            {(userPermission.admin_user_permissions.create_shapes_categories ||
              userPermission.admin_user_permissions.read_shapes_categories ||
              userPermission.admin_user_permissions.update_shapes_categories ||
              userPermission.admin_user_permissions
                .delete_shapes_categories) && (
              <ButtonDropdown
                name="Categoria de Formas"
                url="/shapes-category"
                isActive={isExpandedShapeCategory}
              />
            )}
          </DropdownMenu>
        )}

        {/* {userPermission.admin_user_permissions.user_graphics && (
          <DropdownMenu
            name="Relatórios"
            icon={iconGraphic}
            iconClick={iconGraphicHover}
            expanded={isExpandedGraphic}
          >
            {userPermission.admin_user_permissions.user_graphics && (
              <ButtonDropdown
                name="Usuários"
                url="/user-graphics"
                isActive={isExpandedGraphicUser}
              />
            )}
          </DropdownMenu>
        )} */}

        {userPermission.admin_user_permissions.send_personalized_email && (
          <DropdownMenu
            name="Email"
            icon={iconEmail}
            iconClick={iconEmailHover}
            expanded={isExpandedEmail}
          >
            {userPermission.admin_user_permissions.send_personalized_email && (
              <ButtonDropdown
                name="Enviar email personalizado"
                url="/send-personalized-email"
                isActive={isExpandedSendEmail}
              />
            )}
          </DropdownMenu>
        )}

        {(userPermission.admin_user_permissions.create_text_replacement ||
          userPermission.admin_user_permissions.read_text_replacement ||
          userPermission.admin_user_permissions.update_text_replacement ||
          userPermission.admin_user_permissions.delete_text_replacement) && (
          <ButtonNavigator
            icon={iconTextReplacement}
            iconClick={iconTextReplacementHover}
            name="Substituição de texto"
            url="/text-replacement"
            isActive={isActiveTextReplacement}
          />
        )}

        {(userPermission.admin_user_permissions.create_remote_access_user ||
          userPermission.admin_user_permissions.read_remote_access_user ||
          userPermission.admin_user_permissions.delete_remote_access_user) && (
          <ButtonNavigator
            icon={iconRemoteAccess}
            iconClick={iconRemoteAccessHover}
            name="Acesso remoto"
            url="/remote-access"
            isActive={isActiveRemoteAccess}
          />
        )}

        <ButtonNavigator
          icon={exitIcon}
          iconClick={exitHoverIcon}
          name="Sair"
          url="/exit"
          onClick={() => signOut()}
        />
      </ContainerItems>
    </AsideControlsContent>
  );
};

export default MasterAsideControls;
