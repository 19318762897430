import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../hooks/routes";

import IHookForm from "../../../../../shared/dtos/IHookForm";
import IFormCreateRemoteAccess from "./dtos/IFormCreateRemoteAccess";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../shared/components/Button";
import ButtonCancel from "../../../../../shared/components/ButtonCancel";
import Input from "../../../../../shared/components/Input";
import Alert from "../../../../../shared/components/Alert";
import TooltipInformation from "../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateRemoteAccess: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/create-remote-access");
  }, []);

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um email valido")
      .required("email é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit: SubmitHandler<IFormCreateRemoteAccess> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const createUser = await api.post("/remote-access-user", {
          email: formData.email,
        });

        if (createUser.status === 200) {
          Notify({ text: "Acesso remoto criado com sucesso", type: "success" });
          setLoading(false);
          navigate("/remote-access");
        }
      } catch (err: any) {
        if (err?.response?.data?.message === "Admin user in use") {
          Notify({
            text: "Seu usuário de acesso remoto já esta sendo usado para acessar um usuário. Exclua o acesso remoto desse usuário para poder usar em outro",
            type: "error",
          });
        } else if (
          err?.response?.data?.message === "Remote access user in use"
        ) {
          Notify({
            text: "O email informado já esta recebendo acesso remoto de outro administrador",
            type: "error",
          });
        } else if (
          err?.response?.data?.message === "You cannot register your own email"
        ) {
          Notify({
            text: "Você não pode cadastrar seu próprio email",
            type: "error",
          });
        } else if (err?.response?.data?.message === "Cannot add admin user") {
          Notify({
            text: "Você não pode cadastrar um usuário admin",
            type: "error",
          });
        } else {
          Notify({
            text: "Erro ao criar o acesso remoto. Entre em contato com o suporte",
            type: "error",
          });
        }

        setLoading(false);
      }
    },
    [navigate]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar acesso remoto" />
      <MasterMain>
        <Main>
          <Alert
            message="Aqui você consegue acessar a conta de qualquer usuário
              como usuário comum do UX Doc ou um usuário de uma organização com 
              LBCA mas você não consegue acessar uma outra conta administradora 
              por aqui. Você pode acessar uma conta por vez, então caso adicione
              um email se quiser adicionar outro, precisa excluir o primeiro
              email adicionado. Uma vez que você adicionou o email do usuário que
              deseja fazer o acesso remoto. O usuário não conseguirá acessar a
              conta dele ate que você exclua o email dele do acesso remoto na tela
              anterior."
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Email *</LabelInput>

              <TooltipInformation
                title="Email da conta que deseja acessar"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="email"
                register={register}
                required={true}
                placeholder="Digite o email"
              />
              {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/remote-access");
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Criar acesso remoto
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateRemoteAccess;
