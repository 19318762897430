import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import * as Dialog from "@radix-ui/react-dialog";
import { useRoutes } from "../../../../../../hooks/routes";

import IListIconApiProps from "./dtos/IListIconApiProps";
import ICreatorAndEditorProps from "./dtos/ICreatorAndEditorProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import DialogModal from "../../../../../../shared/components/DialogModal";

import { Notify } from "../../../../../../shared/utils";

import {
  Container,
  Title,
  Subtitle,
  ContainerLoading,
  Link,
  IconImg,
  ContainerButton,
} from "./styles";

const ViewIcon: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [creator, setCreator] = useState<ICreatorAndEditorProps>({ name: "" });
  const [lastEditor, setLastEditor] = useState<ICreatorAndEditorProps>({
    name: "",
  });
  const [iconData, setIconData] = useState<IListIconApiProps>({
    iconRepository: {
      id: "",
      nickname: "",
      creator_id: "",
      last_editor_id: "",
      name: "",
      key_words: "",
      dpath: "",
      stroke_width: 0,
      usage_counter: 0,
      is_icon_filled: false,
      icon_url: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
    iconCategories: [],
  });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { data: dataUseAuth } = useAuth();

  const { getUrl } = useRoutes();

  const userPermissionReadUser = dataUseAuth.admin_user_permissions.read_users;

  const userPermissionUpdate = dataUseAuth.admin_user_permissions.update_icons;
  const userPermissionDelete = dataUseAuth.admin_user_permissions.delete_icons;

  const { icon_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/view-icon/:icon_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listIconSpecific = await api.get(`/icon-repository/${icon_id}`);

      if (userPermissionReadUser) {
        const userCreator = await api.get(
          `/profile/${listIconSpecific.data.iconRepository.creator_id}`
        );

        const userLastEditor = await api.get(
          `/profile/${listIconSpecific.data.iconRepository.last_editor_id}`
        );

        setCreator({
          name: `${userCreator.data.people.first_name} ${userCreator.data.people.last_name}`,
        });
        setLastEditor({
          name: `${userLastEditor.data.people.first_name} ${userLastEditor.data.people.last_name}`,
        });
      } else {
        setCreator({
          name: "Anônimo",
        });
        setLastEditor({
          name: "Anônimo",
        });
      }

      setIconData(listIconSpecific.data);
      setLoading(false);
    })();
  }, [icon_id, userPermissionReadUser]);

  const handleDeleteIcon = useCallback(
    async (id: string) => {
      await api.delete(`/icon-repository/${id}`);
      Notify({ text: "Ícone deletado com sucesso", type: "success" });
      navigate("/icons", {
        state: { params: dataLocation?.params },
      });
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Visualizar ícone" />
      <MasterMain>
        {loading && (
          <ContainerLoading>
            <SyncLoader loading={loading} color="#663399" size={10} />
          </ContainerLoading>
        )}

        {!loading && (
          <Container>
            <Title>Ícone:</Title>
            <IconImg src={iconData.iconRepository.icon_url} />

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Title>Nome:</Title>
            <Subtitle>
              {iconData.iconRepository.nickname
                ? iconData.iconRepository.nickname
                : "null"}
            </Subtitle>

            <Title>Palavras chave:</Title>
            <Subtitle>
              {iconData.iconRepository.key_words
                ? iconData.iconRepository.key_words
                : "null"}
            </Subtitle>

            <Title>Número de vezes usada:</Title>
            <Subtitle>{iconData.iconRepository.usage_counter}</Subtitle>

            <Title>Espessura:</Title>
            <Subtitle>{iconData.iconRepository.stroke_width}</Subtitle>

            <Title>Ícone preenchido:</Title>
            <Subtitle>
              {iconData.iconRepository.is_icon_filled ? "Sim" : "Não"}
            </Subtitle>

            <Title>Ícone URL:</Title>
            <Link href={iconData.iconRepository.icon_url} target="_blank">
              {iconData.iconRepository.icon_url}
            </Link>

            <Title>DPATH:</Title>
            <Subtitle>
              {iconData.iconRepository.dpath
                ? iconData.iconRepository.dpath
                : "null"}
            </Subtitle>

            <Title>Criador:</Title>
            <Subtitle>{creator.name}</Subtitle>

            <Title>Último a editar:</Title>
            <Subtitle>{lastEditor.name}</Subtitle>

            <Title>Criado em:</Title>
            <Subtitle>
              {`${new Date(iconData.iconRepository.created_at)}`
                ? `${format(
                    new Date(iconData.iconRepository.created_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Title>Última atualização:</Title>
            <Subtitle>
              {`${new Date(iconData.iconRepository.updated_at)}`
                ? `${format(
                    new Date(iconData.iconRepository.updated_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 20 }} />

            <Title>Categorias:</Title>
            {iconData.iconCategories.map((item) => (
              <Subtitle key={item.id} style={{ marginBottom: 0, marginTop: 5 }}>
                {item.title}
              </Subtitle>
            ))}

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <ContainerButton>
              <Button
                style={{ width: 100 }}
                onClick={() =>
                  navigate("/icons", {
                    state: { params: dataLocation?.params },
                  })
                }
              >
                Voltar
              </Button>

              <Button
                style={{ width: 100, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/edit-icon/${icon_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionUpdate}
              >
                Editar
              </Button>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 100, marginLeft: 20 }}
                    disabled={!userPermissionDelete}
                  >
                    Excluir
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title="Deletar ícone"
                  type="delete"
                  subtitle={iconData.iconRepository.nickname}
                  callback={() => handleDeleteIcon(iconData.iconRepository.id)}
                />
              </Dialog.Root>
            </ContainerButton>
          </Container>
        )}
      </MasterMain>
    </MasterContainer>
  );
};

export default ViewIcon;
