import styled from "styled-components";
import { lighten, darken } from "polished";

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
`;

export const Form = styled.form`
  padding: 0px;
`;

export const ContainerInput = styled.div`
  & + & {
    margin-top: 15px;
  }
`;

export const LabelInput = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const ErrorInput = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red};
`;

export const WarningText = styled.span`
  display: block;
  margin: 2px 0 4px 0;
  padding: 8px 15px;
  border: 1px solid;
  border-color: ${({ theme }) => darken(0.1, theme.colors.yellow)};
  border-radius: 5px;
  background-color: ${({ theme }) => lighten(0.45, theme.colors.yellow)};
  color: ${({ theme }) => darken(0.1, theme.colors.yellow)};
  font-weight: 600;
`;
