import styled from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  padding: 0px;
`;

export const InputText = styled.input`
  width: 100%;
  height: 40;
  padding: 10px 13px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  :focus {
    border-color: ${({ theme }) => lighten(0.4, theme.colors.purple)};
    box-shadow: 0px 0px 2px 0.5px
      ${({ theme }) => lighten(0.4, theme.colors.purple)};
  }
`;
