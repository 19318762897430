import React, { ButtonHTMLAttributes } from "react";
import { Container } from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  color?: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, color, children, ...rest }) => {
  return (
    <Container onClick={onClick} color={color} {...rest}>
      {children}
    </Container>
  );
};

export default Button;
