import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateTemplateCategory from "./dtos/IFormCreateTemplateCategory";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateTemplateCategory: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  const formSchema = yup.object().shape({
    title: yup.string().required("Título é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    getUrl("/create-template-category");
  }, []);

  const onSubmit: SubmitHandler<IFormCreateTemplateCategory> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const createTemplateCategory = await api.post(
          "/bits-template-categories",
          {
            title: formData.title,
          }
        );

        if (createTemplateCategory.status === 200) {
          Notify({
            text: "Categoria de template criada com sucesso",
            type: "success",
          });
          setLoading(false);
          navigate("/template-category", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar categoria de template" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Título *</LabelInput>
              <Input
                label="title"
                register={register}
                required={true}
                placeholder="Digite o título"
              />
              {errors.title && <ErrorInput>{errors.title.message}</ErrorInput>}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/template-category", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateTemplateCategory;
