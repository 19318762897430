import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import * as Dialog from "@radix-ui/react-dialog";
import { useRoutes } from "../../../../../../hooks/routes";

import IListClauseApiProps from "./dtos/IListClauseApiProps";
import ICreatorAndEditorProps from "./dtos/ICreatorAndEditorProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import DialogModal from "../../../../../../shared/components/DialogModal";

import { Notify } from "../../../../../../shared/utils";

import {
  Container,
  Title,
  Subtitle,
  ContainerLoading,
  ContainerButton,
} from "./styles";

const ViewClause: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [creator, setCreator] = useState<ICreatorAndEditorProps>({ name: "" });
  const [lastEditor, setLastEditor] = useState<ICreatorAndEditorProps>({
    name: "",
  });
  const [clauseData, setClauseData] = useState<IListClauseApiProps>({
    bitsClause: {
      id: "",
      creator_id: "",
      last_editor_id: "",
      title: "",
      clause: "",
      description: "",
      key_words: "",
      usage_counter: 0,
      created_at: new Date(),
      updated_at: new Date(),
    },
    bitsClauseCategories: [],
  });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { getUrl } = useRoutes();

  const { data: dataUseAuth } = useAuth();

  const userPermissionReadUser = dataUseAuth.admin_user_permissions.read_users;

  const userPermissionUpdate =
    dataUseAuth.admin_user_permissions.update_clauses;
  const userPermissionDelete =
    dataUseAuth.admin_user_permissions.delete_clauses;

  const { clause_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/view-clause/:clause_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listClauseSpecific = await api.get(`/bits-clause/${clause_id}`);

      if (userPermissionReadUser) {
        const userCreator = await api.get(
          `/profile/${listClauseSpecific.data.bitsClause.creator_id}`
        );

        const userLastEditor = await api.get(
          `/profile/${listClauseSpecific.data.bitsClause.last_editor_id}`
        );

        setCreator({
          name: `${userCreator.data.people.first_name} ${userCreator.data.people.last_name}`,
        });
        setLastEditor({
          name: `${userLastEditor.data.people.first_name} ${userLastEditor.data.people.last_name}`,
        });
      } else {
        setCreator({
          name: "Anônimo",
        });
        setLastEditor({
          name: "Anônimo",
        });
      }

      setClauseData(listClauseSpecific.data);
      setLoading(false);
    })();
  }, [clause_id, userPermissionReadUser]);

  const handleDeleteClause = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/bits-clause/${id}`);
        Notify({
          text: "Cláusula deletada com sucesso",
          type: "success",
        });
        navigate("/clauses", {
          state: { params: dataLocation?.params },
        });
      } catch (error: any) {
        console.log(error);
      }
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Visualizar cláusula" />
      <MasterMain>
        {loading && (
          <ContainerLoading>
            <SyncLoader loading={loading} color="#663399" size={10} />
          </ContainerLoading>
        )}

        {!loading && (
          <Container>
            <Title>Título:</Title>
            <Subtitle>
              {clauseData.bitsClause.title
                ? clauseData.bitsClause.title
                : "null"}
            </Subtitle>

            <Title>Cláusula:</Title>
            <Subtitle>
              {clauseData.bitsClause.clause
                ? clauseData.bitsClause.clause
                : "null"}
            </Subtitle>

            <Title>Descrição:</Title>
            <Subtitle>
              {clauseData.bitsClause.description
                ? clauseData.bitsClause.description
                : "null"}
            </Subtitle>

            <Title>Palavras chave:</Title>
            <Subtitle>
              {clauseData.bitsClause.key_words
                ? clauseData.bitsClause.key_words
                : "null"}
            </Subtitle>

            <Title>Número de vezes usada:</Title>
            <Subtitle>{clauseData.bitsClause.usage_counter}</Subtitle>

            <Title>Criador:</Title>
            <Subtitle>{creator.name}</Subtitle>

            <Title>Último a editar:</Title>
            <Subtitle>{lastEditor.name}</Subtitle>

            <Title>Criado em:</Title>
            <Subtitle>
              {`${new Date(clauseData.bitsClause.created_at)}`
                ? `${format(
                    new Date(clauseData.bitsClause.created_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Title>Última atualização:</Title>
            <Subtitle>
              {`${new Date(clauseData.bitsClause.updated_at)}`
                ? `${format(
                    new Date(clauseData.bitsClause.updated_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 20 }} />

            <Title>Categorias:</Title>
            {clauseData.bitsClauseCategories.map((item) => (
              <Subtitle key={item.id} style={{ marginBottom: 0, marginTop: 5 }}>
                {item.title}
              </Subtitle>
            ))}

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <ContainerButton>
              <Button
                style={{ width: 100 }}
                onClick={() =>
                  navigate("/clauses", {
                    state: { params: dataLocation?.params },
                  })
                }
              >
                Voltar
              </Button>

              <Button
                style={{ width: 100, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/edit-clause/${clause_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionUpdate}
              >
                Editar
              </Button>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 100, marginLeft: 20 }}
                    disabled={!userPermissionDelete}
                  >
                    Excluir
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title="Deletar cláusula"
                  type="delete"
                  subtitle={clauseData.bitsClause.title}
                  callback={() => handleDeleteClause(clauseData.bitsClause.id)}
                />
              </Dialog.Root>
            </ContainerButton>
          </Container>
        )}
      </MasterMain>
    </MasterContainer>
  );
};

export default ViewClause;
