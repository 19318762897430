import React, { useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

import IconSvg from "../IconSvg";

import iconDragAndDrop from "../../assets/drag-and-drop.svg";

import {
  Container,
  ContainerMain,
  ContainerUploadImage,
  ContainerImage,
  Image,
  ContainerImageButton,
  ButtonUpdate,
  ButtonDelete,
} from "./styles";

interface InputProps {
  isButtonRemove: boolean;
  preloaded: boolean;
  urlImagePreloaded: string;
  imageIsVisible: boolean;
  setImageIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  values: ImageListType;
  onchange: (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => void;
}

const ImageUpload: React.FC<InputProps> = ({
  isButtonRemove,
  preloaded,
  urlImagePreloaded,
  values,
  onchange,
  imageIsVisible,
  setImageIsVisible,
}) => {
  return (
    <ContainerMain>
      <ImageUploading
        multiple
        value={values}
        onChange={onchange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <Container className="upload__image-wrapper">
            {!imageIsVisible && !preloaded && (
              <ContainerUploadImage onClick={onImageUpload} {...dragProps}>
                <IconSvg image={iconDragAndDrop} />
              </ContainerUploadImage>
            )}

            {preloaded && (
              <ContainerImage className="image-item">
                <Image src={urlImagePreloaded} />
                <ContainerImageButton className="image-item__btn-wrapper">
                  <ButtonUpdate type="button" onClick={() => onImageUpdate(0)}>
                    Atualizar
                  </ButtonUpdate>
                </ContainerImageButton>
              </ContainerImage>
            )}

            {imageIsVisible &&
              !preloaded &&
              imageList.map((image, index) => (
                <ContainerImage key={index} className="image-item">
                  <Image src={image["data_url"]} />
                  <ContainerImageButton className="image-item__btn-wrapper">
                    <ButtonUpdate
                      type="button"
                      onClick={() => onImageUpdate(index)}
                    >
                      Atualizar
                    </ButtonUpdate>

                    {isButtonRemove && (
                      <ButtonDelete
                        type="button"
                        onClick={() => {
                          onImageRemove(index);
                          setImageIsVisible(false);
                        }}
                      >
                        Remover
                      </ButtonDelete>
                    )}
                  </ContainerImageButton>
                </ContainerImage>
              ))}
          </Container>
        )}
      </ImageUploading>
    </ContainerMain>
  );
};

export default ImageUpload;
