import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../../../services/api";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { useAuth } from "../../../../../hooks/auth";
import { debounce } from "lodash";
import { useRoutes } from "../../../../../hooks/routes";

import IHookForm from "../../../../../shared/dtos/IHookForm";
import IListTextReplacementApiProps from "./dtos/IListTextReplacementApiProps";
import IListTextReplacementDataParams from "./dtos/IListTextReplacementDataParams";
import IFormSearch from "./dtos/IFormSearch";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../../shared/components/DialogModal";
import Button from "../../../../../shared/components/Button";
import Input from "../../../../../shared/components/Input";
import IconSvg from "../../../../../shared/components/IconSvg";
import Pagination from "../../../../../shared/components/Pagination";
import Tooltip from "../../../../../shared/components/Tooltip";

import penIcon from "../../../../../shared/assets/pen-icon.svg";
import trashIcon from "../../../../../shared/assets/trash-icon.svg";
import iconSearchWhite from "../../../../../shared/assets/icon-search-white.svg";

import { Notify } from "../../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  ButtonSearch,
  FlexBox,
  HStack,
  Main,
  Form,
  ContainerCreate,
  FlexPagination,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
} from "./styles";

const ListTextReplacement: React.FC = () => {
  const location = useLocation();
  const dataLocation: any = location.state;

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dataTextReplacementList, setDataTextReplacementList] =
    useState<IListTextReplacementApiProps>({
      pagination: 0,
      dataArray: [],
    });
  const [listTextReplacementDataParams, setListTextReplacementDataParams] =
    useState<IListTextReplacementDataParams>({
      pagination:
        dataLocation && dataLocation.params != null
          ? dataLocation.params.pagination
          : 0,
    });

  const { data: dataUseAuth } = useAuth();

  const permissionCreate =
    dataUseAuth.admin_user_permissions.create_text_replacement;
  const permissionRead =
    dataUseAuth.admin_user_permissions.read_text_replacement;
  const permissionUpdate =
    dataUseAuth.admin_user_permissions.update_text_replacement;
  const permissionDelete =
    dataUseAuth.admin_user_permissions.delete_text_replacement;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/text-replacement");
  }, []);

  const handleCreateTextReplacement = useCallback(() => {
    navigate("/create-text-replacement", {
      state: { params: listTextReplacementDataParams },
    });
  }, [navigate, listTextReplacementDataParams]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/text-replacement", {
        params: listTextReplacementDataParams,
      });

      setDataTextReplacementList(response.data);
      setTotalPages(response.data.pagination);
      setLoading(false);
    })();
  }, [reload, listTextReplacementDataParams]);

  const handleDeleteTextReplacement = useCallback(
    async (id: string) => {
      await api.delete(`/text-replacement/${id}`);
      setReload(!reload);
    },
    [reload]
  );

  const formSchema = yup.object().shape({
    search: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = useCallback(
    debounce((formData: string) => {
      try {
        setLoading(true);
        if (formData === "") {
          setListTextReplacementDataParams({
            pagination: 0,
          });
        } else {
          setListTextReplacementDataParams({
            pagination: 0,
            search: formData,
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
      }
    }, 400),
    []
  );

  const navigateView = useCallback(
    (item) => {
      navigate(`/view-text-replacement/${item.id}`, {
        state: { params: listTextReplacementDataParams },
      });
    },
    [navigate, listTextReplacementDataParams]
  );

  const notifyRead = useCallback(() => {
    Notify({ text: "Você não tem permissão de visualizar", type: "error" });
  }, []);

  const handleInputSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value;

      onSubmit(searchTerm);
    },
    [onSubmit]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Listar palavras para substituição" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button
              onClick={handleCreateTextReplacement}
              disabled={!permissionCreate}
            >
              Criar palavra para substituição
            </Button>
          </ContainerCreate>

          <HStack>
            <Input
              onChangeInput={handleInputSearchChange}
              label="search"
              register={register}
              required={true}
              placeholder="Pesquisar"
              style={{ width: "100%" }}
            />
          </HStack>

          <BoxList>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#663399" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>Palavra principal</TableTh>
                    <TableTh>Palavra de substituição</TableTh>
                    <TableTh>Data de criação</TableTh>
                    <TableTh>Última modificação</TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                <TableTbody>
                  {dataTextReplacementList.dataArray.map((item, index) => (
                    <TableTr key={item.id}>
                      <TableTd
                        onClick={() =>
                          permissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.word}</TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          permissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.replacement}</TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          permissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          permissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.updated_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Tooltip
                            title="Editar palavra de substituição"
                            placement="top"
                          >
                            <ButtonFlexBox
                              onClick={() => {
                                navigate(`/edit-text-replacement/${item.id}`, {
                                  state: {
                                    params: listTextReplacementDataParams,
                                  },
                                });
                              }}
                              disabled={!permissionUpdate}
                            >
                              <IconSvg width="18" height="18" image={penIcon} />
                            </ButtonFlexBox>
                          </Tooltip>

                          <Dialog.Root>
                            <Tooltip
                              title="Deletar palavra de substituição"
                              placement="top"
                            >
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox disabled={!permissionDelete}>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={trashIcon}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title="Deletar palavra de substituição"
                              subtitle={item.word}
                              type="delete"
                              callback={() =>
                                handleDeleteTextReplacement(item.id)
                              }
                            />
                          </Dialog.Root>
                        </FlexBox>
                      </TableTd>
                    </TableTr>
                  ))}
                </TableTbody>
              </Table>
            )}
          </BoxList>
          <FlexPagination>
            {dataTextReplacementList.dataArray.length > 0 && (
              <Pagination
                total={totalPages}
                offset={listTextReplacementDataParams.pagination}
                setDataParams={setListTextReplacementDataParams}
                dataParams={listTextReplacementDataParams}
              />
            )}
          </FlexPagination>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ListTextReplacement;
