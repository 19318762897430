import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { ImageListType } from "react-images-uploading";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormEditIcon from "./dtos/IFormEditIcon";
import IListIconCategoryApiProps from "./dtos/IListIconCategoryApiProps";
import IIconCategories from "./dtos/IIconCategories";
import IListIconApiProps from "./dtos/IListIconApiProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import InputRadio from "../../../../../../shared/components/InputRadio";
import ImageUpload from "../../../../../../shared/components/ImageUpload";
import InputMultSelect from "../../../../../../shared/components/InputMultSelect";
import ChipInput from "../../../../../../shared/components/ChipInput";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const EditIcon: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [optionSelect, setOptionSelect] = useState<Array<string>>([]);
  const [imageIcon, setImageIcon] = useState<ImageListType>([]);
  const [imageIsVisible, setImageIsVisible] = useState(false);
  const [preloaded, setPreloaded] = useState(true);
  const [iconData, setIconData] = useState<IListIconApiProps>({
    id: "",
    nickname: "",
    creator_id: "",
    last_editor_id: "",
    name: "",
    key_words: "",
    dpath: "",
    usage_counter: 0,
    is_icon_filled: false,
    icon_url: "",
    stroke_width: 0,
    created_at: new Date(),
    updated_at: new Date(),
  });
  const [textErrorImageUpload, setTextErrorImageUpload] = useState<{
    sizeError: boolean;
    genericError: boolean;
  }>({ sizeError: false, genericError: false });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { getUrl } = useRoutes();

  const { icon_id } = useParams();

  useEffect(() => {
    getUrl("/edit-icon/:icon_id");
  }, []);

  const formSchema = yup.object().shape({
    nickname: yup.string().required("nome do ícone é um campo obrigatório"),
    key_words: yup
      .array()
      .of(yup.string())
      .required("palavras chave é um campo obrigatório"),
    dpath: yup.string().required("dpath é um campo obrigatório"),
    stroke_width: yup
      .number()
      .typeError("não pode ser vazio, e precisa ser do tipo número")
      .min(0, "o valor mínimo para espessura é 0")
      .required("espessura é um campo obrigatório"),
    is_icon_filled: yup
      .string()
      .required("ícone tem fill é um campo obrigatório"),
    categories: yup
      .array()
      .min(1, "categorias é um campo obrigatório")
      .required("categorias é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const listIconCategory = await api.get(
        "/admin/icon-categories/list-all-dashboard"
      );

      let options: Array<string> = [];

      listIconCategory.data.forEach((item: IListIconCategoryApiProps) => {
        options.push(`${item.title}||${item.id}`);
      });

      setOptionSelect(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const listIconSpecific = await api.get(`/icon-repository/${icon_id}`);

      setIconData(listIconSpecific.data.iconRepository);

      let isIconFilled = "false";

      if (listIconSpecific.data.iconRepository.is_icon_filled === false)
        isIconFilled = "false";

      if (listIconSpecific.data.iconRepository.is_icon_filled === true)
        isIconFilled = "true";

      let defaultValue = [];

      listIconSpecific.data.iconCategories.forEach((item: IIconCategories) => {
        defaultValue.push(`${item.title}||${item.id}`);
      });

      let keyWords: Array<string> = [];

      if (listIconSpecific.data.iconRepository.key_words) {
        keyWords = listIconSpecific.data.iconRepository.key_words.split(",");
      }

      setValue("categories", defaultValue, { shouldValidate: true });
      reset({
        nickname: listIconSpecific.data.iconRepository.nickname,
        key_words: keyWords,
        dpath: listIconSpecific.data.iconRepository.dpath,
        stroke_width: listIconSpecific.data.iconRepository.stroke_width,
        is_icon_filled: isIconFilled,
        categories: defaultValue,
      });
    })();
  }, [icon_id, reset, setValue]);

  const onSubmit: SubmitHandler<IFormEditIcon> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        let newCategories = [];

        formData.categories.forEach((item) => {
          const [label, id] = String(item).split("||");

          newCategories.push({
            icon_category_id: id,
          });
        });

        let isIconFilled = false;

        if (formData.is_icon_filled === "false") isIconFilled = false;
        if (formData.is_icon_filled === "true") isIconFilled = true;

        let keyWord = "";

        formData.key_words.forEach((item, index) => {
          if (index < formData.key_words.length - 1) {
            if (item.trim().length !== 0) {
              keyWord += `${item.toLowerCase().trim()},`;
            }
          } else {
            if (item.trim().length !== 0) {
              keyWord += `${item.toLowerCase().trim()}`;
            } else {
              keyWord = keyWord.substring(0, keyWord.length - 1);
            }
          }
        });

        const updateIcon = await api.put("/icon-repository", {
          icon_repository_id: icon_id,
          nickname: formData.nickname,
          key_words: keyWord,
          dpath: formData.dpath,
          stroke_width: formData.stroke_width,
          is_icon_filled: isIconFilled,
          categories: newCategories,
        });

        if (updateIcon.status === 200) {
          Notify({ text: "Ícone atualizado com sucesso", type: "success" });
          setLoading(false);
          navigate("/icons", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [icon_id, navigate, dataLocation]
  );

  const onChangeImageIcon = useCallback(
    async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
      if (imageList.length <= 0) {
        setTextErrorImageUpload({ sizeError: false, genericError: true });
      } else {
        if (imageList[0].file.size <= 1000000) {
          setTextErrorImageUpload({ sizeError: false, genericError: false });

          const patch = new FormData();

          patch.append("icon", imageList[0].file);

          const uploadIcon = await api.patch(
            `icon-repository/icon-upload/${icon_id}`,
            patch,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (uploadIcon.status === 200) {
            setImageIcon(imageList);
            setImageIsVisible(true);
            setPreloaded(false);
            Notify({ text: "Ícone atualizado com sucesso", type: "success" });
          }
        } else {
          setTextErrorImageUpload({ sizeError: true, genericError: false });
        }
      }
    },
    [icon_id]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Editar ícone" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome do ícone *</LabelInput>
              <Input
                label="nickname"
                register={register}
                required={true}
                placeholder="Digite o nome do ícone"
              />
              {errors.nickname && (
                <ErrorInput>{errors.nickname.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Palavras chave *</LabelInput>

              <TooltipInformation
                title="A palavra chave é por onde o usuário irá encontrar esse 
                  ícone. Então adicione o máximo de palavras que achar 
                  necessário. Aperte a tecla enter para adicionar a palavra chave"
                placement="top"
                maxWidth={300}
              />

              <ChipInput
                control={control}
                label="key_words"
                required={true}
                defaultValue={[]}
                placeholder="Palavras chave"
              />
              {errors.key_words && (
                <ErrorInput>{errors.key_words.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>DPATH *</LabelInput>

              <TooltipInformation
                title="O DPATH é o código do ícone. Você consegue copiar esse 
                código se abrir o ícone no navegador e apertar a tecla F12, você
                verá a tag D com o path dentro, copie todo o código das tags D
                caso tenha mais que uma tag D copie todos os códigos em uma única
                linha e separe os códigos com vírgula"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="dpath"
                register={register}
                required={true}
                placeholder="Digite o dpath"
              />
              {errors.dpath && <ErrorInput>{errors.dpath.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Espessura *</LabelInput>

              <TooltipInformation
                title="Recomendamos usar o valor em 1, porém, precisa testar e
                ver qual é o melhor valor"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="stroke_width"
                register={register}
                required={true}
                placeholder="Digite a espessura"
                type="number"
                step="0.1"
              />
              {errors.stroke_width && (
                <ErrorInput>{errors.stroke_width.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Ícone tem fill? *</LabelInput>

              <TooltipInformation
                title="O fill é se ele é preenchido ou não. Por exemplo o ícone
                de um carro todo preto preenchido ou um ícone de um carro so com
                a borda porem o interno do ícone é transparente"
                placement="top"
                maxWidth={300}
              />

              <InputRadio
                id="is_icon_filled_true"
                label="is_icon_filled"
                register={register}
                required={true}
                newValue="true"
                text="Sim"
              />
              <InputRadio
                id="is_icon_filled_false"
                label="is_icon_filled"
                register={register}
                required={true}
                newValue="false"
                text="Não"
              />
              {errors.is_icon_filled && (
                <ErrorInput>Ícone fill é obrigatório</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione as categorias *</LabelInput>

              <TooltipInformation
                title="Se a categoria que você quer não estiver na lista. Você 
                  deve ir em categoria de ícones e cadastrar a categoria que 
                  deseja"
                placement="top"
                maxWidth={300}
              />

              <InputMultSelect
                control={control}
                label="categories"
                options={optionSelect}
              />
              {errors.categories && (
                <ErrorInput>{errors.categories.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione o ícone *</LabelInput>

              <TooltipInformation
                title="O arquivo do ícone deve ser exclusivamente em SVG. Na
                confecção do ícone, ele deve ser feito nas proporções de 50x50px"
                placement="top"
                maxWidth={300}
              />

              <ImageUpload
                isButtonRemove={false}
                preloaded={preloaded}
                urlImagePreloaded={iconData.icon_url}
                values={imageIcon}
                onchange={onChangeImageIcon}
                imageIsVisible={imageIsVisible}
                setImageIsVisible={setImageIsVisible}
              />

              {textErrorImageUpload.genericError && (
                <ErrorInput>Selecione um ícone</ErrorInput>
              )}

              {textErrorImageUpload.sizeError && (
                <ErrorInput>
                  Ícone muito grande, seleciona uma ícone com menos MB
                </ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 5, marginTop: 10 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/icons", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default EditIcon;
