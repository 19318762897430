import React, { InputHTMLAttributes } from "react";
import { Path, UseFormRegister } from "react-hook-form";

import { Container, InputText, Text } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  newValue: string;
  text: string;
}

const Input: React.FC<InputProps> = ({
  label,
  register,
  required,
  newValue,
  text,
  id,
  ...rest
}) => {
  return (
    <Container>
      <InputText
        id={id}
        type="radio"
        value={newValue}
        {...register(label, { required })}
        {...rest}
      />

      <Text htmlFor={id}>{text}</Text>
    </Container>
  );
};

export default Input;
