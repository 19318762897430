import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { ImageListType } from "react-images-uploading";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateImage from "./dtos/IFormCreateImage";
import IListImageCategoryApiProps from "./dtos/IListImageCategoryApiProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import ImageUpload from "../../../../../../shared/components/ImageUpload";
import InputMultSelect from "../../../../../../shared/components/InputMultSelect";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateImage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [optionSelect, setOptionSelect] = useState<Array<string>>([]);
  const [image, setImage] = useState<ImageListType>([]);
  const [imageIsVisible, setImageIsVisible] = useState(false);
  const [textErrorImageUpload, setTextErrorImageUpload] = useState<{
    sizeError: boolean;
    genericError: boolean;
  }>({ sizeError: false, genericError: false });

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/create-image");
  }, []);

  useEffect(() => {
    (async () => {
      const listImageCategory = await api.get(
        "/bits-image-categories/list-all-dashboard"
      );

      let options = [];

      listImageCategory.data.forEach((item: IListImageCategoryApiProps) => {
        options.push(`${item.title}||${item.id}`);
      });

      setOptionSelect(options);
    })();
  }, []);

  const formSchema = yup.object().shape({
    name: yup.string().required("nome da ilustração é um campo obrigatório"),
    categories: yup
      .array()
      .min(1, "categorias é um campo obrigatório")
      .required("categorias é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormCreateImage> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        if (image.length <= 0) {
          setTextErrorImageUpload({ sizeError: false, genericError: true });
          setLoading(false);
        } else {
          if (image[0].file.size <= 1000000) {
            setTextErrorImageUpload({ sizeError: false, genericError: false });
            let newCategories = [];

            formData.categories.forEach((item) => {
              const [label, id] = String(item).split("||");

              newCategories.push({
                bits_image_categories_id: id,
              });
            });

            const createImageRepository = await api.post("/bits-image", {
              name: formData.name,
              categories: newCategories,
            });

            const patch = new FormData();

            patch.append("image", image[0].file);

            const uploadImage = await api.patch(
              `bits-image/image-upload/${createImageRepository.data.id}`,
              patch,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (uploadImage.status === 200) {
              Notify({
                text: "Ilustração cadastrada com sucesso",
                type: "success",
              });
              setLoading(false);
              navigate("/image", {
                state: { params: dataLocation?.params },
              });
            }
          } else {
            setTextErrorImageUpload({ sizeError: true, genericError: false });
            setLoading(false);
          }
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [navigate, image, dataLocation]
  );

  const onChangeImage = useCallback(
    (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
      setImage(imageList);
      setImageIsVisible(true);
    },
    []
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar ilustrações" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome da imagem *</LabelInput>
              <Input
                label="name"
                register={register}
                required={true}
                placeholder="Digite o nome da ilustração"
              />
              {errors.name && <ErrorInput>{errors.name.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione as categorias *</LabelInput>

              <TooltipInformation
                title="Se a categoria que você quer não estiver na lista. Você 
                  deve ir em categoria de ilustrações e cadastrar a categoria que 
                  deseja"
                placement="top"
                maxWidth={300}
              />

              <InputMultSelect
                control={control}
                label="categories"
                options={optionSelect}
              />
              {errors.categories && (
                <ErrorInput>{errors.categories.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione a ilustração *</LabelInput>

              <TooltipInformation
                title="O arquivo da ilustração deve ser exclusivamente em PNG"
                placement="top"
                maxWidth={300}
              />

              <ImageUpload
                isButtonRemove={true}
                preloaded={false}
                urlImagePreloaded=""
                values={image}
                onchange={onChangeImage}
                imageIsVisible={imageIsVisible}
                setImageIsVisible={setImageIsVisible}
              />

              {textErrorImageUpload.genericError && (
                <ErrorInput>Selecione uma ilustração</ErrorInput>
              )}
              {textErrorImageUpload.sizeError && (
                <ErrorInput>
                  Ilustração muito grande, seleciona uma ilustração com menos MB
                </ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 5, marginTop: 10 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/image", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" disabled={true} style={{ marginTop: 30 }}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateImage;
