import styled from "styled-components";
import { lighten } from "polished";

interface IContainerProps {
  typeColor: string;
}

export const Container = styled.div<IContainerProps>`
  padding: 10px 20px;
  border-radius: 5px;
  background: ${({ typeColor }) => lighten(0.48, typeColor)};
  color: ${({ typeColor }) => typeColor};
  border: 1px solid ${({ typeColor }) => typeColor};
  font-weight: 500;
  margin-bottom: 20px;
`;
