import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { parse } from "date-fns";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormUpdateUserSubscription from "./dtos/IFormUpdateUserSubscription";
import IListAllPlansApiPorps from "./dtos/IListAllPlansApiProps";
import IOptionSelectPlans from "./dtos/IOptionSelectPlans";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import InputSelect from "../../../../../../shared/components/InputSelect";
import InputMask from "../../../../../../shared/components/InputMask";
import InputRadio from "../../../../../../shared/components/InputRadio";
import Input from "../../../../../../shared/components/Input";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import {
  Notify,
  DateFormatedToDatabase,
  DateFormatedToFrontendUTC,
} from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const UpdateUserSubscription: React.FC = () => {
  const [showCancellationDate, setShowCancellationDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionSelectPlans, setOptionSelectPlans] = useState<
    Array<IOptionSelectPlans>
  >([]);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { user_id } = useParams();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/update-user-subscription/:user_id");
  }, []);

  const formSchema = yup.object().shape({
    plan_id: yup.string().required("plano é um campo obrigatório"),
    user_type: yup.string().required("tipo do usuário é um campo obrigatório"),
    customer_id: yup.string().nullable(),
    subscription_id: yup.string().nullable(),
    is_cancellation_date: yup
      .string()
      .required("remover data de vigência é um campo obrigatório"),
    cancellation_date: yup
      .date()
      .transform((value, originalValue, context) => {
        return parse(originalValue, "dd/MM/yyyy", new Date());
      })
      .typeError("data de vigência está invalida"),
  });

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    (async () => {
      const listPlans = await api.get("/admin/plans/list-all");

      let options = [];

      listPlans.data.forEach((item: IListAllPlansApiPorps) => {
        options.push({ label: item.name, value: item.id });
      });

      setOptionSelectPlans(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const listUserPlanSpecific = await api.get(`/users-plans/${user_id}`);

      if (listUserPlanSpecific.data.cancellation_date) {
        setShowCancellationDate(true);

        const newDate = DateFormatedToFrontendUTC({
          date: listUserPlanSpecific.data.cancellation_date,
        });

        reset({
          plan_id: listUserPlanSpecific.data.plan_id,
          user_type: listUserPlanSpecific.data.user_type,
          cancellation_date: newDate,
          is_cancellation_date: "true",
          customer_id: listUserPlanSpecific.data.customer_id,
          subscription_id: listUserPlanSpecific.data.subscription_id,
        });
      } else {
        setShowCancellationDate(false);

        const newDate = DateFormatedToFrontendUTC({
          date: new Date(),
        });

        reset({
          plan_id: listUserPlanSpecific.data.plan_id,
          user_type: listUserPlanSpecific.data.user_type,
          cancellation_date: newDate,
          is_cancellation_date: "false",
          customer_id: listUserPlanSpecific.data.customer_id,
          subscription_id: listUserPlanSpecific.data.subscription_id,
        });
      }
    })();
  }, [user_id, reset]);

  const onSubmit: SubmitHandler<IFormUpdateUserSubscription> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const newDate = DateFormatedToDatabase({
          date: formData.cancellation_date,
        });

        let customerId = "";
        if (formData.customer_id === "") customerId = "";
        if (formData.customer_id === null) customerId = "";

        let subscriptionId = "";
        if (formData.subscription_id === "") subscriptionId = "";
        if (formData.subscription_id === null) subscriptionId = "";

        const resetPassword = await api.put("/users-plans", {
          user_id: user_id,
          plan_id: formData.plan_id,
          cancellation_date:
            formData.is_cancellation_date === "true" ? newDate : undefined,
          user_type: formData.user_type,
          customer_id: customerId === "" ? undefined : formData.customer_id,
          subscription_id:
            subscriptionId === "" ? undefined : formData.subscription_id,
        });

        if (resetPassword.status === 200) {
          Notify({
            text: "Assinatura do usuário atualizada com sucesso",
            type: "success",
          });
          setLoading(false);
          navigate("/users", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [navigate, user_id, dataLocation]
  );

  const optionsUserTypeUserPlan = [
    { value: "b2b", label: "B2B" },
    { value: "ld_pass", label: "Legal Design Pass" },
    { value: "cortesia", label: "Cortesia" },
    { value: "pre_venda", label: "Pré Venda" },
    { value: "user_free", label: "Usuário padrão" },
  ];

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Atualizar assinatura do usuário" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Plano *</LabelInput>

              <InputSelect
                label="plan_id"
                defaultValue=""
                options={optionSelectPlans}
                control={control}
              />
              {errors.plan_id && (
                <ErrorInput>{errors.plan_id.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Tipo do usuário *</LabelInput>

              <InputSelect
                label="user_type"
                defaultValue=""
                options={optionsUserTypeUserPlan}
                control={control}
              />
              {errors.user_type && (
                <ErrorInput>{errors.user_type.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>ID de cliente do Stripe</LabelInput>

              <TooltipInformation
                title="Esse é o ID do usuário no Stripe. Caso esteja vazio é 
                  porque ou o usuário é de Legual Design Pass, cortesia, B2B, ou 
                  ainda está no periodo de teste"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="customer_id"
                register={register}
                required={true}
                placeholder="Digite o ID do cliente do Stripe"
              />
              {errors.customer_id && (
                <ErrorInput>{errors.customer_id.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>ID da assinatura do usuário do Stripe</LabelInput>

              <TooltipInformation
                title="Esse é o ID da assinatura do usuário no Stripe. Caso 
                  esteja vazio e o id do clinete acima esteja vazio também, é 
                  a mesma sintuação descrita na informação acima. Caso o ID do 
                  cliente esteja preenchido e esse ID da assinatura não esteja 
                  preenchido, o usuário apenas não pagou ainda"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="subscription_id"
                register={register}
                required={true}
                placeholder="Digite o ID da assinatura do usuário do Stripe"
              />
              {errors.subscription_id && (
                <ErrorInput>{errors.subscription_id.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Cadastrar data de vigência?</LabelInput>

              <TooltipInformation
                title="Na data escolhida abaixo o usuário perderá acesso a conta 
                  e precisará pagar pelo Stripe para usar. Recomendado adicionar
                  data de vigência apenas para os usuários que não são do fluxo
                  do Stripe como Legal Design Pass, Cortesia e B2B. Caso queira
                  eliminar a data de vigência apenas selecionar o 'Não'"
                placement="top"
                maxWidth={300}
              />

              <InputRadio
                id="is_cancellation_date_true"
                label="is_cancellation_date"
                register={register}
                required={true}
                newValue="true"
                text="Sim"
                onClick={() => setShowCancellationDate(true)}
              />
              <InputRadio
                id="is_cancellation_date_false"
                label="is_cancellation_date"
                register={register}
                required={true}
                newValue="false"
                text="Não"
                onClick={() => setShowCancellationDate(false)}
              />
              {errors.is_cancellation_date && (
                <ErrorInput>{errors.is_cancellation_date.message}</ErrorInput>
              )}
            </ContainerInput>

            {showCancellationDate && (
              <ContainerInput>
                <LabelInput>Data de vigência *</LabelInput>
                <InputMask
                  mask="99/99/9999"
                  control={control}
                  label="cancellation_date"
                  register={register}
                  required={true}
                  placeholder="Digite a data"
                />
                {errors.cancellation_date && (
                  <ErrorInput>{errors.cancellation_date.message}</ErrorInput>
                )}
              </ContainerInput>
            )}

            <Divider style={{ marginBottom: 0, marginTop: 30 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/users", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default UpdateUserSubscription;
