import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../../../../services/api";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import { debounce } from "lodash";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IListImageApiProps from "./dtos/IListImageApiProps";
import IListImageDataParams from "./dtos/IListImageDataParams";
import IFormSearch from "./dtos/IFormSearch";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../../../shared/components/DialogModal";
import Button from "../../../../../../shared/components/Button";
import Input from "../../../../../../shared/components/Input";
import IconSvg from "../../../../../../shared/components/IconSvg";
import Pagination from "../../../../../../shared/components/Pagination";
import Tooltip from "../../../../../../shared/components/Tooltip";
import TooltipImage from "../../../../../../shared/components/TooltipImage";

import penIcon from "../../../../../../shared/assets/pen-icon.svg";
import trashIcon from "../../../../../../shared/assets/trash-icon.svg";
import iconSearchWhite from "../../../../../../shared/assets/icon-search-white.svg";

import { Notify } from "../../../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  ButtonSearch,
  FlexBox,
  HStack,
  Main,
  Form,
  ContainerCreate,
  FlexPagination,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
} from "./styles";

const ListImage: React.FC = () => {
  const location = useLocation();
  const dataLocation: any = location.state;

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dataImageList, setDataImageList] = useState<IListImageApiProps>({
    pagination: 0,
    dataArray: [],
  });
  const [listImageDataParams, setListImageDataParams] =
    useState<IListImageDataParams>({
      pagination:
        dataLocation && dataLocation.params != null
          ? dataLocation.params.pagination
          : 0,
    });

  const { data: dataUseAuth } = useAuth();

  const { getUrl } = useRoutes();

  const userPermissionCreate = dataUseAuth.admin_user_permissions.create_image;
  const userPermissionRead = dataUseAuth.admin_user_permissions.read_image;
  const userPermissionUpdate = dataUseAuth.admin_user_permissions.update_image;
  const userPermissionDelete = dataUseAuth.admin_user_permissions.delete_image;

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/image");
  }, []);

  const handleCreateImage = useCallback(() => {
    navigate("/create-image", {
      state: { params: listImageDataParams },
    });
  }, [navigate, listImageDataParams]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/bits-image", {
        params: listImageDataParams,
      });

      setDataImageList(response.data);
      setTotalPages(response.data.pagination);
      setLoading(false);
    })();
  }, [reload, listImageDataParams]);

  const handleDeleteImage = useCallback(
    async (id: string) => {
      await api.delete(`/bits-image/${id}`);
      setReload(!reload);
    },
    [reload]
  );

  const formSchema = yup.object().shape({
    search: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = useCallback(
    debounce((formData: string) => {
      try {
        setLoading(true);
        if (formData === "") {
          setListImageDataParams({
            pagination: 0,
          });
        } else {
          setListImageDataParams({
            pagination: 0,
            search: formData,
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
      }
    }, 400),
    []
  );

  const navigateView = useCallback(
    (item) => {
      navigate(`/view-image/${item.id}`, {
        state: { params: listImageDataParams },
      });
    },
    [navigate, listImageDataParams]
  );

  const notifyRead = useCallback(() => {
    Notify({ text: "Você não tem permissão de visualizar", type: "error" });
  }, []);

  const handleInputSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value;

      onSubmit(searchTerm);
    },
    [onSubmit]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Lista de ilustrações" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button
              onClick={handleCreateImage}
              disabled={!userPermissionCreate}
            >
              Criar ilustrações
            </Button>
          </ContainerCreate>

          <HStack>
            <Input
              onChangeInput={handleInputSearchChange}
              label="search"
              register={register}
              required={true}
              placeholder="Pesquisar"
              style={{ width: "100%" }}
            />
          </HStack>

          <BoxList>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#663399" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>Nome</TableTh>
                    <TableTh>Data de criação</TableTh>
                    <TableTh>Última modificação</TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                <TableTbody>
                  {dataImageList.dataArray.map((item, index) => (
                    <TableTr key={item.id}>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TooltipImage
                          srcImage={item.image_url}
                          placement="top"
                          widthImage={100}
                          heightImage={100}
                        >
                          <TableTdText>{item.name}</TableTdText>
                        </TooltipImage>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.updated_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Tooltip title="Editar ilustração" placement="top">
                            <ButtonFlexBox
                              disabled={!userPermissionUpdate}
                              onClick={() => {
                                navigate(`/edit-image/${item.id}`, {
                                  state: { params: listImageDataParams },
                                });
                              }}
                            >
                              <IconSvg width="18" height="18" image={penIcon} />
                            </ButtonFlexBox>
                          </Tooltip>

                          <Dialog.Root>
                            <Tooltip title="Deletar ilustração" placement="top">
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox disabled={!userPermissionDelete}>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={trashIcon}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title="Deletar ilustração"
                              subtitle={item.name}
                              type="delete"
                              callback={() => handleDeleteImage(item.id)}
                            />
                          </Dialog.Root>
                        </FlexBox>
                      </TableTd>
                    </TableTr>
                  ))}
                </TableTbody>
              </Table>
            )}
          </BoxList>
          <FlexPagination>
            {dataImageList.dataArray.length > 0 && (
              <Pagination
                total={totalPages}
                offset={listImageDataParams.pagination}
                setDataParams={setListImageDataParams}
                dataParams={listImageDataParams}
              />
            )}
          </FlexPagination>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ListImage;
