import styled from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  padding: 0px;
`;

export const InputText = styled.input`
  width: 100%;
  height: 40;
  padding: 10px 13px;
  border-top: 0;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  transition: border-color 0.5s;

  ::placeholder {
    color: ${({ theme }) => theme.colors.grayDark};
  }

  :focus {
    border-color: ${({ theme }) => lighten(0.4, theme.colors.purple)};
  }
`;
