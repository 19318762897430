import React from "react";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import TooltipMui, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

import { Image, ContainerImage } from "./styles";

interface InputProps {
  srcImage: string;
  children: any;
  maxWidth?: number;
  widthImage: number;
  heightImage: number;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const TooltipImage: React.FC<InputProps> = ({
  children,
  srcImage,
  widthImage,
  heightImage,
  placement,
  maxWidth,
}) => {
  const CustomTooltipMui = styled(({ className, ...props }: TooltipProps) => (
    <TooltipMui {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: maxWidth ? maxWidth : 200,
      textAlign: "center",
      backgroundColor: "#000",
      boxShadow: `0px 0px 5px 0px #775797`,
      paddingTop: 6,
      paddingBottom: 6,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#000",
    },
  });

  return (
    <CustomTooltipMui
      title={
        <ContainerImage>
          <Image
            srcImage={srcImage}
            widthImage={widthImage}
            heightImage={heightImage}
          />
        </ContainerImage>
      }
      placement={placement}
      arrow
      TransitionComponent={Zoom}
    >
      {children}
    </CustomTooltipMui>
  );
};

export default TooltipImage;
