import { addHours, format } from "date-fns";

interface IRequest {
  date: Date;
}

function DateFormatedToFrontendUTC({ date }: IRequest) {
  const newDate = addHours(new Date(date), 12).toUTCString();

  const dateFormated = format(new Date(newDate), "dd/MM/yyyy");

  return dateFormated;
}

export { DateFormatedToFrontendUTC };
