import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { ImageListType } from "react-images-uploading";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormEditImage from "./dtos/IFormEditImage";
import IListImageCategoryApiProps from "./dtos/IListImageCategoryApiProps";
import IImageCategories from "./dtos/IImageCategories";
import IListImageApiProps from "./dtos/IListImageApiProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import ImageUpload from "../../../../../../shared/components/ImageUpload";
import InputMultSelect from "../../../../../../shared/components/InputMultSelect";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const EditImage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [optionSelect, setOptionSelect] = useState<Array<string>>([]);
  const [image, setImage] = useState<ImageListType>([]);
  const [imageIsVisible, setImageIsVisible] = useState(false);
  const [preloaded, setPreloaded] = useState(true);
  const [imageData, setImageData] = useState<IListImageApiProps>({
    id: "",
    creator_id: "",
    last_editor_id: "",
    name: "",
    image: "",
    image_url: "",
    created_at: new Date(),
    updated_at: new Date(),
  });
  const [textErrorImageUpload, setTextErrorImageUpload] = useState<{
    sizeError: boolean;
    genericError: boolean;
  }>({ sizeError: false, genericError: false });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { image_id } = useParams();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/edit-image/:image_id");
  }, []);

  const formSchema = yup.object().shape({
    name: yup.string().required("nome da ilustração é um campo obrigatório"),
    categories: yup
      .array()
      .min(1, "categorias é um campo obrigatório")
      .required("categorias é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const listImageCategory = await api.get(
        "/bits-image-categories/list-all-dashboard"
      );

      let options: Array<string> = [];

      listImageCategory.data.forEach((item: IListImageCategoryApiProps) => {
        options.push(`${item.title}||${item.id}`);
      });

      setOptionSelect(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const listImageSpecific = await api.get(`/bits-image/${image_id}`);

      setImageData(listImageSpecific.data.bitsImage);

      let defaultValue = [];

      listImageSpecific.data.bitsImageCategories.forEach(
        (item: IImageCategories) => {
          defaultValue.push(`${item.title}||${item.id}`);
        }
      );

      setValue("categories", defaultValue, { shouldValidate: true });
      reset({
        name: listImageSpecific.data.bitsImage.name,
        categories: defaultValue,
      });
    })();
  }, [image_id, reset, setValue]);

  const onSubmit: SubmitHandler<IFormEditImage> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        let newCategories = [];

        formData.categories.forEach((item) => {
          const [label, id] = String(item).split("||");

          newCategories.push({
            bits_image_categories_id: id,
          });
        });

        const updateImage = await api.put("/bits-image", {
          bits_image_id: image_id,
          name: formData.name,
          categories: newCategories,
        });

        if (updateImage.status === 200) {
          Notify({
            text: "Ilustração atualizada com sucesso",
            type: "success",
          });
          setLoading(false);
          navigate("/image", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [image_id, navigate, dataLocation]
  );

  const onChangeImage = useCallback(
    async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
      try {
        if (imageList.length <= 0) {
          setTextErrorImageUpload({ sizeError: false, genericError: true });
        } else {
          if (imageList[0].file.size <= 1000000) {
            setTextErrorImageUpload({ sizeError: false, genericError: false });

            const patch = new FormData();

            patch.append("image", imageList[0].file);

            const uploadImage = await api.patch(
              `/bits-image/image-upload/${image_id}`,
              patch,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (uploadImage.status === 200) {
              setImage(imageList);
              setImageIsVisible(true);
              setPreloaded(false);
              Notify({
                text: "Ilustração atualizada com sucesso",
                type: "success",
              });
            }
          } else {
            setTextErrorImageUpload({ sizeError: true, genericError: false });
          }
        }
      } catch (error: any) {
        console.log(error?.response?.data);
      }
    },
    [image_id]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Editar ilustração" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome da ilustração *</LabelInput>
              <Input
                label="name"
                register={register}
                required={true}
                placeholder="Digite o nome do ilustração"
              />
              {errors.name && <ErrorInput>{errors.name.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione as categorias *</LabelInput>

              <TooltipInformation
                title="Se a categoria que você quer não estiver na lista. Você 
                  deve ir em categoria de ilustrações e cadastrar a categoria que 
                  deseja"
                placement="top"
                maxWidth={300}
              />

              <InputMultSelect
                control={control}
                label="categories"
                options={optionSelect}
              />
              {errors.categories && (
                <ErrorInput>{errors.categories.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione a ilustração *</LabelInput>

              <TooltipInformation
                title="O arquivo da ilustração deve ser exclusivamente em PNG"
                placement="top"
                maxWidth={300}
              />

              <ImageUpload
                isButtonRemove={false}
                preloaded={preloaded}
                urlImagePreloaded={imageData.image_url}
                values={image}
                onchange={onChangeImage}
                imageIsVisible={imageIsVisible}
                setImageIsVisible={setImageIsVisible}
              />

              {textErrorImageUpload.genericError && (
                <ErrorInput>Selecione uma ilustração</ErrorInput>
              )}

              {textErrorImageUpload.sizeError && (
                <ErrorInput>
                  Ilustração muito grande, seleciona uma ilustração com menos MB
                </ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 5, marginTop: 10 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/image", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default EditImage;
