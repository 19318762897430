import { format } from "date-fns";

interface IRequest {
  date: Date;
}

function DateFormatedToDatabase({ date }: IRequest) {
  // const newDate = new Date(date).toLocaleDateString();
  // const dateFormated = newDate.split("/").reverse().join("-");
  // return dateFormated;

  const parsedDate = new Date(date);

  const dateFormated = format(parsedDate, "yyyy-MM-dd");

  return dateFormated;
}

export { DateFormatedToDatabase };
