import React from "react";

import { Container } from "./styles";

interface AlertProps {
  message: string;
  type: "success" | "error" | "info" | "purple";
}

const Alert: React.FC<AlertProps> = ({ message, type }) => {
  let color = "";

  if (type === "success") color = "#00cc00";
  else if (type === "error") color = "#eb0400";
  else if (type === "info") color = "#eeab00";
  else if (type === "purple") color = "#663399";

  return <Container typeColor={color}>{message}</Container>;
};

export default Alert;
