import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../../../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import { debounce } from "lodash";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IListClauseCategoryApiProps from "./dtos/IListClauseCategoryApiProps";
import IListClauseCategoryDataParams from "./dtos/IListClauseCategoryDataParams";
import IFormSearch from "./dtos/IFormSearch";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../../../shared/components/DialogModal";
import Button from "../../../../../../shared/components/Button";
import Input from "../../../../../../shared/components/Input";
import IconSvg from "../../../../../../shared/components/IconSvg";
import Pagination from "../../../../../../shared/components/Pagination";
import Tooltip from "../../../../../../shared/components/Tooltip";

import penIcon from "../../../../../../shared/assets/pen-icon.svg";
import trashIcon from "../../../../../../shared/assets/trash-icon.svg";
import iconSearchWhite from "../../../../../../shared/assets/icon-search-white.svg";

import { Notify } from "../../../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  ButtonSearch,
  FlexBox,
  HStack,
  Main,
  Form,
  ContainerCreate,
  FlexPagination,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
} from "./styles";

const ListClauseCategory: React.FC = () => {
  const location = useLocation();
  const dataLocation: any = location.state;

  const { getUrl } = useRoutes();

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dataClauseCategoryList, setDataClauseCategoryList] =
    useState<IListClauseCategoryApiProps>({
      pagination: 0,
      dataArray: [],
    });
  const [listClauseCategoryDataParams, setListClauseCategoryDataParams] =
    useState<IListClauseCategoryDataParams>({
      pagination:
        dataLocation && dataLocation.params != null
          ? dataLocation.params.pagination
          : 0,
      filter:
        dataLocation && dataLocation.params != null
          ? dataLocation.params.filter
          : "all",
    });

  const { data: dataUseAuth } = useAuth();

  const userPermissionCreate =
    dataUseAuth.admin_user_permissions.create_clause_categories;
  const userPermissionRead =
    dataUseAuth.admin_user_permissions.read_clause_categories;
  const userPermissionUpdate =
    dataUseAuth.admin_user_permissions.update_clause_categories;
  const userPermissionDelete =
    dataUseAuth.admin_user_permissions.delete_clause_categories;

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/clause-category");
  }, []);

  const handleCreateClauseCategory = useCallback(() => {
    navigate("/create-clause-category", {
      state: { params: listClauseCategoryDataParams },
    });
  }, [navigate, listClauseCategoryDataParams]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/bits-clause-categories", {
        params: listClauseCategoryDataParams,
      });

      setDataClauseCategoryList(response.data);
      setTotalPages(response.data.pagination);
      setLoading(false);
    })();
  }, [reload, listClauseCategoryDataParams]);

  const handleDeleteClauseCategory = useCallback(
    async (id: string) => {
      await api.delete(`/bits-clause-categories/${id}`);
      Notify({
        text: "Categoria de cláusula deletada com sucesso",
        type: "success",
      });
      setReload(!reload);
    },
    [reload]
  );

  const formSchema = yup.object().shape({
    search: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = useCallback(
    debounce((formData: string) => {
      try {
        setLoading(true);

        if (formData === "") {
          setListClauseCategoryDataParams({
            pagination: 0,
            filter: "all",
          });
        } else {
          setListClauseCategoryDataParams((state) => {
            let cloneState = cloneDeep(state);

            return {
              pagination: 0,
              filter: cloneState.filter,
              search: formData,
            };
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
      }
    }, 400),
    []
  );

  const navigateView = useCallback(
    (item) => {
      navigate(`/view-clause-category/${item.id}`, {
        state: { params: listClauseCategoryDataParams },
      });
    },
    [navigate, listClauseCategoryDataParams]
  );

  const notifyRead = useCallback(() => {
    Notify({ text: "Você não tem permissão de visualizar", type: "error" });
  }, []);

  const handleInputSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value;

      onSubmit(searchTerm);
    },
    [onSubmit]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Lista de categorias de cláusulas" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button
              onClick={handleCreateClauseCategory}
              disabled={!userPermissionCreate}
            >
              Criar categoria de cláusulas
            </Button>
          </ContainerCreate>

          <HStack>
            <Input
              onChangeInput={handleInputSearchChange}
              label="search"
              register={register}
              required={true}
              placeholder="Pesquisar"
              style={{ width: "100%" }}
            />
          </HStack>

          <BoxList>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#663399" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>Categorias de cláusulas</TableTh>
                    <TableTh>Data de criação</TableTh>
                    <TableTh>Última modificação</TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                <TableTbody>
                  {dataClauseCategoryList.dataArray.map((item, index) => (
                    <TableTr key={item.id}>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.title}</TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.updated_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Tooltip title="Editar categoria" placement="top">
                            <ButtonFlexBox
                              disabled={!userPermissionUpdate}
                              onClick={() => {
                                navigate(`/edit-clause-category/${item.id}`, {
                                  state: {
                                    params: listClauseCategoryDataParams,
                                  },
                                });
                              }}
                            >
                              <IconSvg width="18" height="18" image={penIcon} />
                            </ButtonFlexBox>
                          </Tooltip>

                          <Dialog.Root>
                            <Tooltip title="Deletar categoria" placement="top">
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox disabled={!userPermissionDelete}>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={trashIcon}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title="Deletar categoria de cláusulas"
                              type="delete"
                              subtitle={item.title}
                              callback={() =>
                                handleDeleteClauseCategory(item.id)
                              }
                            />
                          </Dialog.Root>
                        </FlexBox>
                      </TableTd>
                    </TableTr>
                  ))}
                </TableTbody>
              </Table>
            )}
          </BoxList>
          <FlexPagination>
            {dataClauseCategoryList.dataArray.length > 0 && (
              <Pagination
                total={totalPages}
                offset={listClauseCategoryDataParams.pagination}
                setDataParams={setListClauseCategoryDataParams}
                dataParams={listClauseCategoryDataParams}
              />
            )}
          </FlexPagination>

          {/* {!userPermission.admin_user_permissions.read_clause_categories && (
            <ContainerReadNotAllowed>
              <TextReadNotAllowed>
                Você não tem permissão de visualizar esse conteúdo
              </TextReadNotAllowed>
            </ContainerReadNotAllowed>
          )} */}
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ListClauseCategory;
