import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const ContainerInputs = styled.div`
  & + & {
    margin-left: 20px;
  }
`;

export const TextTotal = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;
