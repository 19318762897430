import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { format } from "date-fns";
import * as Dialog from "@radix-ui/react-dialog";
import { useAuth } from "../../../../../../hooks/auth";
import { useRoutes } from "../../../../../../hooks/routes";

import IListUsersApiProps from "./dtos/IListUsersApiProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";

import { DateFormatedToFrontendUTC } from "../../../../../../shared/utils";
import DialogModal from "../../../../../../shared/components/DialogModal";

import { Notify } from "../../../../../../shared/utils";

import {
  Container,
  Title,
  Subtitle,
  ContainerLoading,
  Heading,
  AvatarImg,
  ContainerButton,
} from "./styles";

const ViewUsers: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [usersData, setUserData] = useState<IListUsersApiProps>({
    user: {
      id: "",
      email: "",
      is_blocked: false,
      user_type: "",
      created_at: new Date(),
      updated_at: new Date(),
      avatar_url: "",
    },
    people: {
      id: "",
      first_name: "",
      last_name: "",
      born_at: null,
      gender: null,
      document: null,
    },
    addresses: {
      id: "",
      zipcode: null,
      street: null,
      number: null,
      district: null,
      city: null,
      state: null,
      country: null,
    },
    contacts: {
      id: "",
      phone: null,
    },
    plans: {
      id: "",
      plan_permission_id: "",
      creator_id: "",
      last_editor_id: "",
      name: "",
      description: "",
      benefits: "",
      price: 0,
      discount: 0,
      is_blocked: false,
      created_at: new Date(),
      updated_at: new Date(),
    },
    user_plans: {
      id: "",
      user_id: "",
      plan_id: "",
      price_pay: 0,
      is_active: false,
      cancellation_date: new Date(),
      customer_id: "",
      subscription_id: "",
      user_type: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
    reason_for_cancellation: [],
  });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { user_id } = useParams();

  const { data: dataUseAuth } = useAuth();

  const { getUrl } = useRoutes();

  const userPermissionPassword =
    dataUseAuth.admin_user_permissions.update_user_password;
  const userPermissionUpdateSubscription =
    dataUseAuth.admin_user_permissions.update_user_subscription;
  const userPermissionDelete = dataUseAuth.admin_user_permissions.delete_users;
  const userPermissionBlock = dataUseAuth.admin_user_permissions.block_users;
  const userPermissionCancel = dataUseAuth.admin_user_permissions.cancel_users;

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/view-users/:user_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listUserSpecific = await api.get(`/profile/${user_id}`);

      setUserData(listUserSpecific.data);
      setLoading(false);
    })();
  }, [user_id, reload]);

  const handleBlockUsers = useCallback(
    async (id: string) => {
      await api.put(`/users/block-user/${id}`);
      Notify({
        text: "Usuário bloqueado / desbloqueado com sucesso",
        type: "success",
      });
      setReload(!reload);
    },
    [reload]
  );

  const handleDeleteUsers = useCallback(
    async (id: string) => {
      try {
        Notify({
          text: "Aguarde enquanto deletamos esse usuário.",
          type: "info",
        });

        await api.delete(`/users/${id}`);

        Notify({
          text: "Usuário deletado com sucesso.",
          type: "success",
        });

        navigate("/users", {
          state: { params: dataLocation?.params },
        });
      } catch (error: any) {
        Notify({
          text: "Ocorreu algum erro ao excluir esse usuário. Entre em contato com o suporte.",
          type: "error",
        });
      }
    },
    [navigate, dataLocation]
  );

  const handleUpdateUserSubscriptionStatus = useCallback(
    async (id: string) => {
      try {
        await api.put(`/users/update-subscription-status/${id}`);
        setReload(!reload);
        Notify({
          text: "Assinatura do usuário alterada com sucesso",
          type: "success",
        });
      } catch (error: any) {
        console.log(error);
      }
    },
    [reload]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Visualizar usuário" />
      <MasterMain>
        {loading && (
          <ContainerLoading>
            <SyncLoader loading={loading} color="#663399" size={10} />
          </ContainerLoading>
        )}

        {!loading && (
          <Container>
            <AvatarImg src={usersData.user.avatar_url} />

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Title>Nome:</Title>
            <Subtitle>
              {usersData.people.first_name
                ? usersData.people.first_name
                : "null"}
            </Subtitle>

            <Title>Sobrenome:</Title>
            <Subtitle>
              {usersData.people.last_name ? usersData.people.last_name : "null"}
            </Subtitle>

            <Title>Data de Nascimento:</Title>
            <Subtitle>
              {usersData.people.born_at
                ? format(new Date(usersData.people.born_at), "dd/MM/yyyy")
                : "null"}
            </Subtitle>

            <Title>Genero:</Title>
            <Subtitle>
              {usersData.people.gender ? usersData.people.gender : "null"}
            </Subtitle>

            <Title>Documento:</Title>
            <Subtitle>
              {usersData.people.document ? usersData.people.document : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Heading>Endereço</Heading>

            <Title>Zipcode:</Title>
            <Subtitle>
              {usersData.addresses.zipcode
                ? usersData.addresses.zipcode
                : "null"}
            </Subtitle>

            <Title>Rua:</Title>
            <Subtitle>
              {usersData.addresses.street ? usersData.addresses.street : "null"}
            </Subtitle>

            <Title>Numero:</Title>
            <Subtitle>
              {usersData.addresses.number ? usersData.addresses.number : "null"}
            </Subtitle>

            <Title>Bairro:</Title>
            <Subtitle>
              {usersData.addresses.district
                ? usersData.addresses.district
                : "null"}
            </Subtitle>

            <Title>Cidade:</Title>
            <Subtitle>
              {usersData.addresses.city ? usersData.addresses.city : "null"}
            </Subtitle>

            <Title>Estado:</Title>
            <Subtitle>
              {usersData.addresses.state ? usersData.addresses.state : "null"}
            </Subtitle>

            <Title>Pais:</Title>
            <Subtitle>
              {usersData.addresses.country
                ? usersData.addresses.country
                : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Heading>Contato</Heading>

            <Title>Email:</Title>
            <Subtitle>
              {usersData.user.email ? usersData.user.email : "null"}
            </Subtitle>

            <Title>Telefone:</Title>
            <Subtitle>
              {usersData.contacts.phone ? usersData.contacts.phone : "null"}
            </Subtitle>

            {usersData.questions && (
              <>
                <Divider style={{ marginBottom: 20, marginTop: 20 }} />

                <Heading>Perguntas</Heading>

                <Title>Onde você trabalha?</Title>
                <Subtitle>
                  {usersData.questions?.where_do_you_work
                    ? usersData.questions?.where_do_you_work
                    : "null"}
                </Subtitle>

                <Title>Onde nos conheceu?</Title>
                <Subtitle>
                  {usersData.questions?.how_did_you_meet_us
                    ? usersData.questions?.how_did_you_meet_us
                    : "null"}
                </Subtitle>
              </>
            )}

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Heading>Assinatura</Heading>

            <Title>Status</Title>
            <Subtitle>
              {usersData.user_plans.is_active ? "Ativa" : "Cancelada"}
            </Subtitle>

            <Title>Data do fim do período de trial</Title>
            <Subtitle>
              {usersData.user_plans.cancellation_date
                ? DateFormatedToFrontendUTC({
                    date: usersData.user_plans.cancellation_date,
                  })
                : "null"}
            </Subtitle>

            <Title>ID do cliente</Title>
            <Subtitle>
              {usersData.user_plans.customer_id
                ? usersData.user_plans.customer_id
                : "null"}
            </Subtitle>

            <Title>ID da assinatura</Title>
            <Subtitle>
              {usersData.user_plans.subscription_id
                ? usersData.user_plans.subscription_id
                : "null"}
            </Subtitle>

            <Title>Tipo de usuário</Title>

            {!usersData.user_plans.user_type && <Subtitle>null</Subtitle>}
            {usersData.user_plans.user_type === "ld_pass" && (
              <Subtitle>Legal design pass</Subtitle>
            )}
            {usersData.user_plans.user_type === "user_free" && (
              <Subtitle>Usuário padrão</Subtitle>
            )}
            {usersData.user_plans.user_type === "cortesia" && (
              <Subtitle>Cortesia</Subtitle>
            )}
            {usersData.user_plans.user_type === "b2b" && (
              <Subtitle>B2B</Subtitle>
            )}
            {usersData.user_plans.user_type === "pre_venda" && (
              <Subtitle>Pré venda</Subtitle>
            )}

            <Title>Usuário bloqueado?</Title>
            <Subtitle>{usersData.user.is_blocked ? "Sim" : "Não"}</Subtitle>

            {usersData.reason_for_cancellation.length > 0 && (
              <>
                <Divider style={{ marginBottom: 20, marginTop: 20 }} />

                <Heading>Perguntas de desistência</Heading>

                {usersData.reason_for_cancellation.map((item, index) => (
                  <>
                    <Title style={{ color: "#663399" }}>
                      Resposta {index + 1}
                    </Title>

                    <Title>Por que você não quer continuar?</Title>
                    <Subtitle>{item.reason}</Subtitle>

                    <Title>Comentário</Title>
                    {item.comment ? (
                      <Subtitle>{item.comment}</Subtitle>
                    ) : (
                      <Subtitle>null</Subtitle>
                    )}

                    <Title>Qual tela?</Title>
                    {item.type === "end_trial" && (
                      <Subtitle>Fim do trial</Subtitle>
                    )}
                    {item.type === "unsubscription" && (
                      <Subtitle>Assinatura cancelada</Subtitle>
                    )}

                    {usersData.reason_for_cancellation.length > 1 && (
                      <>
                        {usersData.reason_for_cancellation.length !==
                          index + 1 && (
                          <Divider style={{ marginBottom: 15, width: 280 }} />
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            )}

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <ContainerButton>
              <Button
                style={{ width: 100 }}
                onClick={() =>
                  navigate("/users", {
                    state: { params: dataLocation?.params },
                  })
                }
              >
                Voltar
              </Button>

              <Button
                style={{ width: 170, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/update-user-subscription/${user_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionUpdateSubscription}
              >
                Editar assinatura
              </Button>

              <Button
                style={{ width: 145, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/reset-password-user/${user_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionPassword}
              >
                Alterar senha
              </Button>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 100, marginLeft: 20 }}
                    disabled={!userPermissionDelete}
                  >
                    Excluir
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title="Deletar usuário"
                  type="delete"
                  isTip={true}
                  subtitle={`${usersData.people.first_name} ${usersData.people.last_name}`}
                  callback={() => handleDeleteUsers(usersData.user.id)}
                />
              </Dialog.Root>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 200, marginLeft: 20 }}
                    disabled={!userPermissionCancel}
                  >
                    {`${
                      usersData.user_plans.is_active ? "Desativar" : "Ativar"
                    } assinatura`}
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title={`${
                    usersData.user_plans.is_active ? "Desativar" : "Ativar"
                  } assinatura`}
                  type="subscription"
                  isBlocked={usersData.user_plans.is_active}
                  subtitle={`${usersData.people.first_name} ${usersData.people.last_name}`}
                  callback={() =>
                    handleUpdateUserSubscriptionStatus(usersData.user.id)
                  }
                />
              </Dialog.Root>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 200, marginLeft: 20 }}
                    disabled={!userPermissionBlock}
                  >
                    {`${
                      usersData.user.is_blocked ? "Desbloquear" : "Bloquear"
                    } usuário`}
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title={`${
                    usersData.user.is_blocked ? "Desbloquear" : "Bloquear"
                  } usuário`}
                  type="block"
                  isBlocked={usersData.user.is_blocked}
                  subtitle={`${usersData.people.first_name} ${usersData.people.last_name}`}
                  callback={() => handleBlockUsers(usersData.user.id)}
                />
              </Dialog.Root>
            </ContainerButton>
          </Container>
        )}
      </MasterMain>
    </MasterContainer>
  );
};

export default ViewUsers;
