import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormResetPassword from "./dtos/IFormResetPassword";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const ResetPasswordUserAdmin: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { user_id } = useParams();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/reset-password-user-admin/:user_id");
  }, []);

  const formSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(
        /[*@!#%$&()^~?:<>{}]+/,
        "A senha deve conter pelo menos um símbolo"
      )
      .required(`Senha necessária`),
    confirmPassword: yup
      .string()
      .required("confirmação da senha é um campo obrigatório")
      .min(8, "Deve conter 8 caracteres")
      .oneOf([yup.ref("newPassword"), null], "Senhas devem ser iguais"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormResetPassword> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const resetPassword = await api.put("/users/reset-password", {
          user_id: user_id,
          new_password: formData.newPassword,
        });

        if (resetPassword.status === 200) {
          Notify({ text: "Senha atualizada com sucesso", type: "success" });
          setLoading(false);
          navigate("/users-admin", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [navigate, user_id, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Atualizar senha" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nova senha *</LabelInput>
              <Input
                label="newPassword"
                register={register}
                required={true}
                placeholder="Digite a nova senha"
              />
              {errors.newPassword && (
                <ErrorInput>{errors.newPassword.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Confirme a senha *</LabelInput>
              <Input
                label="confirmPassword"
                register={register}
                required={true}
                placeholder="Digite a confirmação da senha"
              />
              {errors.confirmPassword && (
                <ErrorInput>{errors.confirmPassword.message}</ErrorInput>
              )}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/users-admin", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Editar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ResetPasswordUserAdmin;
