import React, { useEffect } from "react";
import { useRoutes } from "../../../../../hooks/routes";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import TotalAccountCreated from "./components/TotalAccountCreated";
import TotalActiveUsers from "./components/TotalActiveUsers";

import { Container, ContainerGraphics } from "./styles";

const UserGraphics: React.FC = () => {
  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/user-graphics");
  }, []);

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Relatórios de usuários" />
      <MasterMain>
        <Container>
          <ContainerGraphics>
            <TotalAccountCreated />
          </ContainerGraphics>

          <ContainerGraphics>
            <TotalActiveUsers />
          </ContainerGraphics>
        </Container>
      </MasterMain>
    </MasterContainer>
  );
};

export default UserGraphics;
