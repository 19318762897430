import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FileSaver from 'file-saver'
import api from "../../../../../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import { debounce } from "lodash";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IListUsersApiProps from "./dtos/IListUsersApiProps";
import IListUsersDataParams from "./dtos/IListUsersDataParams";
import IContainerMoreOptions from "./dtos/IContainerMoreOptions";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../../../shared/components/DialogModal";
import Button from "../../../../../../shared/components/Button";
import Input from "../../../../../../shared/components/Input";
import IconSvg from "../../../../../../shared/components/IconSvg";
import Pagination from "../../../../../../shared/components/Pagination";
import Tooltip from "../../../../../../shared/components/Tooltip";

import penIcon from "../../../../../../shared/assets/pen-icon.svg";
import iconUpdateSubscription from "../../../../../../shared/assets/update_subscription.svg";
import iconPasswordPassword from "../../../../../../shared/assets/reset-password.svg";
import blockIcon from "../../../../../../shared/assets/block-icon.svg";
import unblockIcon from "../../../../../../shared/assets/unblock-icon.svg";
import trashIcon from "../../../../../../shared/assets/trash-icon.svg";
import moreOptionsIcon from "../../../../../../shared/assets/more-options.svg";
import filtersIcon from "../../../../../../shared/assets/filter_alt.svg";
import filtersIconWhite from "../../../../../../shared/assets/filter_alt_white.svg";

import {
  Notify,
  DateFormatedToFrontendUTC,
} from "../../../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  FlexBox,
  HStack,
  Main,
  ContainerCreate,
  FlexPagination,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
  ContainerMoreOptions,
  ItensMoreOptions,
  TextMoreOptions,
} from "./styles";
import { toast } from "react-toastify";

const ListUsers: React.FC = () => {
  const location = useLocation();
  const dataLocation: any = location.state;
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [heightMoreOptions, setHeightMoreOptions] = useState(false);
  const [heightMoreOptionsTable, setHeightMoreOptionsTable] = useState<
    number | undefined
  >();
  const [openContainerMoreOptions, setOpenContainerMoreOptions] =
    useState<IContainerMoreOptions>({
      open: false,
      user_id: "",
    });
  const [dataUsersList, setDataUsersList] = useState<IListUsersApiProps>({
    pagination: 0,
    dataArray: [],
  });
  const [listUsersDataParams, setListUsersDataParams] =
    useState<IListUsersDataParams>({
      pagination:
        dataLocation && dataLocation.params != null
          ? dataLocation.params.pagination
          : 0,
    });

  const { data: dataUseAuth } = useAuth();

  const { getUrl } = useRoutes();

  const userPermissionCreate = dataUseAuth.admin_user_permissions.create_users;
  const userPermissionRead = dataUseAuth.admin_user_permissions.read_users;
  const userPermissionPassword =
    dataUseAuth.admin_user_permissions.update_user_password;
  const userPermissionUpdateSubscription =
    dataUseAuth.admin_user_permissions.update_user_subscription;
  const userPermissionDelete = dataUseAuth.admin_user_permissions.delete_users;
  const userPermissionBlock = dataUseAuth.admin_user_permissions.block_users;
  const userPermissionCancel = dataUseAuth.admin_user_permissions.cancel_users;
  const userPermissionDownloadCSV = dataUseAuth.admin_user_permissions.download_users_admin;

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/users");
  }, []);

  const handleCreateUsers = useCallback(() => {
    navigate("/create-user", {
      state: { params: listUsersDataParams },
    });
  }, [navigate, listUsersDataParams]);

  const exportCSV = async () => {
    const response = await api.get("/user-admin-download")
    if (response) {
      toast.success('Arquivo gerado com sucesso!')
      FileSaver.saveAs(response.data.url, 'relatorio_usuarios.csv')
      return;
    }
    return toast.error('Não foi possível gerar o arquivo')
  }

  useEffect(() => {
    (async () => {
      const response = await api.get("/users", {
        params: {
          pagination: listUsersDataParams.pagination,
          filter: listUsersDataParams.filter,
          search: listUsersDataParams.search,
        },
      });

      if (response.data.dataArray.length < 5) {
        setHeightMoreOptionsTable(213);
      } else {
        setHeightMoreOptionsTable(undefined);
      }
      setDataUsersList(response.data);
      setTotalPages(response.data.pagination);
      setLoading(false);
    })();
  }, [reload, listUsersDataParams]);

  const handleBlockUsers = useCallback(
    async (id: string) => {
      await api.put(`/users/block-user/${id}`);
      setReload(!reload);
    },
    [reload]
  );

  const handleDeleteUsers = useCallback(
    async (id: string) => {
      try {
        Notify({
          text: "Aguarde enquanto deletamos esse usuário.",
          type: "info",
        });

        await api.delete(`/users/${id}`);
        setReload(!reload);

        Notify({
          text: "Usuário deletado com sucesso.",
          type: "success",
        });
      } catch (error: any) {
        Notify({
          text: "Ocorreu algum erro ao excluir esse usuário. Entre em contato com o suporte.",
          type: "error",
        });
      }
    },
    [reload]
  );

  const handleUpdateUserSubscriptionStatus = useCallback(
    async (id: string) => {
      try {
        await api.put(`/users/update-subscription-status/${id}`);
        setReload(!reload);
      } catch (error: any) {
        console.log(error);
      }
    },
    [reload]
  );

  const formSchema = yup.object().shape({
    search: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = useCallback(
    debounce((formData: string) => {
      try {
        setLoading(true);
        if (formData === "") {
          setListUsersDataParams((prevState) => ({
            pagination: 0,
            filter: prevState.filter
          }))
        } else {
          setListUsersDataParams((prevState) => ({
            ...prevState,
            pagination: 0,
            search: formData,
          }))
        }
      } catch (err: any) {
        console.log(err?.response?.data);
      }
    }, 400),
    []
  );

  const navigateView = useCallback(
    (item) => {
      navigate(`/view-users/${item.id}`, {
        state: { params: listUsersDataParams },
      });
    },
    [navigate, listUsersDataParams]
  );

  const notifyRead = useCallback(() => {
    Notify({ text: "Você não tem permissão de visualizar", type: "error" });
  }, []);

  const handleOpenMoreOptions = useCallback(
    (user_id: string) => {
      if (
        openContainerMoreOptions.user_id === "" &&
        !openContainerMoreOptions.open
      ) {
        setOpenContainerMoreOptions({
          open: true,
          user_id: user_id,
        });
      } else if (
        openContainerMoreOptions.user_id === user_id &&
        openContainerMoreOptions.open
      ) {
        setOpenContainerMoreOptions({
          open: false,
          user_id: "",
        });
      } else if (
        openContainerMoreOptions.user_id !== user_id &&
        openContainerMoreOptions.open
      ) {
        setOpenContainerMoreOptions({
          open: false,
          user_id: "",
        });
      }
    },
    [openContainerMoreOptions]
  );

  const handleInputSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value;

      onSubmit(searchTerm);
    },
    [onSubmit]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Listar usuários" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button
              onClick={handleCreateUsers}
              disabled={!userPermissionCreate}
            >
              Criar usuário
            </Button>
            <Button
              color="red"
              onClick={exportCSV}
              disabled={!userPermissionDownloadCSV}
            >
              Exportar usuários via CSV
            </Button>
          </ContainerCreate>

          <HStack>
            <Input
              onChangeInput={handleInputSearchChange}
              label="search"
              register={register}
              required={true}
              placeholder="Pesquisar"
              style={{ width: "100%" }}
            />
          </HStack>

          <BoxList height={heightMoreOptionsTable}>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#663399" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>
                      Nome
                      <IconSvg
                        width="26"
                        height="26"
                        image={(listUsersDataParams.filter === 'name' || !listUsersDataParams.filter) ? filtersIconWhite : filtersIcon}
                        onClick={() => setListUsersDataParams((prevState) => ({
                          ...prevState,
                          filter: 'name'
                        }))}
                      />
                    </TableTh>
                    <TableTh>
                      Sobrenome
                      <IconSvg
                        width="26"
                        height="26"
                        image={listUsersDataParams.filter === 'last-name' ? filtersIconWhite : filtersIcon}
                        onClick={() => setListUsersDataParams((prevState) => ({
                          ...prevState,
                          filter: 'last-name'
                        }))}
                      />
                    </TableTh>
                    <TableTh>
                      E-mail
                      <IconSvg
                        width="26"
                        height="26"
                        image={listUsersDataParams.filter === 'email' ? filtersIconWhite : filtersIcon}
                        onClick={() => setListUsersDataParams((prevState) => ({
                          ...prevState,
                          filter: 'email'
                        }))}
                      />
                    </TableTh>
                    <TableTh>
                      Tipo do usuário
                      <IconSvg
                        width="26"
                        height="26"
                        image={listUsersDataParams.filter === 'type' ? filtersIconWhite : filtersIcon}
                        onClick={() => setListUsersDataParams((prevState) => ({
                          ...prevState,
                          filter: 'type'
                        }))}
                      />
                    </TableTh>
                    {/* <TableTh>Plano</TableTh> */}
                    <TableTh>Data de vencimento</TableTh>
                    <TableTh>Status</TableTh>
                    <TableTh>Assinatura</TableTh>
                    <TableTh>
                      Criado em
                      <IconSvg
                        width="26"
                        height="26"
                        image={listUsersDataParams.filter === 'createdAt' ? filtersIconWhite : filtersIcon}
                        onClick={() => setListUsersDataParams((prevState) => ({
                          ...prevState,
                          filter: 'createdAt'
                        }))}
                      />
                    </TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                <TableTbody>
                  {dataUsersList.dataArray.map((item, index) => (
                    <TableTr key={`${item.id}${index}`}>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.first_name}</TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.last_name}</TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.email}</TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        {item.user_type === "ld_pass" && (
                          <TableTdText>Legal design pass</TableTdText>
                        )}
                        {item.user_type === "user_free" && (
                          <TableTdText>Usuário padrão</TableTdText>
                        )}
                        {item.user_type === "cortesia" && (
                          <TableTdText>Cortesia</TableTdText>
                        )}
                        {item.user_type === "b2b" && (
                          <TableTdText>B2B</TableTdText>
                        )}
                        {item.user_type === "pre_venda" && (
                          <TableTdText>Pré venda</TableTdText>
                        )}
                      </TableTd>
                      {/* <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>{item.name_plan}</TableTdText>
                      </TableTd> */}
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {item.cancellation_date
                            ? DateFormatedToFrontendUTC({
                              date: item.cancellation_date,
                            })
                            : "Sem vencimento"}
                        </TableTdText>
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        {item.status_cancellation_date === "no-date" && (
                          <TableTdText
                            style={{
                              color: "gray",
                              backgroundColor: "#E1E1E1",
                              textAlign: "center",
                            }}
                          >
                            Sem data
                          </TableTdText>
                        )}
                        {item.status_cancellation_date === "date-ok" && (
                          <TableTdText
                            style={{
                              color: "green",
                              backgroundColor: "#DFFFE2",
                              textAlign: "center",
                            }}
                          >
                            Em dia
                          </TableTdText>
                        )}
                        {item.status_cancellation_date === "date-expired" && (
                          <TableTdText
                            style={{
                              color: "red",
                              backgroundColor: "#FFDFDF",
                              textAlign: "center",
                            }}
                          >
                            Data expirada
                          </TableTdText>
                        )}
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        {item.subscription_is_active && (
                          <TableTdText
                            style={{
                              color: "green",
                              backgroundColor: "#DFFFE2",
                              textAlign: "center",
                            }}
                          >
                            Ativa
                          </TableTdText>
                        )}
                        {!item.subscription_is_active && (
                          <TableTdText
                            style={{
                              color: "red",
                              backgroundColor: "#FFDFDF",
                              textAlign: "center",
                            }}
                          >
                            Cancelada
                          </TableTdText>
                        )}
                      </TableTd>
                      <TableTd
                        onClick={() =>
                          userPermissionRead ? navigateView(item) : notifyRead()
                        }
                      >
                        <TableTdText>
                          {format(
                            new Date(item.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Dialog.Root>
                            <Tooltip
                              title={`${item.subscription_is_active
                                ? "Desativar"
                                : "Ativar"
                                } assinatura`}
                              placement="top"
                            >
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox disabled={!userPermissionCancel}>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={iconUpdateSubscription}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title={`${item.subscription_is_active
                                ? "Desativar"
                                : "Ativar"
                                } assinatura`}
                              subtitle={`${item.first_name} ${item.last_name}`}
                              type="subscription"
                              isBlocked={item.subscription_is_active}
                              callback={() =>
                                handleUpdateUserSubscriptionStatus(item.id)
                              }
                            />
                          </Dialog.Root>

                          <Dialog.Root>
                            <Tooltip
                              title={`${item.is_blocked ? "Desbloquear" : "Bloquear"
                                } usuário`}
                              placement="top"
                            >
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox disabled={!userPermissionBlock}>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={
                                      item.is_blocked ? blockIcon : unblockIcon
                                    }
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title={`${item.is_blocked ? "Desbloquear" : "Bloquear"
                                } usuário`}
                              subtitle={`${item.first_name} ${item.last_name}`}
                              type="block"
                              isBlocked={item.is_blocked}
                              callback={() => handleBlockUsers(item.id)}
                            />
                          </Dialog.Root>

                          <Tooltip title="Mais opções" placement="top">
                            <ButtonFlexBox
                              onClick={() => {
                                handleOpenMoreOptions(item.id);

                                if (dataUsersList.dataArray.length > 2) {
                                  if (index >= 2) {
                                    setHeightMoreOptions(true);
                                  } else {
                                    setHeightMoreOptions(false);
                                  }
                                }
                              }}
                            >
                              <IconSvg
                                width="18"
                                height="18"
                                image={moreOptionsIcon}
                              />
                            </ButtonFlexBox>
                          </Tooltip>
                        </FlexBox>

                        {openContainerMoreOptions.open &&
                          openContainerMoreOptions.user_id === item.id && (
                            <ContainerMoreOptions
                              isHeight={heightMoreOptions}
                              onMouseLeave={() => {
                                setOpenContainerMoreOptions({
                                  open: false,
                                  user_id: "",
                                });
                                setHeightMoreOptions(false);
                              }}
                            >
                              <ItensMoreOptions>
                                <ButtonFlexBox
                                  width="100%"
                                  disabled={!userPermissionUpdateSubscription}
                                  onClick={() => {
                                    navigate(
                                      `/update-user-subscription/${item.id}`,
                                      {
                                        state: { params: listUsersDataParams },
                                      }
                                    );
                                  }}
                                >
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={penIcon}
                                  />
                                  <TextMoreOptions>
                                    Editar assinatura
                                  </TextMoreOptions>
                                </ButtonFlexBox>
                              </ItensMoreOptions>

                              <ItensMoreOptions>
                                <ButtonFlexBox
                                  width="100%"
                                  disabled={!userPermissionPassword}
                                  onClick={() => {
                                    navigate(
                                      `/reset-password-user/${item.id}`,
                                      {
                                        state: { params: listUsersDataParams },
                                      }
                                    );
                                  }}
                                >
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={iconPasswordPassword}
                                  />
                                  <TextMoreOptions>
                                    Alterar senha
                                  </TextMoreOptions>
                                </ButtonFlexBox>
                              </ItensMoreOptions>

                              <ItensMoreOptions>
                                <Dialog.Root>
                                  <Dialog.Trigger asChild>
                                    <ButtonFlexBox
                                      width="100%"
                                      disabled={!userPermissionDelete}
                                    >
                                      <IconSvg
                                        width="18"
                                        height="18"
                                        image={trashIcon}
                                      />
                                      <TextMoreOptions>
                                        Deletar usuário
                                      </TextMoreOptions>
                                    </ButtonFlexBox>
                                  </Dialog.Trigger>

                                  <DialogModal
                                    title="Deletar usuário"
                                    subtitle={`${item.first_name} ${item.last_name}`}
                                    type="delete"
                                    isTip={true}
                                    callback={() => handleDeleteUsers(item.id)}
                                  />
                                </Dialog.Root>
                              </ItensMoreOptions>
                            </ContainerMoreOptions>
                          )}
                      </TableTd>
                    </TableTr>
                  ))}
                </TableTbody>
              </Table>
            )}
          </BoxList>
          <FlexPagination>
            {dataUsersList.dataArray.length > 0 && (
              <Pagination
                total={totalPages}
                offset={listUsersDataParams.pagination}
                setDataParams={setListUsersDataParams}
                dataParams={listUsersDataParams}
              />
            )}
          </FlexPagination>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ListUsers;
