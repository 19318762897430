import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ContainerGraphics = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 300px;
  height: auto;
  padding: 0 10px;
`;
