import React, { InputHTMLAttributes } from "react";
import InputMask from "react-input-mask";
import {
  Path,
  UseFormRegister,
  Controller,
  Control,
  FieldValues,
} from "react-hook-form";

import { Container, InputText } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  control: Control<FieldValues, any>;
  placeholder: string;
  mask: string;
}

const Input: React.FC<InputProps> = ({
  label,
  register,
  required,
  control,
  placeholder,
  mask,
  ...rest
}) => {
  return (
    <Container>
      <Controller
        name={label}
        control={control}
        defaultValue=""
        rules={{
          required: required,
        }}
        render={({ field }) => (
          <InputMask
            mask={mask}
            maskChar=""
            value={field.value}
            onChange={field.onChange}
          >
            {(inputProps: any) => (
              <InputText
                {...inputProps}
                type="text"
                placeholder={placeholder}
              />
            )}
          </InputMask>
        )}
      />
    </Container>
  );
};

export default Input;
