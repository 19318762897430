import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../hooks/routes";

import IHookForm from "../../../../../shared/dtos/IHookForm";
import IFormEditTextReplacement from "./dtos/IFormEditTextReplacement";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../shared/components/Button";
import ButtonCancel from "../../../../../shared/components/ButtonCancel";
import Input from "../../../../../shared/components/Input";
import ChipInput from "../../../../../shared/components/ChipInput";
import Alert from "../../../../../shared/components/Alert";
import TooltipInformation from "../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const EditTextReplacement: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const { text_replacement_id } = useParams();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/edit-text-replacement/:text_replacement_id");
  }, []);

  const formSchema = yup.object().shape({
    word: yup.string().required("palavra principal é um campo obrigatório"),
    replacement: yup
      .array()
      .of(yup.string())
      .required("palavra de substituição é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const listTextReplacement = await api.get(
        `/text-replacement/${text_replacement_id}`
      );

      let newReplacement: Array<string> = [];

      if (listTextReplacement.data.replacement) {
        newReplacement = listTextReplacement.data.replacement.split(",");
      }

      reset({
        word: listTextReplacement.data.word,
        replacement: newReplacement,
      });
    })();
  }, [text_replacement_id, reset]);

  const onSubmit: SubmitHandler<IFormEditTextReplacement> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        let newReplacement = "";

        formData.replacement.forEach((item, index) => {
          if (index < formData.replacement.length - 1) {
            if (item.trim().length !== 0) {
              newReplacement += `${item.toLowerCase().trim()},`;
            }
          } else {
            if (item.trim().length !== 0) {
              newReplacement += `${item.toLowerCase().trim()}`;
            } else {
              newReplacement = newReplacement.substring(
                0,
                newReplacement.length - 1
              );
            }
          }
        });

        const updateTextReplacement = await api.put("/text-replacement", {
          text_replacement_id: text_replacement_id,
          word: formData.word,
          replacement: newReplacement,
        });

        if (updateTextReplacement.status === 200) {
          Notify({
            text: "Palavras de substituição atualizada com sucesso",
            type: "success",
          });
          setLoading(false);
          navigate("/text-replacement", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [text_replacement_id, navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Editar palavra de substituição" />
      <MasterMain>
        <Main>
          <Alert
            message="Aqui você alimenta a biblioteca de texto do UX Doc. 
          Quando você atualizar as palavras aqui na dashboard automaticamente
          estará atualizando no One Click."
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Palavra principal *</LabelInput>

              <TooltipInformation
                title="A palavra pela qual o algoritmo irá buscar no texto para 
                substituir"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="word"
                register={register}
                required={true}
                placeholder="Digite a palavra ou frase"
              />
              {errors.word && <ErrorInput>{errors.word.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Palavras de substituição *</LabelInput>

              <TooltipInformation
                title="A palavra pela qual o algoritmo irá substituir no texto
                quando encontrar a palavra principal. Adicione varias palavras 
                que podem ser substituidas. Adicione tanto no feminino quanto no
                masculino. Adicione tanto no plural quanto não plural. Aperte a
                tecla enter para adicionar a palavra"
                placement="top"
                maxWidth={300}
              />

              <ChipInput
                control={control}
                label="replacement"
                required={true}
                defaultValue={[]}
                placeholder="Palavra de substituição"
              />
              {errors.replacement && (
                <ErrorInput>{errors.replacement.message}</ErrorInput>
              )}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/text-replacement", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default EditTextReplacement;
