import styled from "styled-components";

interface IImage {
  src: string;
}

export const Container = styled.div`
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  display: flex;
`;

export const ContainerMain = styled.div`
  width: 100%;
`;

export const ContainerUploadImage = styled.div`
  width: 250px;
  height: 150px;
  border: 1px dashed;
  border-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  margin-top: 5px;
  margin-bottom: 20px;
`;

export const Image = styled.div<IImage>`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  background: url(${({ src }) => src}) no-repeat;
  background-size: contain;
  background-position: center;
`;

export const ContainerImageButton = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonUpdate = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.purple};
  padding: 5px 15px 5px 15px;
  border-radius: 7px;
`;

export const ButtonDelete = styled.button`
  color: ${({ theme }) => theme.colors.purple};
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px 15px 5px 15px;
  border-radius: 7px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.purple};
  margin-left: 5px;
`;
