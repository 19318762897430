import styled from "styled-components";

export const Container = styled.button<{ color?: string }>`
  padding: 10px 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme, color }) => color ? theme.colors.green : theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  transition: all 150ms linear;

  &:hover {
    background: ${({ theme, color }) => color ? theme.colors.greenDark : theme.colors.purpleDark};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.gray};
    cursor: not-allowed;
  }
`;
