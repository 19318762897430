import React, { InputHTMLAttributes } from "react";
import { Path, UseFormRegister } from "react-hook-form";

import { Container, InputText } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  onChangeInput?: any;
}

const Input: React.FC<InputProps> = ({
  label,
  register,
  required,
  onChangeInput,
  ...rest
}) => {
  return (
    <Container>
      <InputText
        {...register(label, { required })}
        {...rest}
        onChange={onChangeInput}
      />
    </Container>
  );
};

export default Input;
