import React, { InputHTMLAttributes } from "react";
import { Path, Controller, Control, FieldValues } from "react-hook-form";
import Select from "react-select";

import { Container } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: Path<IFormValues>;
  options: Array<string>;
  control: Control<FieldValues, any>;
}

const InputMultSelect: React.FC<InputProps> = ({
  label,
  options,
  control,
  ...rest
}) => {
  const newOptions = options.map((value) => {
    const [label, valueSelect] = value.split("||");

    return {
      label: label,
      value: value,
    };
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "#333333",
      padding: 10,
    }),
  };

  return (
    <Container>
      <Controller
        control={control}
        name={label}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            ref={ref}
            placeholder="Selecione..."
            styles={customStyles}
            value={newOptions.filter((option) => value?.includes(option.value))}
            defaultValue={newOptions.filter((option) =>
              value?.includes(option.value)
            )}
            onChange={(newOptions) =>
              onChange(newOptions?.map((option) => option.value))
            }
            options={newOptions}
            isMulti
          />
        )}
      />
    </Container>
  );
};

export default InputMultSelect;
