import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { useAuth } from "../../../../../hooks/auth";
import { useRoutes } from "../../../../../hooks/routes";

import IListRemoteAccessApiProps from "./dtos/IListRemoteAccessApiProps";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../../shared/components/DialogModal";
import Button from "../../../../../shared/components/Button";
import IconSvg from "../../../../../shared/components/IconSvg";
import Tooltip from "../../../../../shared/components/Tooltip";

import trashIcon from "../../../../../shared/assets/trash-icon.svg";

import { Notify } from "../../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  FlexBox,
  Main,
  ContainerCreate,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
} from "./styles";

const ListRemoteAccess: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [emptyList, setEmptyList] = useState(false);
  const [dataRemoteAccessList, setDataRemoteAccessList] =
    useState<IListRemoteAccessApiProps>({
      user: {
        id: "",
        people_id: "",
        email: "",
        avatar: "",
        is_blocked: false,
        user_type: "",
        created_at: new Date(),
        updated_at: new Date(),
        avatar_url: "",
      },
      people: {
        id: "",
        address_id: "",
        contact_id: "",
        first_name: "",
        last_name: "",
        born_at: new Date(),
        gender: "",
        cpf: "",
        is_individual: false,
        is_finished: false,
        created_at: new Date(),
        updated_at: new Date(),
      },
    });

  const { data: dataUseAuth } = useAuth();

  const userPermissionCreate =
    dataUseAuth.admin_user_permissions.create_remote_access_user;
  const userPermissionDelete =
    dataUseAuth.admin_user_permissions.delete_remote_access_user;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/remote-access");
  }, []);

  const handleCreateRemoteAccess = useCallback(() => {
    navigate("/create-remote-access");
  }, [navigate]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/remote-access-user");

        setDataRemoteAccessList(response.data);
        setLoading(false);
        setEmptyList(true);
      } catch (error: any) {
        setEmptyList(false);
        setLoading(false);
      }
    })();
  }, [reload]);

  const handleDeleteRemoteAccess = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/remote-access-user/${id}`);
        Notify({ text: "Acesso remoto deletado com sucesso", type: "success" });
        setReload(!reload);
      } catch (error: any) {
        console.log(error.response);
      }
    },
    [reload]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Listar acesso remoto" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button
              onClick={handleCreateRemoteAccess}
              disabled={!userPermissionCreate}
            >
              Criar acesso remoto
            </Button>
          </ContainerCreate>

          <BoxList>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#663399" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>Nome</TableTh>
                    <TableTh>Sobrenome</TableTh>
                    <TableTh>E-mail</TableTh>
                    <TableTh>Documento</TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                {emptyList && (
                  <TableTbody>
                    <TableTr>
                      <TableTd>
                        <TableTdText>
                          {dataRemoteAccessList.people.first_name}
                        </TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>
                          {dataRemoteAccessList.people.last_name}
                        </TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>
                          {dataRemoteAccessList.user.email}
                        </TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>
                          {dataRemoteAccessList.people.cpf}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Dialog.Root>
                            <Tooltip
                              title={"Deletar acesso remoto"}
                              placement="top"
                            >
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox disabled={!userPermissionDelete}>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={trashIcon}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title={"Deletar acesso remoto"}
                              subtitle={`${dataRemoteAccessList.people.first_name} ${dataRemoteAccessList.people.last_name}`}
                              type="delete"
                              callback={() =>
                                handleDeleteRemoteAccess(
                                  dataRemoteAccessList.user.id
                                )
                              }
                            />
                          </Dialog.Root>
                        </FlexBox>
                      </TableTd>
                    </TableTr>
                  </TableTbody>
                )}
              </Table>
            )}
          </BoxList>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ListRemoteAccess;
