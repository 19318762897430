import styled from "styled-components";

interface IContainerProps {
  width: number;
}

export const Container = styled.div<IContainerProps>`
  display: inline-block;
  margin-right: 20px;
  width: ${({ width }) => width}px;
`;

export const Label = styled.label`
  margin-left: -10px;
  width: 100%;

  .MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-top: -3px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
`;
