import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface RoutesProviderProps {
  children: ReactNode;
}

interface RoutesDataProps {
  getUrl: (url: string) => void;
  isExpandedClauseRepository: boolean;
  setIsExpandedClauseRepository: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedClause: boolean;
  setIsExpandedClause: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedClauseCategory: boolean;
  setIsExpandedClauseCategory: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedIcon: boolean;
  setIsExpandedIcon: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedIconRepository: boolean;
  setIsExpandedIconRepository: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedIconCategory: boolean;
  setIsExpandedIconCategory: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedImage: boolean;
  setIsExpandedImage: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedImageRepository: boolean;
  setIsExpandedImageRepository: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedImageCategory: boolean;
  setIsExpandedImageCategory: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedShape: boolean;
  setIsExpandedShape: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedShapeRepository: boolean;
  setIsExpandedShapeRepository: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedShapeCategory: boolean;
  setIsExpandedShapeCategory: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedGraphic: boolean;
  setIsExpandedGraphic: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedGraphicUser: boolean;
  setIsExpandedGraphicUser: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedEmail: boolean;
  setIsExpandedEmail: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedSendEmail: boolean;
  setIsExpandedSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedTemplate: boolean;
  setIsExpandedTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedTemplateRepository: boolean;
  setIsExpandedTemplateRepository: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isExpandedTemplateCategory: boolean;
  setIsExpandedTemplateCategory: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedUser: boolean;
  setIsExpandedUser: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedCommonUser: boolean;
  setIsExpandedCommonUser: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedUserAdmin: boolean;
  setIsExpandedUserAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedUserAdminEditor: boolean;
  setIsExpandedUserAdminEditor: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveHome: boolean;
  setIsActiveHome: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveTextReplacement: boolean;
  setIsActiveTextReplacement: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveRemoteAccess: boolean;
  setIsActiveRemoteAccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const RoutesContext = createContext<RoutesDataProps>({} as RoutesDataProps);

const RoutesHookProvider: React.FC<RoutesProviderProps> = ({ children }) => {
  const [isActiveHome, setIsActiveHome] = useState(false);
  const [isActiveTextReplacement, setIsActiveTextReplacement] = useState(false);
  const [isActiveRemoteAccess, setIsActiveRemoteAccess] = useState(false);

  const [isExpandedClause, setIsExpandedClause] = useState(false);
  const [isExpandedClauseRepository, setIsExpandedClauseRepository] =
    useState(false);
  const [isExpandedClauseCategory, setIsExpandedClauseCategory] =
    useState(false);

  const [isExpandedIcon, setIsExpandedIcon] = useState(false);
  const [isExpandedIconRepository, setIsExpandedIconRepository] =
    useState(false);
  const [isExpandedIconCategory, setIsExpandedIconCategory] = useState(false);

  const [isExpandedImage, setIsExpandedImage] = useState(false);
  const [isExpandedImageRepository, setIsExpandedImageRepository] =
    useState(false);
  const [isExpandedImageCategory, setIsExpandedImageCategory] = useState(false);

  const [isExpandedShape, setIsExpandedShape] = useState(false);
  const [isExpandedShapeRepository, setIsExpandedShapeRepository] =
    useState(false);
  const [isExpandedShapeCategory, setIsExpandedShapeCategory] = useState(false);

  const [isExpandedGraphic, setIsExpandedGraphic] = useState(false);
  const [isExpandedGraphicUser, setIsExpandedGraphicUser] = useState(false);

  const [isExpandedEmail, setIsExpandedEmail] = useState(false);
  const [isExpandedSendEmail, setIsExpandedSendEmail] = useState(false);

  const [isExpandedTemplate, setIsExpandedTemplate] = useState(false);
  const [isExpandedTemplateRepository, setIsExpandedTemplateRepository] =
    useState(false);
  const [isExpandedTemplateCategory, setIsExpandedTemplateCategory] =
    useState(false);

  const [isExpandedUser, setIsExpandedUser] = useState(false);
  const [isExpandedCommonUser, setIsExpandedCommonUser] = useState(false);
  const [isExpandedUserAdmin, setIsExpandedUserAdmin] = useState(false);
  const [isExpandedUserAdminEditor, setIsExpandedUserAdminEditor] =
    useState(false);

  const updateStates = useCallback(
    (state: React.Dispatch<React.SetStateAction<boolean>>) => {
      setIsExpandedClauseRepository(false);
      setIsExpandedClauseCategory(false);
      setIsExpandedIconRepository(false);
      setIsExpandedIconCategory(false);
      setIsExpandedImageRepository(false);
      setIsExpandedImageCategory(false);
      setIsExpandedShapeRepository(false);
      setIsExpandedShapeCategory(false);
      setIsExpandedGraphicUser(false);
      setIsExpandedSendEmail(false);
      setIsExpandedTemplateRepository(false);
      setIsExpandedTemplateCategory(false);
      setIsExpandedCommonUser(false);
      setIsExpandedUserAdmin(false);
      setIsExpandedUserAdminEditor(false);
      setIsActiveHome(false);
      setIsActiveTextReplacement(false);
      setIsActiveRemoteAccess(false);

      state(true);
    },
    []
  );

  const getUrl = useCallback(
    (url: string) => {
      if (
        url === "/clauses" ||
        url === "/create-clause" ||
        url === "/edit-clause/:clause_id" ||
        url === "/view-clause/:clause_id" ||
        url === "/clause-category" ||
        url === "/create-clause-category" ||
        url === "/edit-clause-category/:clause_category_id" ||
        url === "/view-clause-category/:clause_category_id"
      ) {
        setIsExpandedClause(true);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        if (
          url === "/clauses" ||
          url === "/create-clause" ||
          url === "/edit-clause/:clause_id" ||
          url === "/view-clause/:clause_id"
        ) {
          updateStates(setIsExpandedClauseRepository);
        } else if (
          url === "/clause-category" ||
          url === "/create-clause-category" ||
          url === "/edit-clause-category/:clause_category_id" ||
          url === "/view-clause-category/:clause_category_id"
        ) {
          updateStates(setIsExpandedClauseCategory);
        }
      } else if (
        url === "/templates" ||
        url === "/create-template" ||
        url === "/edit-template/:template_id" ||
        url === "/view-template/:template_id" ||
        url === "/clone-template" ||
        url === "/clone-template-to-user" ||
        url === "/template-category" ||
        url === "/create-template-category" ||
        url === "/edit-template-category/:template_category_id" ||
        url === "/view-template-category/:template_category_id"
      ) {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(true);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        if (
          url === "/templates" ||
          url === "/create-template" ||
          url === "/edit-template/:template_id" ||
          url === "/view-template/:template_id" ||
          url === "/clone-template" ||
          url === "/clone-template-to-user"
        ) {
          updateStates(setIsExpandedTemplateRepository);
        } else if (
          url === "/template-category" ||
          url === "/create-template-category" ||
          url === "/edit-template-category/:template_category_id" ||
          url === "/view-template-category/:template_category_id"
        ) {
          updateStates(setIsExpandedTemplateCategory);
        }
      } else if (
        url === "/icons" ||
        url === "/create-icon" ||
        url === "/edit-icon/:icon_id" ||
        url === "/view-icon/:icon_id" ||
        url === "/icon-category" ||
        url === "/create-icon-category" ||
        url === "/edit-icon-category/:icon_category_id" ||
        url === "/view-icon-category/:icon_category_id"
      ) {
        setIsExpandedClause(false);
        setIsExpandedIcon(true);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        if (
          url === "/icons" ||
          url === "/create-icon" ||
          url === "/edit-icon/:icon_id" ||
          url === "/view-icon/:icon_id"
        ) {
          updateStates(setIsExpandedIconRepository);
        } else if (
          url === "/icon-category" ||
          url === "/create-icon-category" ||
          url === "/edit-icon-category/:icon_category_id" ||
          url === "/view-icon-category/:icon_category_id"
        ) {
          updateStates(setIsExpandedIconCategory);
        }
      } else if (
        url === "/image" ||
        url === "/create-image" ||
        url === "/edit-image/:image_id" ||
        url === "/view-image/:image_id" ||
        url === "/image-category" ||
        url === "/create-image-category" ||
        url === "/edit-image-category/:image_category_id" ||
        url === "/view-image-category/:image_category_id"
      ) {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(true);
        setIsExpandedShape(false);

        if (
          url === "/image" ||
          url === "/create-image" ||
          url === "/edit-image/:image_id" ||
          url === "/view-image/:image_id"
        ) {
          updateStates(setIsExpandedImageRepository);
        } else if (
          url === "/image-category" ||
          url === "/create-image-category" ||
          url === "/edit-image-category/:image_category_id" ||
          url === "/view-image-category/:image_category_id"
        ) {
          updateStates(setIsExpandedImageCategory);
        }
      } else if (
        url === "/shapes" ||
        url === "/create-shapes" ||
        url === "/edit-shapes/:shape_id" ||
        url === "/view-shapes/:shape_id" ||
        url === "/shapes-category" ||
        url === "/create-shapes-category" ||
        url === "/edit-shapes-category/:shape_category_id" ||
        url === "/view-shapes-category/:shape_category_id"
      ) {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(true);

        if (
          url === "/shapes" ||
          url === "/create-shapes" ||
          url === "/edit-shapes/:shape_id" ||
          url === "/view-shapes/:shape_id"
        ) {
          updateStates(setIsExpandedShapeRepository);
        } else if (
          url === "/shapes-category" ||
          url === "/create-shapes-category" ||
          url === "/edit-shapes-category/:shape_category_id" ||
          url === "/view-shapes-category/:shape_category_id"
        ) {
          updateStates(setIsExpandedShapeCategory);
        }
      } else if (
        url === "/users" ||
        url === "/create-user" ||
        url === "/view-users/:user_id" ||
        url === "/reset-password-user/:user_id" ||
        url === "/update-user-subscription/:user_id" ||
        url === "/users-admin" ||
        url === "/create-user-admin" ||
        url === "/view-user-admin/:which_page/:user_id" ||
        url === "/edit-user-admin/:user_id" ||
        url === "/reset-password-user-admin/:user_id" ||
        url === "/users-admin-editor" ||
        url === "/create-user-admin-editor"
      ) {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(true);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        if (
          url === "/users" ||
          url === "/create-user" ||
          url === "/view-users/:user_id" ||
          url === "/reset-password-user/:user_id" ||
          url === "/update-user-subscription/:user_id"
        ) {
          updateStates(setIsExpandedCommonUser);
        } else if (
          url === "/users-admin" ||
          url === "/create-user-admin" ||
          url === "/view-user-admin/:which_page/:user_id" ||
          url === "/edit-user-admin/:user_id" ||
          url === "/reset-password-user-admin/:user_id"
        ) {
          updateStates(setIsExpandedUserAdmin);
        } else if (
          url === "/users-admin-editor" ||
          url === "/create-user-admin-editor"
        ) {
          updateStates(setIsExpandedUserAdminEditor);
        }
      } else if (url === "/user-graphics") {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(true);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        updateStates(setIsExpandedGraphicUser);
      } else if (url === "/send-personalized-email") {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(true);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        updateStates(setIsExpandedSendEmail);
      } else if (url === "/") {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        updateStates(setIsActiveHome);
      } else if (
        url === "/text-replacement" ||
        url === "/create-text-replacement" ||
        url === "/edit-text-replacement/:text_replacement_id" ||
        url === "/view-text-replacement/:text_replacement_id"
      ) {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        updateStates(setIsActiveTextReplacement);
      } else if (url === "/remote-access" || url === "/create-remote-access") {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        updateStates(setIsActiveRemoteAccess);
      } else {
        setIsExpandedClause(false);
        setIsExpandedIcon(false);
        setIsExpandedTemplate(false);
        setIsExpandedUser(false);
        setIsExpandedGraphic(false);
        setIsExpandedEmail(false);
        setIsExpandedImage(false);
        setIsExpandedShape(false);

        setIsExpandedClauseRepository(false);
        setIsExpandedClauseCategory(false);
        setIsExpandedIconRepository(false);
        setIsExpandedIconCategory(false);
        setIsExpandedImageRepository(false);
        setIsExpandedImageCategory(false);
        setIsExpandedShapeRepository(false);
        setIsExpandedShapeCategory(false);
        setIsExpandedGraphicUser(false);
        setIsExpandedSendEmail(false);
        setIsExpandedTemplateRepository(false);
        setIsExpandedTemplateCategory(false);
        setIsExpandedCommonUser(false);
        setIsExpandedUserAdmin(false);
        setIsExpandedUserAdminEditor(false);
        setIsActiveHome(false);
        setIsActiveTextReplacement(false);
        setIsActiveRemoteAccess(false);
      }
    },
    [updateStates]
  );

  return (
    <RoutesContext.Provider
      value={{
        getUrl,
        isExpandedClauseRepository,
        setIsExpandedClauseRepository,
        isExpandedClause,
        setIsExpandedClause,
        isExpandedClauseCategory,
        setIsExpandedClauseCategory,
        isExpandedIcon,
        setIsExpandedIcon,
        isExpandedIconRepository,
        setIsExpandedIconRepository,
        isExpandedIconCategory,
        setIsExpandedIconCategory,
        isExpandedImage,
        setIsExpandedImage,
        isExpandedImageRepository,
        setIsExpandedImageRepository,
        isExpandedImageCategory,
        setIsExpandedImageCategory,
        isExpandedShape,
        setIsExpandedShape,
        isExpandedShapeRepository,
        setIsExpandedShapeRepository,
        isExpandedShapeCategory,
        setIsExpandedShapeCategory,
        isExpandedGraphic,
        setIsExpandedGraphic,
        isExpandedGraphicUser,
        setIsExpandedGraphicUser,
        isExpandedEmail,
        setIsExpandedEmail,
        isExpandedSendEmail,
        setIsExpandedSendEmail,
        isExpandedTemplate,
        setIsExpandedTemplate,
        isExpandedTemplateRepository,
        setIsExpandedTemplateRepository,
        isExpandedTemplateCategory,
        setIsExpandedTemplateCategory,
        isExpandedUser,
        setIsExpandedUser,
        isExpandedCommonUser,
        setIsExpandedCommonUser,
        isExpandedUserAdmin,
        setIsExpandedUserAdmin,
        isExpandedUserAdminEditor,
        setIsExpandedUserAdminEditor,
        isActiveHome,
        setIsActiveHome,
        isActiveTextReplacement,
        setIsActiveTextReplacement,
        isActiveRemoteAccess,
        setIsActiveRemoteAccess,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

function useRoutes(): RoutesDataProps {
  const context = useContext(RoutesContext);

  if (!context) {
    throw new Error("useRoutes must be used with an RoutesHookProvider");
  }

  return context;
}

export { RoutesHookProvider, useRoutes };
