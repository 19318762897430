import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../services/api";
import { AxiosError } from "axios";
import secureLocalStorage from "react-secure-storage";

interface AuthContextData {
  data?: IData | null;
  loading?: boolean;
  signIn(email: string, password: string): Promise<void>;
  signOut(): Promise<void>;
}

interface IData {
  user: {
    id: string;
    people_id: string;
    email: string;
    avatar: "avatar_default.png" | string;
    is_admin: boolean;
    is_blocked: boolean;
    created_at: string;
    updated_at: string;
    avatar_url: string;
  };
  people: {
    id: string;
    address_id: string;
    contact_id: string;
    first_name: string;
    last_name: string;
    born_at: string;
    gender: string;
    document: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  address: {
    id: string;
    zipcode: string;
    street: string;
    number: number;
    district: string;
    city: string;
    state: string;
    country: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  contact: {
    id: string;
    phone: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  admin_user_permissions: {
    id: string;
    user_id: string;
    create_templates: boolean;
    read_templates: boolean;
    update_templates: boolean;
    delete_templates: boolean;
    clone_templates: boolean;
    create_template_categories: boolean;
    read_template_categories: boolean;
    update_template_categories: boolean;
    delete_template_categories: boolean;
    create_users: boolean;
    read_users: boolean;
    block_users: boolean;
    update_user_password: boolean;
    update_user_subscription: boolean;
    delete_users: boolean;
    cancel_users: boolean;
    create_clauses: boolean;
    read_clauses: true;
    update_clauses: boolean;
    delete_clauses: boolean;
    create_clause_categories: boolean;
    read_clause_categories: true;
    update_clause_categories: boolean;
    delete_clause_categories: boolean;
    create_icons: boolean;
    read_icons: boolean;
    update_icons: boolean;
    delete_icons: boolean;
    create_icons_categories: boolean;
    read_icons_categories: boolean;
    update_icons_categories: boolean;
    delete_icons_categories: boolean;
    create_users_admin: boolean;
    read_users_admin: boolean;
    update_users_admin: boolean;
    update_users_admin_password: boolean;
    block_users_admin: boolean;
    read_users_admin_editor: boolean;
    create_users_admin_editor: boolean;
    delete_users_admin_editor: boolean;
    user_graphics: boolean;
    send_personalized_email: boolean;
    create_text_replacement: boolean;
    read_text_replacement: boolean;
    update_text_replacement: boolean;
    delete_text_replacement: boolean;
    create_image: boolean;
    read_image: boolean;
    update_image: boolean;
    delete_image: boolean;
    create_image_categories: boolean;
    read_image_categories: boolean;
    update_image_categories: boolean;
    delete_image_categories: boolean;
    create_shapes: boolean;
    read_shapes: boolean;
    update_shapes: boolean;
    delete_shapes: boolean;
    create_shapes_categories: boolean;
    read_shapes_categories: boolean;
    update_shapes_categories: boolean;
    delete_shapes_categories: boolean;
    clone_bits_to_user_template: boolean;
    create_remote_access_user: boolean;
    read_remote_access_user: boolean;
    delete_remote_access_user: boolean;
    download_users_admin: boolean;
    create_element_block: boolean;
    read_element_block: boolean;
    update_element_block: boolean;
    delete_element_block: boolean;
    create_element_block_category: boolean;
    read_element_block_category: boolean;
    update_element_block_category: boolean;
    delete_element_block_category: boolean;
    created_at: Date;
    updated_at: Date;
  };
  token: string;
  tokenFirebase: string;
  payment_method: {
    is_active: boolean;
    customer_id: string;
    subscription_id: string;
  };
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IData | null>(null);

  useEffect(() => {
    let isRefreshing = false;
    let failedRequestQueue: Array<{
      onSuccess: (token: string) => void;
      onFailure: (err: AxiosError) => void;
    }> = [];

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (
            error?.response?.data?.message ===
            "Incorrect email/password combination."
          ) {
            // if incorrect email we throw error to get catch on sign function
            throw error;
          }

          if (
            error?.response?.data?.message === "Blocked user, contact support."
          ) {
            throw error;
          }

          if (error.response.data?.message === "Invalid JWT token") {
            //refresh token
            const originalConfig = error.config;

            const oldToken = secureLocalStorage.getItem("@UXDOCDASH:token");

            if (!isRefreshing) {
              isRefreshing = true;

              api
                .put("/sessions/refresh-token", { token: oldToken })
                .then((response) => {
                  const { refresh_token } = response.data;

                  secureLocalStorage.setItem("@UXDOCDASH:token", refresh_token);

                  api.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${refresh_token}`;

                  failedRequestQueue.forEach((request) =>
                    request.onSuccess(refresh_token)
                  );
                  failedRequestQueue = [];
                })
                .catch((err) => {
                  failedRequestQueue.forEach((request) =>
                    request.onFailure(err)
                  );
                  failedRequestQueue = [];
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }

            return new Promise((resolve, reject) => {
              failedRequestQueue.push({
                onSuccess: (token: string) => {
                  originalConfig.headers["Authorization"] = `Bearer ${token}`;

                  resolve(api(originalConfig));
                },
                onFailure: (err: AxiosError) => {
                  reject(err);
                  signOut();
                },
              });
            });
          }
        } else {
          //if there is any other kind of error throw it
          throw error;
        }
      }
    );
  }, []);

  useEffect(() => {
    const autoLoad = async () => {
      const token = secureLocalStorage.getItem("@UXDOCDASH:token");
      const data = secureLocalStorage.getItem("@UXDOCDASH:data");

      if (token && data) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setData(JSON.parse(String(data)));
      }

      await api.get("profile");
    };

    autoLoad();
  }, []);

  const signIn = useCallback(async (email, password) => {
    try {
      const response = await api.post("sessions/admin", {
        email,
        password,
      });

      const {
        user,
        address,
        contact,
        people,
        admin_user_permissions,
        token,
        tokenFirebase,
        payment_method,
      } = response.data as IData;

      secureLocalStorage.setItem("@UXDOCDASH:token", token);
      secureLocalStorage.setItem(
        "@UXDOCDASH:data",
        JSON.stringify(response.data)
      );

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setData({
        user,
        address,
        contact,
        people,
        admin_user_permissions,
        token,
        tokenFirebase,
        payment_method,
      });
    } catch (error: any) {
      throw error;
    }
  }, []);

  const signOut = useCallback(async () => {
    secureLocalStorage.removeItem("@UXDOCDASH:token");
    secureLocalStorage.removeItem("@UXDOCDASH:data");

    setData(null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        data,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used with an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
