import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { Divider } from "@material-ui/core";
import { parse } from "date-fns";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateUser from "./dtos/IFormCreateUser";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import InputSelect from "../../../../../../shared/components/InputSelect";
import InputRadio from "../../../../../../shared/components/InputRadio";
import InputMask from "../../../../../../shared/components/InputMask";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";
import Alert from "../../../../../../shared/components/Alert";

import { Notify, DateFormatedToDatabase } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateUser: React.FC = () => {
  const [isIndividual, setIsIndividual] = useState(true);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/create-user");
  }, []);

  const formSchema = yup.object().shape({
    first_name: yup.string().required("nome é um campo obrigatório"),
    last_name: yup.string().required("sobrenome é um campo obrigatório"),
    email: yup
      .string()
      .email("Insira um email valido")
      .required("email é um campo obrigatório"),
    password: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(
        /[*@!#%$&()^~?:<>{}]+/,
        "A senha deve conter pelo menos um símbolo"
      )
      .required(`Senha necessária`),
    phone: yup.string().required("telefone é um campo obrigatório"),
    where_do_you_work: yup
      .string()
      .required("onde você trabalha é um campo obrigatório"),
    how_did_you_meet_us: yup
      .string()
      .required("onde nos conheceu é um campo obrigatório"),
    document: yup.string().required("documento é um campo obrigatório"),
    is_individual: yup
      .string()
      .required("tipo da pessoa é um campo obrigatório"),
    cancellation_date: yup
      .date()
      .transform((value, originalValue, context) => {
        return parse(originalValue, "dd/MM/yyyy", new Date());
      })
      .typeError("data de vigência está invalida")
      .required("data de vigência é um campo obrigatório"),
    user_type_users_plans: yup
      .string()
      .required("tipo de usuário é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormCreateUser> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        console.log("antes -> ", formData.cancellation_date);

        const newDate = DateFormatedToDatabase({
          date: formData.cancellation_date,
        });

        console.log("antes -> ", newDate);

        const createUser = await api.post("/users", {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          password: formData.password,
          phone: formData.phone.replace(/[^a-zA-Z0-9s]/g, ""),
          user_type: "collaborator",
          where_do_you_work: formData.where_do_you_work,
          how_did_you_meet_us: formData.how_did_you_meet_us,
          document: formData.document.replace(/[^a-zA-Z0-9s]/g, ""),
          is_individual: formData.is_individual === "true" ? true : false,
          cancellation_date: newDate,
          user_type_users_plans: formData.user_type_users_plans,
        });

        if (createUser.status === 200) {
          Notify({ text: "Usuário cadastrado com sucesso", type: "success" });
          setLoading(false);
          navigate("/users", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        if (err?.response?.data?.message === "Invalid CPF.") {
          Notify({ text: "CPF Inválido.", type: "error" });
        }

        if (err?.response?.data?.message === "CPF already used") {
          Notify({ text: "CPF já está em uso.", type: "error" });
        }

        if (err?.response?.data?.message === "Invalid CNPJ.") {
          Notify({ text: "CNPJ Inválido.", type: "error" });
        }

        if (err?.response?.data?.message === "Email address alredy used") {
          Notify({ text: "Email já cadastrado", type: "error" });
        }

        setLoading(false);
      }
    },
    [navigate, dataLocation]
  );

  const optionsWhereDoYouWork = [
    {
      value: "Escritório de advocacia pequeno",
      label: "Escritório de advocacia pequeno",
    },
    {
      value: "Escritório de advocacia grande",
      label: "Escritório de advocacia grande",
    },
    {
      value: "Departamento jurídico de empresa",
      label: "Departamento jurídico de empresa",
    },
    {
      value: "Sou advogado autônomo (a)",
      label: "Sou advogado autônomo (a)",
    },
    {
      value: "Sou estudante de Direito",
      label: "Sou estudante de Direito",
    },
    {
      value: "Não tenho formação na área jurídica",
      label: "Não tenho formação na área jurídica",
    },
  ];

  const optionsHowDidYouMeetUs = [
    { value: "Google", label: "Google" },
    { value: "Cursos", label: "Cursos" },
    { value: "Instagram", label: "Instagram" },
    { value: "Linkedin", label: "Linkedin" },
    { value: "Indicação", label: "Indicação" },
    { value: "Outros", label: "Outros" },
  ];

  const optionsUserTypeUserPlan = [
    { value: "b2b", label: "B2B" },
    { value: "ld_pass", label: "Legal Design Pass" },
    { value: "cortesia", label: "Cortesia" },
    { value: "pre_venda", label: "Pré Venda" },
    { value: "user_free", label: "Usuário padrão" },
  ];

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar usuário" />
      <MasterMain>
        <Main>
          <Alert
            message="Aqui você cria as contas para usuários comuns da 
              plataforma, B2B, Cortesia e Legal Design Pass."
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome *</LabelInput>
              <Input
                label="first_name"
                register={register}
                required={true}
                placeholder="Digite o nome"
              />
              {errors.first_name && (
                <ErrorInput>{errors.first_name.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Sobrenome *</LabelInput>
              <Input
                label="last_name"
                register={register}
                required={true}
                placeholder="Digite o sobrenome"
              />
              {errors.last_name && (
                <ErrorInput>{errors.last_name.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Email *</LabelInput>
              <Input
                label="email"
                register={register}
                required={true}
                placeholder="Digite o email"
              />
              {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Senha *</LabelInput>
              <Input
                label="password"
                register={register}
                required={true}
                placeholder="Digite a senha"
              />
              {errors.password && (
                <ErrorInput>{errors.password.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Telefone *</LabelInput>
              <InputMask
                mask="(99) 99999-9999"
                control={control}
                label="phone"
                register={register}
                required={true}
                placeholder="Digite o telefone"
              />
              {errors.phone && <ErrorInput>{errors.phone.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Onde você trabalha?</LabelInput>

              <InputSelect
                label="where_do_you_work"
                defaultValue=""
                options={optionsWhereDoYouWork}
                control={control}
              />
              {errors.where_do_you_work && (
                <ErrorInput>{errors.where_do_you_work.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Onde nos conheceu?</LabelInput>

              <InputSelect
                label="how_did_you_meet_us"
                defaultValue=""
                options={optionsHowDidYouMeetUs}
                control={control}
              />
              {errors.how_did_you_meet_us && (
                <ErrorInput>{errors.how_did_you_meet_us.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Tipo de pessoa *</LabelInput>
              <InputRadio
                id="is_individual_true"
                label="is_individual"
                register={register}
                required={true}
                newValue="true"
                text="Física"
                onClick={() => setIsIndividual(true)}
              />
              <InputRadio
                id="is_individual_false"
                label="is_individual"
                register={register}
                required={true}
                newValue="false"
                text="Jurídica"
                onClick={() => setIsIndividual(false)}
              />
              {errors.is_individual && (
                <ErrorInput>tipo da pessoa é um campo obrigatório</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Documento *</LabelInput>
              <InputMask
                mask={isIndividual ? "999.999.999-99" : "99.999.999/9999-99"}
                control={control}
                label="document"
                register={register}
                required={true}
                placeholder="Digite o documento"
              />
              {errors.document && (
                <ErrorInput>{errors.document.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Data de vigência *</LabelInput>

              <TooltipInformation
                title="Adicione aqui a data que a conta desse usuário expirará. 
                  Nesse dia o usuário perderá acesso do UX Doc"
                placement="top"
                maxWidth={300}
              />

              <InputMask
                mask="99/99/9999"
                control={control}
                label="cancellation_date"
                register={register}
                required={true}
                placeholder="Digite a data"
              />
              {errors.cancellation_date && (
                <ErrorInput>{errors.cancellation_date.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Tipo do usuário *</LabelInput>

              <InputSelect
                label="user_type_users_plans"
                defaultValue=""
                options={optionsUserTypeUserPlan}
                control={control}
              />
              {errors.user_type_users_plans && (
                <ErrorInput>{errors.user_type_users_plans.message}</ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 10, marginTop: 40 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/users", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateUser;
