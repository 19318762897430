import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import * as Dialog from "@radix-ui/react-dialog";
import { useRoutes } from "../../../../../../hooks/routes";

import IListImageApiProps from "./dtos/IListImageApiProps";
import ICreatorAndEditorProps from "./dtos/ICreatorAndEditorProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import DialogModal from "../../../../../../shared/components/DialogModal";

import { Notify } from "../../../../../../shared/utils";

import {
  Container,
  Title,
  Subtitle,
  ContainerLoading,
  Link,
  IconImg,
  ContainerButton,
} from "./styles";

const ViewImage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [creator, setCreator] = useState<ICreatorAndEditorProps>({ name: "" });
  const [lastEditor, setLastEditor] = useState<ICreatorAndEditorProps>({
    name: "",
  });
  const [imageData, setImageData] = useState<IListImageApiProps>({
    bitsImage: {
      id: "",
      creator_id: "",
      last_editor_id: "",
      name: "",
      image: "",
      image_url: "",
      created_at: new Date(),
      updated_at: new Date(),
    },
    bitsImageCategories: [],
  });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { data: dataUseAuth } = useAuth();

  const { getUrl } = useRoutes();

  const userPermissionReadUser = dataUseAuth.admin_user_permissions.read_users;

  const userPermissionUpdate = dataUseAuth.admin_user_permissions.update_image;
  const userPermissionDelete = dataUseAuth.admin_user_permissions.delete_image;

  const { image_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/view-image/:image_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listImageSpecific = await api.get(`/bits-image/${image_id}`);

      if (userPermissionReadUser) {
        const userCreator = await api.get(
          `/profile/${listImageSpecific.data.bitsImage.creator_id}`
        );

        const userLastEditor = await api.get(
          `/profile/${listImageSpecific.data.bitsImage.last_editor_id}`
        );

        setCreator({
          name: `${userCreator.data.people.first_name} ${userCreator.data.people.last_name}`,
        });
        setLastEditor({
          name: `${userLastEditor.data.people.first_name} ${userLastEditor.data.people.last_name}`,
        });
      } else {
        setCreator({
          name: "Anônimo",
        });
        setLastEditor({
          name: "Anônimo",
        });
      }

      setImageData(listImageSpecific.data);
      setLoading(false);
    })();
  }, [image_id, userPermissionReadUser]);

  const handleDeleteImage = useCallback(
    async (id: string) => {
      await api.delete(`/bits-image/${id}`);
      Notify({ text: "Ilustração deletada com sucesso", type: "success" });
      navigate("/image", {
        state: { params: dataLocation?.params },
      });
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Visualizar ilustração" />
      <MasterMain>
        {loading && (
          <ContainerLoading>
            <SyncLoader loading={loading} color="#663399" size={10} />
          </ContainerLoading>
        )}

        {!loading && (
          <Container>
            <Title>Ilustração:</Title>
            <IconImg src={imageData.bitsImage.image_url} />

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Title>Nome:</Title>
            <Subtitle>
              {imageData.bitsImage.name ? imageData.bitsImage.name : "null"}
            </Subtitle>

            <Title>URL da imagem:</Title>
            <Link href={imageData.bitsImage.image_url} target="_blank">
              {imageData.bitsImage.image_url}
            </Link>

            <Title>Criador:</Title>
            <Subtitle>{creator.name}</Subtitle>

            <Title>Último a editar:</Title>
            <Subtitle>{lastEditor.name}</Subtitle>

            <Title>Criado em:</Title>
            <Subtitle>
              {`${new Date(imageData.bitsImage.created_at)}`
                ? `${format(
                    new Date(imageData.bitsImage.created_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Title>Última atualização:</Title>
            <Subtitle>
              {`${new Date(imageData.bitsImage.updated_at)}`
                ? `${format(
                    new Date(imageData.bitsImage.updated_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 20 }} />

            <Title>Categorias:</Title>
            {imageData.bitsImageCategories.map((item) => (
              <Subtitle key={item.id} style={{ marginBottom: 0, marginTop: 5 }}>
                {item.title}
              </Subtitle>
            ))}

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <ContainerButton>
              <Button
                style={{ width: 100 }}
                onClick={() =>
                  navigate("/image", {
                    state: { params: dataLocation?.params },
                  })
                }
              >
                Voltar
              </Button>

              <Button
                style={{ width: 100, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/edit-image/${image_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!userPermissionUpdate}
              >
                Editar
              </Button>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 100, marginLeft: 20 }}
                    disabled={!userPermissionDelete}
                  >
                    Excluir
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title="Deletar ilustração"
                  type="delete"
                  subtitle={imageData.bitsImage.name}
                  callback={() => handleDeleteImage(imageData.bitsImage.id)}
                />
              </Dialog.Root>
            </ContainerButton>
          </Container>
        )}
      </MasterMain>
    </MasterContainer>
  );
};

export default ViewImage;
