import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormEditTemplateCategory from "./dtos/IFormEditTemplateCategory";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const EditTemplateCategory: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const { getUrl } = useRoutes();

  const { template_category_id } = useParams();

  const formSchema = yup.object().shape({
    title: yup.string().required("Título é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    getUrl("/edit-template-category/:template_category_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listTemplateSpecific = await api.get(
        `/bits-template-categories/${template_category_id}`
      );

      reset({
        title: listTemplateSpecific.data.title,
      });
    })();
  }, [template_category_id, reset]);

  const onSubmit: SubmitHandler<IFormEditTemplateCategory> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const updateTemplateCategory = await api.put(
          "/bits-template-categories",
          {
            bits_template_category_id: template_category_id,
            title: formData.title,
          }
        );

        if (updateTemplateCategory.status === 200) {
          Notify({
            text: "Categoria de templates atualizada com sucesso",
            type: "success",
          });
          setLoading(false);
          navigate("/template-category", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [template_category_id, navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Editar categoria de templates" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Título *</LabelInput>
              <Input
                label="title"
                register={register}
                required={true}
                placeholder="Digite o título"
              />
              {errors.title && <ErrorInput>{errors.title.message}</ErrorInput>}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/template-category", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default EditTemplateCategory;
