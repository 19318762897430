import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./hooks/auth";
import { RoutesHookProvider } from "./hooks/routes";
import { CustomThemeProvider } from "./hooks/theme";
import RouteApp from "./routes";
import GlobalStyle from "./shared/styles/GlobalStyle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <ToastContainer />
      <RoutesHookProvider>
        <AuthProvider>
          <CustomThemeProvider>
            <GlobalStyle />
            <RouteApp />
          </CustomThemeProvider>
        </AuthProvider>
      </RoutesHookProvider>
    </Router>
  );
}

export default App;
