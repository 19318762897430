import React, { useEffect } from "react";
import { useRoutes } from "../../../../hooks/routes";

import MasterAsideControls from "../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../components/SidebarMaster/MasterMain";

import {
  ButtonRatingExcel,
  ButtonsExcelContainer,
  ButtonUserExcel,
  Container,
  FullChart,
  GridContainer,
  GridItem,
  WrapperRight,
} from "./styles";

const Dashboard: React.FC = () => {
  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/");
  }, []);

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="" />
      <MasterMain>
        <p>Novidades em breve</p>
      </MasterMain>
    </MasterContainer>
  );
};

export default Dashboard;
