import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateUserAdminEditor from "./dtos/IFormCreateUserAdminEditor";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import Alert from "../../../../../../shared/components/Alert";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateUserAdminEditor: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/create-user-admin-editor");
  }, []);

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um email valido")
      .required("email é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit: SubmitHandler<IFormCreateUserAdminEditor> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const createUser = await api.post("/bits-templates-editor-users", {
          email: formData.email,
        });

        if (createUser.status === 200) {
          Notify({ text: "Usuário cadastrado com sucesso", type: "success" });
          setLoading(false);
          navigate("/users-admin-editor", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        if (err?.response?.data?.message === "Email does not exist") {
          Notify({ text: "Email não existe", type: "error" });
        }

        if (err?.response?.data?.message === "This user is not admin") {
          Notify({ text: "Esse usuário não é administrador", type: "error" });
        }

        if (err?.response?.data?.message === "User already registered") {
          Notify({ text: "Esse usuário já foi registrado", type: "error" });
        }

        if (
          err?.response?.data?.message ===
          "You must remove all admin permissions from that user"
        ) {
          Notify({
            text: "Você deve remover todas as permissões de administrador desse usuário para adicionar ele como usuário editor",
            type: "error",
          });
        }

        setLoading(false);
      }
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar usuário editor admin" />
      <MasterMain>
        <Main>
          <Alert
            message="Adicione o email do usuário administrador que foi criado. 
              Esse usuário não pode ter nenhuma permissão de administrador. Esse 
              usuário irá logar no APP do UX Doc para editar os templates da bits"
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Email *</LabelInput>
              <Input
                label="email"
                register={register}
                required={true}
                placeholder="Digite o email"
              />
              {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/users-admin-editor", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateUserAdminEditor;
