import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1`
  margin-bottom: 5px;
  font-size: 150px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.purple};

  @media (max-width: 450px) {
    font-size: 70px;
  }
`;

export const Img = styled.div`
  width: 100%;
  max-width: 800px;
  height: 380px;

  background-image: url("https://d1fsx5fzdaqq9n.cloudfront.net/404.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  @media (max-width: 450px) {
    height: 300px;
  }
`;

export const Text = styled.span`
  font-size: 30px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: 450px) {
    font-size: 22px;
  }
`;

export const SubText = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 40px;

  @media (max-width: 450px) {
    font-size: 15px;
  }
`;
