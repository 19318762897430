import React, { useEffect, useState, useCallback } from "react";
import api from "../../../../../../../services/api";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import IWeekGraphicsApiProps from "../../dtos/IWeekGraphicsApiProps";
import IFormFilterWeekGraphics from "../../dtos/IFormFilterWeekGraphics";
import IListDataParamsWeekGraphics from "../../dtos/IListDataParamsWeekGraphics";
import IHookForm from "../../../../../../../shared/dtos/IHookForm";

import InputMask from "../../../../../../../shared/components/InputMask";
import InputSelect from "../../../../../../../shared/components/InputSelect";
import Button from "../../../../../../../shared/components/Button";
import IconSvg from "../../../../../../../shared/components/IconSvg";

import iconSearchWhite from "../../../../../../../shared/assets/icon-search-white.svg";

import { Container, Form, ContainerInputs, TextTotal } from "./styles";

const TotalActiveUsers: React.FC = () => {
  const [totalUser, setTotalUser] = useState(0);
  const [dataGraphic, setDataGraphic] = useState<Array<IWeekGraphicsApiProps>>(
    []
  );
  const [listDataParams, setListDataParams] =
    useState<IListDataParamsWeekGraphics>({});

  const formSchema = yup.object().shape({
    year: yup.string(),
    month: yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    (async () => {
      const listGraphic = await api.get("/user-graphics/total-active-users", {
        params: listDataParams,
      });

      const totalMonth =
        listGraphic.data.week1 +
        listGraphic.data.week2 +
        listGraphic.data.week3 +
        listGraphic.data.week4 +
        listGraphic.data.week5 +
        listGraphic.data.week6;

      setTotalUser(totalMonth);

      const arrayWeeks = [];

      arrayWeeks.push({ week: listGraphic.data.week1 });
      arrayWeeks.push({ week: listGraphic.data.week2 });
      arrayWeeks.push({ week: listGraphic.data.week3 });
      arrayWeeks.push({ week: listGraphic.data.week4 });
      arrayWeeks.push({ week: listGraphic.data.week5 });
      arrayWeeks.push({ week: listGraphic.data.week6 });

      setDataGraphic(arrayWeeks);
    })();
  }, [listDataParams]);

  const onSubmit: SubmitHandler<IFormFilterWeekGraphics> = useCallback(
    async (formData) => {
      try {
        if (formData.month === "" && formData.year === "") {
          setListDataParams({});
        } else {
          setListDataParams({
            month: Number(formData.month),
            year: Number(formData.year),
          });
        }
      } catch (err: any) {}
    },
    []
  );

  const optionsMonth = [
    { value: "1", label: "Janeiro" },
    { value: "2", label: "Fevereiro" },
    { value: "3", label: "Março" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Maio" },
    { value: "6", label: "Junho" },
    { value: "7", label: "Julho" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = [
    "Semana 1",
    "Semana 2",
    "Semana 3",
    "Semana 4",
    "Semana 5",
    "Semana 6",
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Total de usuários ativos por mês",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Usuários",
        data: dataGraphic.map((item) => (item.week ? item.week : 0)),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <Container>
      <Line options={options} data={data} />

      <TextTotal>Total do mês: {totalUser}</TextTotal>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <ContainerInputs style={{ width: 200 }}>
          <InputSelect
            label="month"
            defaultValue=""
            options={optionsMonth}
            control={control}
          />
        </ContainerInputs>

        <ContainerInputs style={{ width: 200 }}>
          <InputMask
            mask="9999"
            control={control}
            label="year"
            register={register}
            required={true}
            placeholder="Digite o ano"
          />
        </ContainerInputs>

        <ContainerInputs>
          <Button type="submit">
            <IconSvg image={iconSearchWhite} width="18" height="18" />
          </Button>
        </ContainerInputs>
      </Form>
    </Container>
  );
};

export default TotalActiveUsers;
