import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import api from "../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { format } from "date-fns";
import { useAuth } from "../../../../../hooks/auth";
import * as Dialog from "@radix-ui/react-dialog";
import { useRoutes } from "../../../../../hooks/routes";

import IListTextReplacementApiProps from "./dtos/IListTextReplacementApiProps";
import ICreatorAndEditorProps from "./dtos/ICreatorAndEditorProps";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../shared/components/Button";
import DialogModal from "../../../../../shared/components/DialogModal";

import { Notify } from "../../../../../shared/utils";

import {
  Container,
  Title,
  Subtitle,
  ContainerLoading,
  ContainerButton,
} from "./styles";

const ViewTextReplacement: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [creator, setCreator] = useState<ICreatorAndEditorProps>({ name: "" });
  const [lastEditor, setLastEditor] = useState<ICreatorAndEditorProps>({
    name: "",
  });
  const [textReplacementData, setTextReplacementData] =
    useState<IListTextReplacementApiProps>({
      id: "",
      creator_id: "",
      last_editor_id: "",
      word: "",
      replacement: "",
      created_at: new Date(),
      updated_at: new Date(),
    });

  const location = useLocation();
  const dataLocation: any = location.state;

  const { data: dataUseAuth } = useAuth();

  const permissionReadUser = dataUseAuth.admin_user_permissions.read_users;

  const permissionUpdate =
    dataUseAuth.admin_user_permissions.update_text_replacement;
  const permissionDelete =
    dataUseAuth.admin_user_permissions.delete_text_replacement;

  const { text_replacement_id } = useParams();

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/view-text-replacement/:text_replacement_id");
  }, []);

  useEffect(() => {
    (async () => {
      const listTextReplacementSpecific = await api.get(
        `/text-replacement/${text_replacement_id}`
      );

      if (permissionReadUser) {
        const userCreator = await api.get(
          `/profile/${listTextReplacementSpecific.data.creator_id}`
        );

        const userLastEditor = await api.get(
          `/profile/${listTextReplacementSpecific.data.last_editor_id}`
        );

        setCreator({
          name: `${userCreator.data.people.first_name} ${userCreator.data.people.last_name}`,
        });
        setLastEditor({
          name: `${userLastEditor.data.people.first_name} ${userLastEditor.data.people.last_name}`,
        });
      } else {
        setCreator({
          name: "Anônimo",
        });
        setLastEditor({
          name: "Anônimo",
        });
      }

      setTextReplacementData(listTextReplacementSpecific.data);
      setLoading(false);
    })();
  }, [text_replacement_id, permissionReadUser]);

  const handleDeleteTextReplacement = useCallback(
    async (id: string) => {
      await api.delete(`/text-replacement/${id}`);
      Notify({
        text: "Palavra de substituição deletada com sucesso",
        type: "success",
      });
      navigate("/text-replacement", {
        state: { params: dataLocation?.params },
      });
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Visualizar palavra de substituição" />
      <MasterMain>
        {loading && (
          <ContainerLoading>
            <SyncLoader loading={loading} color="#663399" size={10} />
          </ContainerLoading>
        )}

        {!loading && (
          <Container>
            <Title>Palavra principal:</Title>
            <Subtitle>
              {textReplacementData.word ? textReplacementData.word : "null"}
            </Subtitle>

            <Title>Palavra de substituição:</Title>
            <Subtitle>
              {textReplacementData.replacement
                ? textReplacementData.replacement
                : "null"}
            </Subtitle>

            <Title>Criador:</Title>
            <Subtitle>{creator.name}</Subtitle>

            <Title>Último a editar:</Title>
            <Subtitle>{lastEditor.name}</Subtitle>

            <Title>Criado em:</Title>
            <Subtitle>
              {`${new Date(textReplacementData.created_at)}`
                ? `${format(
                    new Date(textReplacementData.created_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Title>Última atualização:</Title>
            <Subtitle>
              {`${new Date(textReplacementData.updated_at)}`
                ? `${format(
                    new Date(textReplacementData.updated_at),
                    "dd/MM/yyyy HH:mm"
                  )}`
                : "null"}
            </Subtitle>

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <ContainerButton>
              <Button
                style={{ width: 100 }}
                onClick={() =>
                  navigate("/text-replacement", {
                    state: { params: dataLocation?.params },
                  })
                }
              >
                Voltar
              </Button>

              <Button
                style={{ width: 100, marginLeft: 20 }}
                onClick={() =>
                  navigate(`/edit-text-replacement/${text_replacement_id}`, {
                    state: { params: dataLocation?.params },
                  })
                }
                disabled={!permissionUpdate}
              >
                Editar
              </Button>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    style={{ width: 100, marginLeft: 20 }}
                    disabled={!permissionDelete}
                  >
                    Excluir
                  </Button>
                </Dialog.Trigger>

                <DialogModal
                  title="Deletar palavra de substituição"
                  type="delete"
                  subtitle={textReplacementData.word}
                  callback={() =>
                    handleDeleteTextReplacement(textReplacementData.id)
                  }
                />
              </Dialog.Root>
            </ContainerButton>
          </Container>
        )}
      </MasterMain>
    </MasterContainer>
  );
};

export default ViewTextReplacement;
