import React, { InputHTMLAttributes } from "react";
import { Path, Controller, Control, FieldValues } from "react-hook-form";
import Select from "react-select";

import { Container } from "./styles";

interface IFormValues {
  [key: string]: any;
}

interface IOptionSelect {
  value: string;
  label: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: Path<IFormValues>;
  options: IOptionSelect[];
  control: Control<FieldValues, any>;
  defaultValue: string;
}

const InputSelect: React.FC<InputProps> = ({
  label,
  options,
  control,
  defaultValue,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "#333333",
      padding: 10,
    }),
  };

  return (
    <Container>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={label}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            ref={ref}
            styles={customStyles}
            value={options.find((c) => c.value === value)}
            onChange={(val) => onChange(val.value)}
            options={options}
          />
        )}
      />
    </Container>
  );
};

export default InputSelect;
