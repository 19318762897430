import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 2px;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray300};
  margin-bottom: 20px;
`;

export const Img = styled.img`
  width: 150px;
  border: 1px solid #ccc;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
