import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../hooks/auth";

import Home from "../modules/dashboard/pages/Home";
import ListClause from "../modules/dashboard/pages/BitsClause/ClauseRepository/ListClause";
import CreateClause from "../modules/dashboard/pages/BitsClause/ClauseRepository/CreateClause";
import EditClause from "../modules/dashboard/pages/BitsClause/ClauseRepository/EditClause";
import ViewClause from "../modules/dashboard/pages/BitsClause/ClauseRepository/ViewClause";
import ListClauseCategory from "../modules/dashboard/pages/BitsClause/ClauseCategory/ListClauseCategory";
import CreateClauseCategory from "../modules/dashboard/pages/BitsClause/ClauseCategory/CreateClauseCategory";
import EditClauseCategory from "../modules/dashboard/pages/BitsClause/ClauseCategory/EditClauseCategory";
import ViewClauseCategory from "../modules/dashboard/pages/BitsClause/ClauseCategory/ViewClauseCategory";
import ListIconCategory from "../modules/dashboard/pages/BitsIcon/IconCategory/ListIconCategory";
import CreateIconCategory from "../modules/dashboard/pages/BitsIcon/IconCategory/CreateIconCategory";
import EditIconCategory from "../modules/dashboard/pages/BitsIcon/IconCategory/EditIconCategory";
import ViewIconCategory from "../modules/dashboard/pages/BitsIcon/IconCategory/ViewIconCategory";
import ListIcon from "../modules/dashboard/pages/BitsIcon/IconRepository/ListIcon";
import CreateIcon from "../modules/dashboard/pages/BitsIcon/IconRepository/CreateIcon";
import EditIcon from "../modules/dashboard/pages/BitsIcon/IconRepository/EditIcon";
import ViewIcon from "../modules/dashboard/pages/BitsIcon/IconRepository/ViewIcon";
import ListImageCategory from "../modules/dashboard/pages/BitsImage/ImageCategory/ListImageCategory";
import CreateImageCategory from "../modules/dashboard/pages/BitsImage/ImageCategory/CreateImageCategory";
import EditImageCategory from "../modules/dashboard/pages/BitsImage/ImageCategory/EditImageCategory";
import ViewImageCategory from "../modules/dashboard/pages/BitsImage/ImageCategory/ViewImageCategory";
import ListImage from "../modules/dashboard/pages/BitsImage/ImageRepository/ListImage";
import CreateImage from "../modules/dashboard/pages/BitsImage/ImageRepository/CreateImage";
import EditImage from "../modules/dashboard/pages/BitsImage/ImageRepository/EditImage";
import ViewImage from "../modules/dashboard/pages/BitsImage/ImageRepository/ViewImage";
import ListShapeCategory from "../modules/dashboard/pages/BitsShapes/ShapesCategory/ListShapesCategory";
import CreateShapeCategory from "../modules/dashboard/pages/BitsShapes/ShapesCategory/CreateShapesCategory";
import EditShapeCategory from "../modules/dashboard/pages/BitsShapes/ShapesCategory/EditShapesCategory";
import ViewShapeCategory from "../modules/dashboard/pages/BitsShapes/ShapesCategory/ViewShapesCategory";
import ListShape from "../modules/dashboard/pages/BitsShapes/ShapesRepository/ListShapes";
import CreateShape from "../modules/dashboard/pages/BitsShapes/ShapesRepository/CreateShapes";
import EditShape from "../modules/dashboard/pages/BitsShapes/ShapesRepository/EditShapes";
import ViewShape from "../modules/dashboard/pages/BitsShapes/ShapesRepository/ViewShapes";
import ListUsers from "../modules/dashboard/pages/Users/Common/ListUsers";
import CreateUser from "../modules/dashboard/pages/Users/Common/CreateUser";
import ViewUsers from "../modules/dashboard/pages/Users/Common/ViewUsers";
import UpdateUserSubscription from "../modules/dashboard/pages/Users/Common/UpdateUserSubscription";
import ResetPasswordUser from "../modules/dashboard/pages/Users/Common/ResetPasswordUser";
import ListTemplateCategory from "../modules/dashboard/pages/BitsTemplates/TemplateCategory/ListTemplateCategory";
import CreateTemplateCategory from "../modules/dashboard/pages/BitsTemplates/TemplateCategory/CreateTemplateCategory";
import EditTemplateCategory from "../modules/dashboard/pages/BitsTemplates/TemplateCategory/EditTemplateCategory";
import ViewTemplateCategory from "../modules/dashboard/pages/BitsTemplates/TemplateCategory/ViewTemplateCategory";
import CloneTemplate from "../modules/dashboard/pages/BitsTemplates/TemplateRepository/CloneTemplate";
import CloneTemplateToUser from "../modules/dashboard/pages/BitsTemplates/TemplateRepository/CloneTemplateToUser";
import CreateTemplate from "../modules/dashboard/pages/BitsTemplates/TemplateRepository/CreateTemplate";
import EditTemplate from "../modules/dashboard/pages/BitsTemplates/TemplateRepository/EditTemplate";
import ListTemplate from "../modules/dashboard/pages/BitsTemplates/TemplateRepository/ListTemplate";
import ViewTemplate from "../modules/dashboard/pages/BitsTemplates/TemplateRepository/ViewTemplate";
import CreateUserAdmin from "../modules/dashboard/pages/Users/Admin/CreateUserAdmin";
import EditUserAdmin from "../modules/dashboard/pages/Users/Admin/EditUserAdmin";
import ListUsersAdmin from "../modules/dashboard/pages/Users/Admin/ListUsersAdmin";
import ViewUserAdmin from "../modules/dashboard/pages/Users/Admin/ViewUserAdmin";
import ResetPasswordUserAdmin from "../modules/dashboard/pages/Users/Admin/ResetPasswordUserAdmin";
import ListUsersAdminEditor from "../modules/dashboard/pages/Users/AdminEditor/ListUsersAdminEditor";
import CreateUserAdminEditor from "../modules/dashboard/pages/Users/AdminEditor/CreateUserAdminEditor";
import UserGraphics from "../modules/dashboard/pages/Graphics/User";
import SendPersonalizedEmail from "../modules/dashboard/pages/Email/SendPersonalizedEmail";
import ListTextReplacement from "../modules/dashboard/pages/TextReplacement/ListTextReplacement";
import CreateTextReplacement from "../modules/dashboard/pages/TextReplacement/CreateTextReplacement";
import EditTextReplacement from "../modules/dashboard/pages/TextReplacement/EditTextReplacement";
import ViewTextReplacement from "../modules/dashboard/pages/TextReplacement/ViewTextReplacement";
import ListRemoteAccess from "../modules/dashboard/pages/RemoteAccess/ListRemoteAccess";
import CreateRemoteAccess from "../modules/dashboard/pages/RemoteAccess/CreateRemoteAccess";
import RouteDoesntExist from "../shared/components/RouteDoesntExist";

import Exit from "../modules/login/pages/Exit";
import Login from "../modules/login/pages/Login";

const RouteApp: React.FC = () => {
  const { data: dataUseAuth } = useAuth();

  return dataUseAuth ? (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* USERS */}
      {(dataUseAuth.admin_user_permissions.create_users ||
        dataUseAuth.admin_user_permissions.read_users ||
        dataUseAuth.admin_user_permissions.block_users ||
        dataUseAuth.admin_user_permissions.update_user_password ||
        dataUseAuth.admin_user_permissions.delete_users ||
        dataUseAuth.admin_user_permissions.cancel_users ||
        dataUseAuth.admin_user_permissions.update_user_subscription) && (
        <Route path="/users" element={<ListUsers />} />
      )}

      {dataUseAuth.admin_user_permissions.create_users && (
        <Route path="/create-user" element={<CreateUser />} />
      )}
      {dataUseAuth.admin_user_permissions.read_users && (
        <Route path="/view-users/:user_id" element={<ViewUsers />} />
      )}
      {dataUseAuth.admin_user_permissions.update_user_password && (
        <Route
          path="/reset-password-user/:user_id"
          element={<ResetPasswordUser />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_user_subscription && (
        <Route
          path="/update-user-subscription/:user_id"
          element={<UpdateUserSubscription />}
        />
      )}

      {/* USERS ADMIN */}
      {(dataUseAuth.admin_user_permissions.create_users_admin ||
        dataUseAuth.admin_user_permissions.read_users_admin ||
        dataUseAuth.admin_user_permissions.update_users_admin ||
        dataUseAuth.admin_user_permissions.block_users_admin) && (
        <Route path="/users-admin" element={<ListUsersAdmin />} />
      )}

      {dataUseAuth.admin_user_permissions.create_users_admin && (
        <Route path="/create-user-admin" element={<CreateUserAdmin />} />
      )}
      {dataUseAuth.admin_user_permissions.read_users_admin && (
        <Route
          path="/view-user-admin/:which_page/:user_id"
          element={<ViewUserAdmin />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_users_admin && (
        <Route path="/edit-user-admin/:user_id" element={<EditUserAdmin />} />
      )}
      {dataUseAuth.admin_user_permissions.update_users_admin_password && (
        <Route
          path="/reset-password-user-admin/:user_id"
          element={<ResetPasswordUserAdmin />}
        />
      )}

      {/* USERS ADMIN EDITOR */}
      {(dataUseAuth.admin_user_permissions.create_users_admin_editor ||
        dataUseAuth.admin_user_permissions.read_users_admin_editor ||
        dataUseAuth.admin_user_permissions.delete_users_admin_editor) && (
        <Route path="/users-admin-editor" element={<ListUsersAdminEditor />} />
      )}
      {dataUseAuth.admin_user_permissions.create_users_admin_editor && (
        <Route
          path="/create-user-admin-editor"
          element={<CreateUserAdminEditor />}
        />
      )}

      {/* TEMPLATE */}
      {(dataUseAuth.admin_user_permissions.create_templates ||
        dataUseAuth.admin_user_permissions.read_templates ||
        dataUseAuth.admin_user_permissions.update_templates ||
        dataUseAuth.admin_user_permissions.delete_templates ||
        dataUseAuth.admin_user_permissions.clone_templates ||
        dataUseAuth.admin_user_permissions.clone_bits_to_user_template) && (
        <Route path="/templates" element={<ListTemplate />} />
      )}

      {dataUseAuth.admin_user_permissions.create_templates && (
        <Route path="/create-template" element={<CreateTemplate />} />
      )}
      {dataUseAuth.admin_user_permissions.update_templates && (
        <Route path="/edit-template/:template_id" element={<EditTemplate />} />
      )}
      {dataUseAuth.admin_user_permissions.read_templates && (
        <Route path="/view-template/:template_id" element={<ViewTemplate />} />
      )}
      {dataUseAuth.admin_user_permissions.clone_templates && (
        <Route path="/clone-template" element={<CloneTemplate />} />
      )}
      {dataUseAuth.admin_user_permissions.clone_bits_to_user_template && (
        <Route
          path="/clone-template-to-user"
          element={<CloneTemplateToUser />}
        />
      )}

      {/* TEMPLATE CATEGORY */}
      {(dataUseAuth.admin_user_permissions.create_template_categories ||
        dataUseAuth.admin_user_permissions.read_template_categories ||
        dataUseAuth.admin_user_permissions.update_template_categories ||
        dataUseAuth.admin_user_permissions.delete_template_categories) && (
        <Route path="/template-category" element={<ListTemplateCategory />} />
      )}

      {dataUseAuth.admin_user_permissions.create_template_categories && (
        <Route
          path="/create-template-category"
          element={<CreateTemplateCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_template_categories && (
        <Route
          path="/edit-template-category/:template_category_id"
          element={<EditTemplateCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.read_template_categories && (
        <Route
          path="/view-template-category/:template_category_id"
          element={<ViewTemplateCategory />}
        />
      )}

      {/* CLAUSES */}
      {(dataUseAuth.admin_user_permissions.create_clauses ||
        dataUseAuth.admin_user_permissions.read_clauses ||
        dataUseAuth.admin_user_permissions.update_clauses ||
        dataUseAuth.admin_user_permissions.delete_clauses) && (
        <Route path="/clauses" element={<ListClause />} />
      )}

      {dataUseAuth.admin_user_permissions.create_clauses && (
        <Route path="/create-clause" element={<CreateClause />} />
      )}
      {dataUseAuth.admin_user_permissions.update_clauses && (
        <Route path="/edit-clause/:clause_id" element={<EditClause />} />
      )}
      {dataUseAuth.admin_user_permissions.read_clauses && (
        <Route path="/view-clause/:clause_id" element={<ViewClause />} />
      )}

      {/* CLAUSE CATEGORY */}
      {(dataUseAuth.admin_user_permissions.create_clause_categories ||
        dataUseAuth.admin_user_permissions.read_clause_categories ||
        dataUseAuth.admin_user_permissions.update_clause_categories ||
        dataUseAuth.admin_user_permissions.delete_clause_categories) && (
        <Route path="/clause-category" element={<ListClauseCategory />} />
      )}

      {dataUseAuth.admin_user_permissions.create_clause_categories && (
        <Route
          path="/create-clause-category"
          element={<CreateClauseCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_clause_categories && (
        <Route
          path="/edit-clause-category/:clause_category_id"
          element={<EditClauseCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.read_clause_categories && (
        <Route
          path="/view-clause-category/:clause_category_id"
          element={<ViewClauseCategory />}
        />
      )}

      {/* ICON */}
      {(dataUseAuth.admin_user_permissions.create_icons ||
        dataUseAuth.admin_user_permissions.read_icons ||
        dataUseAuth.admin_user_permissions.update_icons ||
        dataUseAuth.admin_user_permissions.delete_icons) && (
        <Route path="/icons" element={<ListIcon />} />
      )}

      {dataUseAuth.admin_user_permissions.create_icons && (
        <Route path="/create-icon" element={<CreateIcon />} />
      )}
      {dataUseAuth.admin_user_permissions.update_icons && (
        <Route path="/edit-icon/:icon_id" element={<EditIcon />} />
      )}
      {dataUseAuth.admin_user_permissions.read_icons && (
        <Route path="/view-icon/:icon_id" element={<ViewIcon />} />
      )}

      {/* ICON CATEGORY */}
      {(dataUseAuth.admin_user_permissions.create_icons_categories ||
        dataUseAuth.admin_user_permissions.read_icons_categories ||
        dataUseAuth.admin_user_permissions.update_icons_categories ||
        dataUseAuth.admin_user_permissions.delete_icons_categories) && (
        <Route path="/icon-category" element={<ListIconCategory />} />
      )}

      {dataUseAuth.admin_user_permissions.create_icons_categories && (
        <Route path="/create-icon-category" element={<CreateIconCategory />} />
      )}
      {dataUseAuth.admin_user_permissions.update_icons_categories && (
        <Route
          path="/edit-icon-category/:icon_category_id"
          element={<EditIconCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.read_icons_categories && (
        <Route
          path="/view-icon-category/:icon_category_id"
          element={<ViewIconCategory />}
        />
      )}

      {/* IMAGE */}
      {(dataUseAuth.admin_user_permissions.create_image ||
        dataUseAuth.admin_user_permissions.read_image ||
        dataUseAuth.admin_user_permissions.update_image ||
        dataUseAuth.admin_user_permissions.delete_image) && (
        <Route path="/image" element={<ListImage />} />
      )}

      {dataUseAuth.admin_user_permissions.create_image && (
        <Route path="/create-image" element={<CreateImage />} />
      )}
      {dataUseAuth.admin_user_permissions.update_image && (
        <Route path="/edit-image/:image_id" element={<EditImage />} />
      )}
      {dataUseAuth.admin_user_permissions.read_image && (
        <Route path="/view-image/:image_id" element={<ViewImage />} />
      )}

      {/* IMAGE CATEGORY */}
      {(dataUseAuth.admin_user_permissions.create_image_categories ||
        dataUseAuth.admin_user_permissions.read_image_categories ||
        dataUseAuth.admin_user_permissions.update_image_categories ||
        dataUseAuth.admin_user_permissions.delete_image_categories) && (
        <Route path="/image-category" element={<ListImageCategory />} />
      )}

      {dataUseAuth.admin_user_permissions.create_image_categories && (
        <Route
          path="/create-image-category"
          element={<CreateImageCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_image_categories && (
        <Route
          path="/edit-image-category/:image_category_id"
          element={<EditImageCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.read_image_categories && (
        <Route
          path="/view-image-category/:image_category_id"
          element={<ViewImageCategory />}
        />
      )}

      {/* SHAPE */}
      {(dataUseAuth.admin_user_permissions.create_shapes ||
        dataUseAuth.admin_user_permissions.read_shapes ||
        dataUseAuth.admin_user_permissions.update_shapes ||
        dataUseAuth.admin_user_permissions.delete_shapes) && (
        <Route path="/shapes" element={<ListShape />} />
      )}

      {dataUseAuth.admin_user_permissions.create_shapes && (
        <Route path="/create-shapes" element={<CreateShape />} />
      )}
      {dataUseAuth.admin_user_permissions.update_shapes && (
        <Route path="/edit-shapes/:shape_id" element={<EditShape />} />
      )}
      {dataUseAuth.admin_user_permissions.read_shapes && (
        <Route path="/view-shapes/:shape_id" element={<ViewShape />} />
      )}

      {/* SHAPE CATEGORY */}
      {(dataUseAuth.admin_user_permissions.create_shapes_categories ||
        dataUseAuth.admin_user_permissions.read_shapes_categories ||
        dataUseAuth.admin_user_permissions.update_shapes_categories ||
        dataUseAuth.admin_user_permissions.delete_shapes_categories) && (
        <Route path="/shapes-category" element={<ListShapeCategory />} />
      )}

      {dataUseAuth.admin_user_permissions.create_shapes_categories && (
        <Route
          path="/create-shapes-category"
          element={<CreateShapeCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_shapes_categories && (
        <Route
          path="/edit-shapes-category/:shape_category_id"
          element={<EditShapeCategory />}
        />
      )}
      {dataUseAuth.admin_user_permissions.read_shapes_categories && (
        <Route
          path="/view-shapes-category/:shape_category_id"
          element={<ViewShapeCategory />}
        />
      )}

      {/* USER GRAPHICS */}
      {dataUseAuth.admin_user_permissions.user_graphics && (
        <Route path="/user-graphics" element={<UserGraphics />} />
      )}

      {/* EMAIL */}
      {dataUseAuth.admin_user_permissions.send_personalized_email && (
        <Route
          path="/send-personalized-email"
          element={<SendPersonalizedEmail />}
        />
      )}

      {/* TEXT REPLACEMENT */}
      {(dataUseAuth.admin_user_permissions.create_text_replacement ||
        dataUseAuth.admin_user_permissions.read_text_replacement ||
        dataUseAuth.admin_user_permissions.update_text_replacement ||
        dataUseAuth.admin_user_permissions.delete_text_replacement) && (
        <Route path="/text-replacement" element={<ListTextReplacement />} />
      )}
      {dataUseAuth.admin_user_permissions.create_text_replacement && (
        <Route
          path="/create-text-replacement"
          element={<CreateTextReplacement />}
        />
      )}
      {dataUseAuth.admin_user_permissions.update_text_replacement && (
        <Route
          path="/edit-text-replacement/:text_replacement_id"
          element={<EditTextReplacement />}
        />
      )}
      {dataUseAuth.admin_user_permissions.read_text_replacement && (
        <Route
          path="/view-text-replacement/:text_replacement_id"
          element={<ViewTextReplacement />}
        />
      )}

      {/* REMOTE ACCESS */}
      {(dataUseAuth.admin_user_permissions.create_remote_access_user ||
        dataUseAuth.admin_user_permissions.read_remote_access_user ||
        dataUseAuth.admin_user_permissions.delete_remote_access_user) && (
        <Route path="/remote-access" element={<ListRemoteAccess />} />
      )}
      {dataUseAuth.admin_user_permissions.create_remote_access_user && (
        <Route path="/create-remote-access" element={<CreateRemoteAccess />} />
      )}

      {/* ROUTE DOESN'T EXIST */}
      <Route path="*" element={<RouteDoesntExist />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/exit" element={<Exit />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<RouteDoesntExist />} />
    </Routes>
  );
};

export default RouteApp;
