import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { ImageListType } from "react-images-uploading";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateShapes from "./dtos/IFormCreateShapes";
import IListShapesCategoryApiProps from "./dtos/IListShapesCategoryApiProps";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import InputRadio from "../../../../../../shared/components/InputRadio";
import ImageUpload from "../../../../../../shared/components/ImageUpload";
import InputMultSelect from "../../../../../../shared/components/InputMultSelect";
import ChipInput from "../../../../../../shared/components/ChipInput";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateShapes: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [optionSelect, setOptionSelect] = useState<Array<string>>([]);
  const [imageShape, setImageShape] = useState<ImageListType>([]);
  const [imageIsVisible, setImageIsVisible] = useState(false);
  const [textErrorImageUpload, setTextErrorImageUpload] = useState<{
    sizeError: boolean;
    genericError: boolean;
  }>({ sizeError: false, genericError: false });

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/create-shapes");
  }, []);

  useEffect(() => {
    (async () => {
      const listShapeCategory = await api.get(
        "/bits-shape-category/list-all-dashboard"
      );

      let options = [];

      listShapeCategory.data.forEach((item: IListShapesCategoryApiProps) => {
        options.push(`${item.title}||${item.id}`);
      });

      setOptionSelect(options);
    })();
  }, []);

  const formSchema = yup.object().shape({
    nickname: yup.string().required("nome da forma é um campo obrigatório"),
    key_words: yup
      .array()
      .of(yup.string())
      .required("palavras chave é um campo obrigatório"),
    dpath: yup.string().required("dpath é um campo obrigatório"),
    stroke_width: yup
      .number()
      .typeError("não pode ser vazio, e precisa ser do tipo número")
      .min(0, "o valor mínimo para espessura é 0")
      .required("espessura é um campo obrigatório"),
    is_filled: yup
      .string()
      .required("forma tem fill é um campo obrigatório")
      .typeError("forma tem fill é um campo obrigatório"),
    categories: yup
      .array()
      .min(1, "categorias é um campo obrigatório")
      .required("categorias é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormCreateShapes> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        if (imageShape.length <= 0) {
          setTextErrorImageUpload({ sizeError: false, genericError: true });
          setLoading(false);
        } else {
          if (imageShape[0].file.size <= 1000000) {
            setTextErrorImageUpload({ sizeError: false, genericError: false });

            let newCategories = [];

            formData.categories.forEach((item) => {
              const [label, id] = String(item).split("||");

              newCategories.push({
                bits_shape_category_id: id,
              });
            });

            let isFilled = false;

            if (formData.is_filled === "false") isFilled = false;
            if (formData.is_filled === "true") isFilled = true;

            let keyWord = "";

            formData.key_words.forEach((item, index) => {
              if (index < formData.key_words.length - 1) {
                if (item.trim().length !== 0) {
                  keyWord += `${item.toLowerCase().trim()},`;
                }
              } else {
                if (item.trim().length !== 0) {
                  keyWord += `${item.toLowerCase().trim()}`;
                } else {
                  keyWord = keyWord.substring(0, keyWord.length - 1);
                }
              }
            });

            const createShapeRepository = await api.post("/bits-shape", {
              nickname: formData.nickname,
              key_words: keyWord,
              dpath: formData.dpath,
              stroke_width: formData.stroke_width,
              is_filled: isFilled,
              categories: newCategories,
            });

            const patch = new FormData();

            patch.append("shape", imageShape[0].file);

            const uploadShape = await api.patch(
              `bits-shape/shape-upload/${createShapeRepository.data.id}`,
              patch,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (uploadShape.status === 200) {
              Notify({ text: "Forma criada com sucesso", type: "success" });
              setLoading(false);
              navigate("/shapes", {
                state: { params: dataLocation?.params },
              });
            }
          } else {
            setTextErrorImageUpload({ sizeError: true, genericError: false });
            setLoading(false);
          }
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [navigate, imageShape, dataLocation]
  );

  const onChangeImageShape = useCallback(
    (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
      setImageShape(imageList);
      setImageIsVisible(true);
    },
    []
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar formas" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome da forma *</LabelInput>
              <Input
                label="nickname"
                register={register}
                required={true}
                placeholder="Digite o nome da forma"
              />
              {errors.nickname && (
                <ErrorInput>{errors.nickname.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Palavras chave *</LabelInput>

              <TooltipInformation
                title="A palavra chave é por onde o usuário irá encontrar essa 
                  forma. Então adicione o máximo de palavras que achar 
                  necessário. Aperte a tecla enter para adicionar a palavra chave"
                placement="top"
                maxWidth={300}
              />

              <ChipInput
                control={control}
                label="key_words"
                required={true}
                defaultValue={[]}
                placeholder="Palavras chave"
              />
              {errors.key_words && (
                <ErrorInput>{errors.key_words.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>DPATH *</LabelInput>

              <TooltipInformation
                title="O DPATH é o código da forma. Você consegue copiar esse 
                código se abrir a forma no navegador e apertar a tecla F12, você
                verá a tag D com o path dentro, copie todo o código das tags D
                caso tenha mais que uma tag D copie todos os códigos em uma única
                linha e separe os códigos com vírgula"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="dpath"
                register={register}
                required={true}
                placeholder="Digite o dpath"
              />
              {errors.dpath && <ErrorInput>{errors.dpath.message}</ErrorInput>}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Espessura *</LabelInput>

              <TooltipInformation
                title="Recomendamos usar o valor em 1, porém, precisa testar e
                ver qual é o melhor valor"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="stroke_width"
                register={register}
                required={true}
                placeholder="Digite a espessura"
                type="number"
                step="0.1"
              />
              {errors.stroke_width && (
                <ErrorInput>{errors.stroke_width.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Forma tem fill? *</LabelInput>

              <TooltipInformation
                title="O fill é se ele é preenchido ou não. Por exemplo o ícone
                de um carro todo preto preenchido ou um ícone de um carro so com
                a borda porem o interno do ícone é transparente"
                placement="top"
                maxWidth={300}
              />

              <InputRadio
                id="is_filled_true"
                label="is_filled"
                register={register}
                required={true}
                newValue="true"
                text="Sim"
              />
              <InputRadio
                id="is_filled_false"
                label="is_filled"
                register={register}
                required={true}
                newValue="false"
                text="Não"
              />
              {errors.is_filled && (
                <ErrorInput>{errors.is_filled.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione as categorias *</LabelInput>

              <TooltipInformation
                title="Se a categoria que você quer não estiver na lista. Você 
                  deve ir em categoria de formas e cadastrar a categoria que 
                  deseja"
                placement="top"
                maxWidth={300}
              />

              <InputMultSelect
                control={control}
                label="categories"
                options={optionSelect}
              />
              {errors.categories && (
                <ErrorInput>{errors.categories.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione a forma *</LabelInput>

              <TooltipInformation
                title="O arquivo da forma deve ser exclusivamente em SVG"
                placement="top"
                maxWidth={300}
              />

              <ImageUpload
                isButtonRemove={true}
                preloaded={false}
                urlImagePreloaded=""
                values={imageShape}
                onchange={onChangeImageShape}
                imageIsVisible={imageIsVisible}
                setImageIsVisible={setImageIsVisible}
              />

              {textErrorImageUpload.genericError && (
                <ErrorInput>Selecione uma forma</ErrorInput>
              )}

              {textErrorImageUpload.sizeError && (
                <ErrorInput>
                  Forma muito grande, seleciona uma forma com menos MB
                </ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 5, marginTop: 10 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/shapes", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateShapes;
