import styled from "styled-components";
import { lighten, darken } from "polished";

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
`;

export const ButtonFlexBox = styled.button`
  display: flex;
  background: transparent;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const HStack = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ButtonSearch = styled.button`
  width: 42px;
  height: 42px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};
`;

export const ContainerCreate = styled.div`
  width: 100%;
  padding: 0px 0px 20px 0px;
`;

export const FlexPagination = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 9px;
`;

export const BoxList = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin-top: 20px;
  overflow: auto;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableTr = styled.tr`
  &:nth-child(even) {
    background-color: ${({ theme }) => lighten(0.54, theme.colors.purple)};
  }

  &:hover {
    background-color: ${({ theme }) =>
      darken(0.07, lighten(0.54, theme.colors.purple))};
  }
`;

export const TableTh = styled.th`
  padding: 10px 10px;
  background-color: ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`;

export const TableTd = styled.td`
  padding: 10px 10px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;

  :not(:last-child) {
    cursor: pointer;
  }

  :last-child {
    width: 80px;
  }
`;

export const TableTdText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const TableThead = styled.thead``;

export const TableTbody = styled.tbody``;
