import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCloneTemplateToUser from "./dtos/IFormCloneTemplateToUser";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import Alert from "../../../../../../shared/components/Alert";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import {
  Main,
  Form,
  ContainerInput,
  LabelInput,
  ErrorInput,
  WarningText,
} from "./styles";

const CloneTemplateToUser: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/clone-template-to-user");
  }, []);

  const formSchema = yup.object().shape({
    user_template_id: yup
      .string()
      .uuid("ID do template está inválido")
      .required("ID do template é um campo obrigatório"),
    email: yup.string().email().required("Email é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormCloneTemplateToUser> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const cloneTemplate = await api.put(
          "/bits-templates/clone-bits-to-user-template",
          {
            user_template_id: formData.user_template_id,
            email: formData.email,
          }
        );

        if (cloneTemplate.status === 200) {
          Notify({
            text: "Template clonado com sucesso",
            type: "success",
          });
          setLoading(false);
          navigate("/templates", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Clonar template para o usuários" />
      <MasterMain>
        <Main>
          <Alert
            message="Copie o id do template do usuário que deseja, não importa
                a conta, e coloque no campo de email o email que deseja receber
                o template que você copiou o id."
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>ID do template *</LabelInput>

              <TooltipInformation
                title="ID do template do usuário que você quer clonar"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="user_template_id"
                register={register}
                required={true}
                placeholder="Digite o ID do template"
              />
              {errors.user_template_id && (
                <ErrorInput>{errors.user_template_id.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Email *</LabelInput>

              <TooltipInformation
                title="Email do usuário que irá receber o template clonado"
                placement="top"
                maxWidth={200}
              />

              <Input
                label="email"
                register={register}
                required={true}
                placeholder="Digite o ID do template do usuário"
              />
              {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/templates", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CloneTemplateToUser;
