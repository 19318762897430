import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateUserAdmin from "./dtos/IFormCreateUserAdmin";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import InputCheckbox from "../../../../../../shared/components/InputCheckbox";
import Alert from "../../../../../../shared/components/Alert";

import { Notify } from "../../../../../../shared/utils";

import {
  Main,
  Form,
  ContainerInput,
  LabelInput,
  ErrorInput,
  ContainerCheckbox,
  Title,
} from "./styles";

const CreateUserAdmin: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dataLocation: any = location.state;

  const navigate = useNavigate();

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/create-user-admin");
  }, []);

  const formSchema = yup.object().shape({
    first_name: yup.string().required("nome é um campo obrigatório"),
    last_name: yup.string().required("sobrenome é um campo obrigatório"),
    email: yup
      .string()
      .email("Insira um email valido")
      .required("email é um campo obrigatório"),
    password: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(
        /[*@!#%$&()^~?:<>{}]+/,
        "A senha deve conter pelo menos um símbolo"
      )
      .required(`Senha necessária`),
    create_templates: yup.boolean(),
    read_templates: yup.boolean(),
    update_templates: yup.boolean(),
    delete_templates: yup.boolean(),
    clone_templates: yup.boolean(),
    create_template_categories: yup.boolean(),
    read_template_categories: yup.boolean(),
    update_template_categories: yup.boolean(),
    delete_template_categories: yup.boolean(),
    create_users: yup.boolean(),
    read_users: yup.boolean(),
    block_users: yup.boolean(),
    update_user_password: yup.boolean(),
    update_user_subscription: yup.boolean(),
    delete_users: yup.boolean(),
    cancel_users: yup.boolean(),
    create_clauses: yup.boolean(),
    read_clauses: yup.boolean(),
    update_clauses: yup.boolean(),
    delete_clauses: yup.boolean(),
    create_clause_categories: yup.boolean(),
    read_clause_categories: yup.boolean(),
    update_clause_categories: yup.boolean(),
    delete_clause_categories: yup.boolean(),
    create_icons: yup.boolean(),
    read_icons: yup.boolean(),
    update_icons: yup.boolean(),
    delete_icons: yup.boolean(),
    create_icons_categories: yup.boolean(),
    read_icons_categories: yup.boolean(),
    update_icons_categories: yup.boolean(),
    delete_icons_categories: yup.boolean(),
    create_users_admin: yup.boolean(),
    read_users_admin: yup.boolean(),
    update_users_admin: yup.boolean(),
    update_users_admin_password: yup.boolean(),
    block_users_admin: yup.boolean(),
    read_users_admin_editor: yup.boolean(),
    create_users_admin_editor: yup.boolean(),
    delete_users_admin_editor: yup.boolean(),
    user_graphics: yup.boolean(),
    send_personalized_email: yup.boolean(),
    create_text_replacement: yup.boolean(),
    read_text_replacement: yup.boolean(),
    update_text_replacement: yup.boolean(),
    delete_text_replacement: yup.boolean(),
    create_image: yup.boolean(),
    read_image: yup.boolean(),
    update_image: yup.boolean(),
    delete_image: yup.boolean(),
    create_image_categories: yup.boolean(),
    read_image_categories: yup.boolean(),
    update_image_categories: yup.boolean(),
    delete_image_categories: yup.boolean(),
    create_shapes: yup.boolean(),
    read_shapes: yup.boolean(),
    update_shapes: yup.boolean(),
    delete_shapes: yup.boolean(),
    create_shapes_categories: yup.boolean(),
    read_shapes_categories: yup.boolean(),
    update_shapes_categories: yup.boolean(),
    delete_shapes_categories: yup.boolean(),
    clone_bits_to_user_template: yup.boolean(),
    create_remote_access_user: yup.boolean(),
    read_remote_access_user: yup.boolean(),
    delete_remote_access_user: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit: SubmitHandler<IFormCreateUserAdmin> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const createUser = await api.post("/user-admin", {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          password: formData.password,
          permissions: {
            create_templates: formData.create_templates,
            read_templates: formData.read_templates,
            update_templates: formData.update_templates,
            delete_templates: formData.delete_templates,
            clone_templates: formData.clone_templates,
            create_template_categories: formData.create_template_categories,
            read_template_categories: formData.read_template_categories,
            update_template_categories: formData.update_template_categories,
            delete_template_categories: formData.delete_template_categories,
            create_users: formData.create_users,
            read_users: formData.read_users,
            block_users: formData.block_users,
            update_user_password: formData.update_user_password,
            update_user_subscription: formData.update_user_subscription,
            delete_users: formData.delete_users,
            cancel_users: formData.cancel_users,
            create_clauses: formData.create_clauses,
            read_clauses: formData.read_clauses,
            update_clauses: formData.update_clauses,
            delete_clauses: formData.delete_clauses,
            create_clause_categories: formData.create_clause_categories,
            read_clause_categories: formData.read_clause_categories,
            update_clause_categories: formData.update_clause_categories,
            delete_clause_categories: formData.delete_clause_categories,
            create_icons: formData.create_icons,
            read_icons: formData.read_icons,
            update_icons: formData.update_icons,
            delete_icons: formData.delete_icons,
            create_icons_categories: formData.create_icons_categories,
            read_icons_categories: formData.read_icons_categories,
            update_icons_categories: formData.update_icons_categories,
            delete_icons_categories: formData.delete_icons_categories,
            create_users_admin: formData.create_users_admin,
            read_users_admin: formData.read_users_admin,
            update_users_admin: formData.update_users_admin,
            update_users_admin_password: formData.update_users_admin_password,
            block_users_admin: formData.block_users_admin,
            read_users_admin_editor: formData.read_users_admin_editor,
            create_users_admin_editor: formData.create_users_admin_editor,
            delete_users_admin_editor: formData.delete_users_admin_editor,
            user_graphics: formData.user_graphics,
            send_personalized_email: formData.send_personalized_email,
            create_text_replacement: formData.create_text_replacement,
            read_text_replacement: formData.read_text_replacement,
            update_text_replacement: formData.update_text_replacement,
            delete_text_replacement: formData.delete_text_replacement,
            create_image: formData.create_image,
            read_image: formData.read_image,
            update_image: formData.update_image,
            delete_image: formData.delete_image,
            create_image_categories: formData.create_image_categories,
            read_image_categories: formData.read_image_categories,
            update_image_categories: formData.update_image_categories,
            delete_image_categories: formData.delete_image_categories,
            create_shapes: formData.create_shapes,
            read_shapes: formData.read_shapes,
            update_shapes: formData.update_shapes,
            delete_shapes: formData.delete_shapes,
            create_shapes_categories: formData.create_shapes_categories,
            read_shapes_categories: formData.read_shapes_categories,
            update_shapes_categories: formData.update_shapes_categories,
            delete_shapes_categories: formData.delete_shapes_categories,
            clone_bits_to_user_template: formData.clone_bits_to_user_template,
            create_remote_access_user: formData.create_remote_access_user,
            read_remote_access_user: formData.read_remote_access_user,
            delete_remote_access_user: formData.delete_remote_access_user,
            create_element_block: formData.create_element_block,
            read_element_block: formData.read_element_block,
            update_element_block: formData.update_element_block,
            delete_element_block: formData.delete_element_block,
            create_element_block_category: formData.create_element_block_category,
            read_element_block_category: formData.read_element_block_category,
            update_element_block_category: formData.update_element_block_category,
            delete_element_block_category: formData.delete_element_block_category,
            download_users_admin: formData.download_users_admin
          },
        });

        if (createUser.status === 200) {
          Notify({ text: "Usuário cadastrado com sucesso", type: "success" });
          setLoading(false);
          navigate("/users-admin", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        if (err?.response?.data?.message === "Email address alredy used") {
          Notify({ text: "Email já cadastrado", type: "error" });
        }
        setLoading(false);
      }
    },
    [navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar usuário admin" />
      <MasterMain>
        <Main>
          <Alert
            message="Aqui você cria as contas para usuários administradores da 
              dashboard e editores dos templates da Bits. Se você quiser criar 
              um usuário editor, não passe nenhuma permissão."
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome *</LabelInput>
              <Input
                label="first_name"
                register={register}
                required={true}
                placeholder="Digite o nome"
              />
              {errors.first_name && (
                <ErrorInput>{errors.first_name.message}</ErrorInput>
              )}
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Sobrenome *</LabelInput>
              <Input
                label="last_name"
                register={register}
                required={true}
                placeholder="Digite o sobrenome"
              />
              {errors.last_name && (
                <ErrorInput>{errors.last_name.message}</ErrorInput>
              )}
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Email *</LabelInput>
              <Input
                label="email"
                register={register}
                required={true}
                placeholder="Digite o email"
              />
              {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Senha *</LabelInput>
              <Input
                label="password"
                register={register}
                required={true}
                placeholder="Digite a senha"
              />
              {errors.password && (
                <ErrorInput>{errors.password.message}</ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 20, marginTop: 20 }} />

            <Title>Regras de acesso</Title>

            <ContainerInput>
              <LabelInput>Templates</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_templates"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_templates"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_templates"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_templates"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
                <InputCheckbox
                  label="clone_templates"
                  register={register}
                  required={false}
                  text="Clonar para bits"
                  control={control}
                  width={170}
                />
                <InputCheckbox
                  label="clone_bits_to_user_template"
                  register={register}
                  required={false}
                  text="Clonar para o usuário"
                  control={control}
                  width={200}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Categorias de templates</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_template_categories"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_template_categories"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_template_categories"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_template_categories"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Usuários UX Doc</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_users"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_users"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="block_users"
                  register={register}
                  required={false}
                  text="Bloquear"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_user_password"
                  register={register}
                  required={false}
                  text="Atualizar senha"
                  control={control}
                  width={150}
                />
                <InputCheckbox
                  label="update_user_subscription"
                  register={register}
                  required={false}
                  text="Atualizar status da assinatura"
                  control={control}
                  width={245}
                />
                <InputCheckbox
                  label="delete_users"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
                <InputCheckbox
                  label="cancel_users"
                  register={register}
                  required={false}
                  text="Cancelar assinatura"
                  control={control}
                  width={180}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Cláusulas</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_clauses"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_clauses"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_clauses"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_clauses"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Categorias de cláusulas</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_clause_categories"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_clause_categories"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_clause_categories"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_clause_categories"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Ícones</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_icons"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_icons"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_icons"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_icons"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Categorias de ícones</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_icons_categories"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_icons_categories"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_icons_categories"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_icons_categories"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Imagens</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_image"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_image"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_image"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_image"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Categorias de imagens</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_image_categories"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_image_categories"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_image_categories"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_image_categories"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Formas</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_shapes"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_shapes"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_shapes"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_shapes"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Categorias de formas</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_shapes_categories"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_shapes_categories"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_shapes_categories"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_shapes_categories"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={100}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Usuário admin</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_users_admin"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_users_admin"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_users_admin"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_users_admin_password"
                  register={register}
                  required={false}
                  text="Atualizar senha"
                  control={control}
                  width={150}
                />
                <InputCheckbox
                  label="block_users_admin"
                  register={register}
                  required={false}
                  text="Bloquear"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Usuário admin editor</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_users_admin_editor"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_users_admin_editor"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_users_admin_editor"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Substituição de texto</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_text_replacement"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_text_replacement"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_text_replacement"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_text_replacement"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Relatórios</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="user_graphics"
                  register={register}
                  required={false}
                  text="Usuários"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Email</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="send_personalized_email"
                  register={register}
                  required={false}
                  text="Enviar email personalizado"
                  control={control}
                  width={225}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Acesso remoto</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_remote_access_user"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_remote_access_user"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_remote_access_user"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Download CSV</LabelInput>
              <ContainerCheckbox>
                <InputCheckbox
                  label="download_users_admin"
                  register={register}
                  required={false}
                  text="Permitir download de usuários via CSV"
                  control={control}
                  width={400}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Bloco de elementos</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_element_block"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_element_block"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_element_block"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_element_block"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Categorias de blocos de elementos</LabelInput>

              <ContainerCheckbox>
                <InputCheckbox
                  label="create_element_block_category"
                  register={register}
                  required={false}
                  text="Criar"
                  control={control}
                  width={80}
                />
                <InputCheckbox
                  label="read_element_block_category"
                  register={register}
                  required={false}
                  text="Visualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="update_element_block_category"
                  register={register}
                  required={false}
                  text="Atualizar"
                  control={control}
                  width={110}
                />
                <InputCheckbox
                  label="delete_element_block_category"
                  register={register}
                  required={false}
                  text="Deletar"
                  control={control}
                  width={110}
                />
              </ContainerCheckbox>
            </ContainerInput>


            <Divider style={{ marginBottom: 10, marginTop: 20 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/users-admin", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateUserAdmin;
