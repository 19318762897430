import React, { useCallback, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAuth } from "../../../../hooks/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SyncLoader from "react-spinners/SyncLoader";

import IconSvg from "../../../../shared/components/IconSvg";
import Button from "../../../../shared/components/Button";
import InputLogin from "../../../../shared/components/InputLogin";

import logoBits from "../../../../shared/assets/logo.svg";
import backgroundLogin from "../../../../shared/assets/background-login.png";

import {
  Container,
  Heading,
  LoginBox,
  BoxLogo,
  ImageBox,
  ContainerInput,
  ErrorInput,
} from "./styles";

interface IHookForm {
  [key: string]: any;
}

interface IFormValues {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("digite um email válido")
      .required("e-mail é um campo obrigatório"),
    password: yup.string().required("senha é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const { signIn } = useAuth();

  const notify = useCallback((text: string) => {
    toast.error(text);
  }, []);

  const onSubmit: SubmitHandler<IFormValues> = useCallback(
    async (formData) => {
      try {
        setLoading(true);
        await signIn(formData.email, formData.password);
        setLoading(false);
      } catch (error: any) {
        if (
          error.response.data.message ===
          "Incorrect email/password combination."
        ) {
          notify("Email ou senha incorretos, tente novamente.");
          setLoading(false);
        }
        if (error.response.data.message === "Blocked user, contact support.") {
          notify("Usuário sem acesso, consulte o suporte.");
          setLoading(false);
        }
      }
    },
    [notify, signIn]
  );

  return (
    <Container>
      <LoginBox onSubmit={handleSubmit(onSubmit)}>
        <BoxLogo>
          <IconSvg image={logoBits} width="178" height="77" />
        </BoxLogo>

        <Heading>Faça seu login</Heading>

        <ContainerInput>
          <InputLogin
            label="email"
            register={register}
            required={true}
            placeholder="Digite o email"
          />
          {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
        </ContainerInput>

        <ContainerInput>
          <InputLogin
            type="password"
            label="password"
            register={register}
            required={true}
            placeholder="Digite a senha"
          />
          {errors.password && (
            <ErrorInput>{errors.password.message}</ErrorInput>
          )}
        </ContainerInput>

        <Button type="submit" style={{ marginTop: 40, width: "60%" }}>
          {!loading ? (
            "Entrar"
          ) : (
            <SyncLoader loading={loading} color="#ffffff" size={6} />
          )}
        </Button>
      </LoginBox>

      <ImageBox src={backgroundLogin} />

      <ToastContainer />
    </Container>
  );
};

export default LoginPage;
