import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../../hooks/routes";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormEditClause from "./dtos/IFormEditClause";
import IListClauseCategoryApiProps from "./dtos/IListClauseCategoryApiProps";
import IBitsClauseCategories from "./dtos/IBitsClauseCategories";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";
import InputMultSelect from "../../../../../../shared/components/InputMultSelect";
import ChipInput from "../../../../../../shared/components/ChipInput";
import TooltipInformation from "../../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const EditClause: React.FC = () => {
  const [optionSelect, setOptionSelect] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);

  const { getUrl } = useRoutes();

  const location = useLocation();
  const dataLocation: any = location.state;

  const { clause_id } = useParams();

  useEffect(() => {
    getUrl("/edit-clause/:clause_id");
  }, []);

  const formSchema = yup.object().shape({
    clause: yup.string().required("cláusula é um campo obrigatório"),
    key_words: yup
      .array()
      .of(yup.string())
      .required("palavras chave é um campo obrigatório"),
    categories: yup
      .array()
      .min(1, "categorias é um campo obrigatório")
      .required("categorias é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const listClauseCategory = await api.get(
        "/bits-clause-categories/list-all-dashboard"
      );

      let options: Array<string> = [];

      listClauseCategory.data.forEach((item: IListClauseCategoryApiProps) => {
        options.push(`${item.title}||${item.id}`);
      });

      setOptionSelect(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const listClauseSpecific = await api.get(`/bits-clause/${clause_id}`);

      let defaultValue = [];

      listClauseSpecific.data.bitsClauseCategories.forEach(
        (item: IBitsClauseCategories) => {
          defaultValue.push(`${item.title}||${item.id}`);
        }
      );

      let keyWords: Array<string> = [];

      if (listClauseSpecific.data.bitsClause.key_words) {
        keyWords = listClauseSpecific.data.bitsClause.key_words.split(",");
      }

      setValue("categories", defaultValue, { shouldValidate: true });
      reset({
        clause: listClauseSpecific.data.bitsClause.clause,
        key_words: keyWords,
        categories: defaultValue,
      });
    })();
  }, [clause_id, reset, setValue]);

  const onSubmit: SubmitHandler<IFormEditClause> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        let newCategories = [];

        formData.categories.forEach((item) => {
          const [label, id] = String(item).split("||");

          newCategories.push({
            bits_clause_categories_id: id,
          });
        });

        let keyWord = "";

        formData.key_words.forEach((item, index) => {
          if (index < formData.key_words.length - 1) {
            if (item.trim().length !== 0) {
              keyWord += `${item.toLowerCase().trim()},`;
            }
          } else {
            if (item.trim().length !== 0) {
              keyWord += `${item.toLowerCase().trim()}`;
            } else {
              keyWord = keyWord.substring(0, keyWord.length - 1);
            }
          }
        });

        const updateClause = await api.put("/bits-clause", {
          bits_clause_id: clause_id,
          clause: formData.clause,
          key_words: keyWord,
          categories: newCategories,
        });

        if (updateClause.status === 200) {
          Notify({ text: "Cláusula atualizada com sucesso", type: "success" });
          setLoading(false);
          navigate("/clauses", {
            state: { params: dataLocation?.params },
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [clause_id, navigate, dataLocation]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Editar Cláusula" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Cláusula *</LabelInput>
              <Input
                label="clause"
                register={register}
                required={true}
                placeholder="Digite a cláusula"
              />
              {errors.clause && (
                <ErrorInput>{errors.clause.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Palavras chave *</LabelInput>

              <TooltipInformation
                title="A palavra chave é por onde o usuário irá encontrar essa 
                  cláusula. Então adicione o máximo de palavras que achar 
                  necessário. Aperte a tecla enter para adicionar a palavra chave"
                placement="top"
                maxWidth={300}
              />

              <ChipInput
                control={control}
                label="key_words"
                required={true}
                defaultValue={[]}
                placeholder="Palavras chave"
              />
              {errors.key_words && (
                <ErrorInput>{errors.key_words.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Selecione a categoria *</LabelInput>

              <TooltipInformation
                title="Se a categoria que você quer não estiver na lista. Você 
                  deve ir em categoria de cláusulas e cadastrar a categoria que 
                  deseja"
                placement="top"
                maxWidth={300}
              />

              <InputMultSelect
                control={control}
                label="categories"
                options={optionSelect}
              />
              {errors.categories && (
                <ErrorInput>{errors.categories.message}</ErrorInput>
              )}
            </ContainerInput>

            <ButtonCancel
              onClick={() => {
                navigate("/clauses", {
                  state: { params: dataLocation?.params },
                });
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Salvar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default EditClause;
