import React, { useCallback, useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../services/api";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useRoutes } from "../../../../../hooks/routes";

import IFormSendPersonalizedEmail from "./dtos/IFormSendPersonalizedEmail";
import IHookForm from "../../../../../shared/dtos/IHookForm";

import MasterAsideControls from "../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../shared/components/Button";
import Input from "../../../../../shared/components/Input";
import InputSelect from "../../../../../shared/components/InputSelect";
import Alert from "../../../../../shared/components/Alert";
import TooltipInformation from "../../../../../shared/components/TooltipInformation";

import { Notify } from "../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const SendPersonalizedEmail: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { getUrl } = useRoutes();

  useEffect(() => {
    getUrl("/send-personalized-email");
  }, []);

  const formSchema = yup.object().shape({
    template_id: yup
      .string()
      .typeError("id do template é um campo obrigatório")
      .required("id do template é um campo obrigatório"),
    user_type: yup
      .string()
      .typeError("tipo do usuário é um campo obrigatório")
      .required("tipo do usuário é um campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormSendPersonalizedEmail> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const sendEmail = await api.post(
          "/automation/send-personalized-email",
          {
            template_id: formData.template_id,
            user_type: formData.user_type,
          }
        );

        if (sendEmail.status === 200) {
          Notify({
            text: "Email enviado com sucesso",
            type: "success",
          });
          setLoading(false);
          reset({
            template_id: "",
          });
        }
      } catch (err: any) {
        console.log(err?.response?.data);
        setLoading(false);
      }
    },
    [reset]
  );

  const optionsUserType = [
    { value: "b2b", label: "B2B" },
    { value: "ld_pass", label: "Legal Design Pass" },
    { value: "cortesia", label: "Cortesia" },
    { value: "pre_venda", label: "Pré Venda" },
    { value: "user_free", label: "Usuário padrão" },
    { value: "bitsacademy", label: "Bits Academy" },
    { value: "active_user", label: "Usuários com assinatura ativa" },
    { value: "disabled_user", label: "Usuários com assinatura cancelada" },
  ];

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Enviar email personalizado" />
      <MasterMain>
        <Main>
          <Alert
            message="Aqui você pode enviar emails personalizados usando a 
            base de dados de usuários do UX Doc, basta adicionar o ID do template
            do email do Sendgrid e escolher o tipo de usuário que irá receber os
            emails"
            type="purple"
          />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>ID do template do Sendgrid *</LabelInput>

              <TooltipInformation
                title="Quando você cria um novo template no Sendgrid ele gera um
                ID unico daquele template, adicione esse ID aqui"
                placement="top"
                maxWidth={300}
              />

              <Input
                label="template_id"
                register={register}
                required={true}
                placeholder="Digite o id do template do sendgrid"
              />
              {errors.template_id && (
                <ErrorInput>{errors.template_id.message}</ErrorInput>
              )}
            </ContainerInput>

            <ContainerInput>
              <LabelInput>Tipo do usuário *</LabelInput>

              <TooltipInformation
                title="Escolha qual o tipo de usuário que irá receber os email"
                placement="top"
                maxWidth={300}
              />

              <InputSelect
                label="user_type"
                defaultValue=""
                options={optionsUserType}
                control={control}
              />
              {errors.user_type && (
                <ErrorInput>{errors.user_type.message}</ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 0, marginTop: 30 }} />

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Enviar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }} disabled={true}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default SendPersonalizedEmail;
