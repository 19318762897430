import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.purple};
  margin-bottom: 10px;
`;

export const Title = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 2px;
`;

export const TitlePermissions = styled.h3`
  font-size: 17px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const Subtitle = styled.p`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray300};
  margin-bottom: 20px;
  margin-right: 30px;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Positive = styled.span`
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.greenLight};
`;

export const Negative = styled.span`
  color: ${({ theme }) => theme.colors.red};
  background-color: ${({ theme }) => theme.colors.redLight};
`;

export const Link = styled.a`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray300};
  margin-bottom: 20px;
`;

export const AvatarImg = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 100%;
`;

export const ContainerPermission = styled.div`
  width: 100%;
  height: auto;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
